<script setup>
import { computed, ref, nextTick, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { i18n } from '@/i18n.js';

import { PROGRESS_STATUS } from '@/constants/status';

const store = useStore();
const focusRef = ref(null);
const { t } = i18n.global;

const progress = computed(() => store.getters['progressStore/GET']);
const layerCount = store.getters['layerStore/GET_COUNT'];

const state = reactive({
    message: {
        progress: t('message.1951'),
        success: t('message.1952'),
        exception: t('message.1954'),
    },
});

const handleBodyStyle = () => {
    if (layerCount === 0) {
        document.body.style.setProperty('overflow', 'unset', 'important');
    }
};

const close = () => {
    if (progress.value.status === PROGRESS_STATUS.EXCEPTION) {
        store.dispatch('progressStore/CLEAR_PROGRESS');
    } else {
        if (progress.value.callback) {
            progress.value.callback();
            store.dispatch('progressStore/CLEAR_PROGRESS');
        } else {
            store.dispatch('progressStore/CLEAR_PROGRESS');
        }
    }

    handleBodyStyle();
};

onMounted(() => {
    nextTick(() => {
        document.body.style.overflow = 'hidden';

        if (focusRef.value) {
            focusRef.value.focus();
        }
    });
});
</script>
<template>
    <div class="pop-wrapper">
        <div class="modal" ref="modalBg"></div>
        <div class="popup" :style="{ width: '600px' }">
            <div class="popup-header">
                <div class="title"><span class="title-sub"></span><span class="title-sub"></span></div>
                <button
                    v-if="['success', 'exception'].includes(progress.status)"
                    class="btn pop-close"
                    type="button"
                    @click="close"
                ></button>
            </div>
            <div class="popup-content scrollbar">
                <div class="progressbar">
                    <el-progress :percentage="progress.percentage" :status="progress.status" />
                    <div>
                        <span v-if="progress.status === 'progress'" class="txt">{{ state.message.progress }}</span>
                        <span v-else-if="progress.status === 'success'" class="txt">{{ state.message.success }}</span>
                        <span v-else-if="progress.status === 'exception'" class="txt">{{ state.message.exception }}</span>
                    </div>
                </div>
            </div>

            <div class="popup-footer">
                <button
                    v-if="['success', 'exception'].includes(progress.status)"
                    class="btn primary round"
                    type="button"
                    @click="close"
                    ref="focusRef"
                >
                    {{ $t('message.0181') }}
                </button>
            </div>
        </div>
    </div>
</template>
