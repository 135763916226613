<template>
    <div id="wrap">
        <router-view />
        <div
            v-loading.fullscreen.lock="data.isLoading"
            element-loading-text="Loading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
            element-loading-svg-view-box="0 0 512 512"
            :element-loading-svg="data.svg"
        ></div>
        <if-dialog v-if="data.isOpenDialog" />
        <if-progress v-if="data.isOpenProgress" />
    </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import IfDialog from '@/components/common/IfDialog.vue';
import IfProgress from '@/components/common/IfProgress.vue';

export default {
    name: 'App',
    components: { IfDialog, IfProgress },
    provide() {
        return {
            env: process.env.VUE_APP_ENV,
        };
    },
    setup() {
        const store = useStore();

        const data = reactive({
            isLoading: computed(() => {
                return store.getters['loadingStore/GET_IS_MOUNTED_LOADING'] || store.getters['loadingStore/GET_IS_LOADING'];
            }),
            isOpenDialog: computed(() => {
                return store.getters['dialogStore/GET_DIALOG'];
            }),
            isOpenProgress: computed(() => {
                return store.getters['progressStore/GET'];
            }),
            svg: `
                <path d="M248 92c-13.6 0-24-10.4-24-24V24c0-13.6 10.4-24 24-24s24 10.4 24 24v44c0 12.8-10.4 24-24 24z" fill="rgba(95,44,223,0.1)"></path>
                <path d="M248 496c-13.6 0-24-10.4-24-24v-44c0-13.6 10.4-24 24-24s24 10.4 24 24v44c0 13.6-10.4 24-24 24z" fill="rgba(95,44,223,0.6)"></path>
                <path d="M157.6 116c-8 0-16-4-20.8-12l-21.6-37.6c-6.4-11.2-2.4-26.4 8.8-32.8s26.4-2.4 32.8 8.8L178.4 80c6.4 11.2 2.4 26.4-8.8 32.8-3.2 1.6-8 3.2-12 3.2z" fill="rgba(95,44,223,0.15)"></path>
                <path d="M360 465.6c-8 0-16-4-20.8-12L317.6 416c-6.4-11.2-2.4-26.4 8.8-32.8 11.2-6.4 26.4-2.4 32.8 8.8l21.6 37.6c6.4 11.2 2.4 26.4-8.8 32.8-4 2.4-8 3.2-12 3.2z" fill="rgba(95,44,223,0.7)"></path>
                <path d="M92 181.6c-4 0-8-.8-12-3.2l-37.6-21.6c-11.2-6.4-15.2-21.6-8.8-32.8s21.6-15.2 32.8-8.8l37.6 21.6c11.2 6.4 15.2 21.6 8.8 32.8-4.8 8-12.8 12-20.8 12z" fill="rgba(95,44,223,0.2)"></path>
                <path d="M442.4 384c-4 0-8-.8-12-3.2L392 359.2c-11.2-6.4-15.2-21.6-8.8-32.8 6.4-11.2 21.6-15.2 32.8-8.8l37.6 21.6c11.2 6.4 15.2 21.6 8.8 32.8-4 8-12 12-20 12z" fill="rgba(95,44,223,0.8)"></path>
                <path d="M68 272H24c-13.6 0-24-10.4-24-24s10.4-24 24-24h44c13.6 0 24 10.4 24 24s-11.2 24-24 24z" fill="rgba(95,44,223,0.25)"></path>
                <path d="M472 272h-44c-13.6 0-24-10.4-24-24s10.4-24 24-24h44c13.6 0 24 10.4 24 24s-10.4 24-24 24z" fill="rgba(95,44,223,0.9)"></path>
                <path d="M53.6 384c-8 0-16-4-20.8-12-6.4-11.2-2.4-26.4 8.8-32.8l37.6-21.6c11.2-6.4 26.4-2.4 32.8 8.8 6.4 11.2 2.4 26.4-8.8 32.8l-37.6 21.6c-3.2 2.4-7.2 3.2-12 3.2z" fill="rgba(95,44,223,0.3)"></path>
                <path d="M404 181.6c-8 0-16-4-20.8-12-6.4-11.2-2.4-26.4 8.8-32.8l37.6-21.6c11.2-6.4 26.4-2.4 32.8 8.8s2.4 26.4-8.8 32.8L416 178.4c-4 2.4-8 3.2-12 3.2z" fill="rgba(95,44,223,1)"></path>
                <path d="M136 465.6c-4 0-8-.8-12-3.2-11.2-6.4-15.2-21.6-8.8-32.8l21.6-37.6c6.4-11.2 21.6-15.2 32.8-8.8 11.2 6.4 15.2 21.6 8.8 32.8l-21.6 37.6c-4.8 8-12.8 12-20.8 12z" fill="rgba(95,44,223,0.4)"></path>
                <path d="M338.4 116c-4 0-8-.8-12-3.2-11.2-6.4-15.2-21.6-8.8-32.8l21.6-37.6c6.4-11.2 21.6-15.2 32.8-8.8 11.2 6.4 15.2 21.6 8.8 32.8L359.2 104c-4.8 7.2-12.8 12-20.8 12z" fill="rgba(95,44,223,0.5)"></path>
            `,
        });

        onMounted(() => {
            const locale = localStorage.getItem('common.locale.lang');
            document.querySelector('html').setAttribute('lang', locale);
        });

        return {
            data,
        };
    },
};
</script>

<style></style>
