export default {
  "0001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
  "0002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총"])},
  "0003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개"])},
  "0004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
  "0005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록필터"])},
  "0006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용"])},
  "0007": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 개씩 보기"])},
  "0008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록"])},
  "0009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
  "0010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록"])},
  "0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
  "0012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록자ID"])},
  "0013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록일시"])},
  "0014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정자ID"])},
  "0015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정일시"])},
  "0016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])},
  "0017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
  "0018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
  "0019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택안함"])},
  "0020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
  "0021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원"])},
  "0022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트"])},
  "0023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출여부"])},
  "0024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역코드"])},
  "0025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출기간"])},
  "0026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출범위"])},
  "0027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출순서"])},
  "0028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출일"])},
  "0029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
  "0030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어"])},
  "0031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 이미지"])},
  "0032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 제목"])},
  "0033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경"])},
  "0034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어코드"])},
  "0035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출위치"])},
  "0036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
  "0037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
  "0038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일"])},
  "0040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일찾기"])},
  "0041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비고"])},
  "0042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 추가 정보"])},
  "0043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 수정 정보"])},
  "0044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 입력 항목"])},
  "0045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화"])},
  "0046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성화"])},
  "0047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화 여부"])},
  "0048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
  "0049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서"])},
  "0050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼"])},
  "0051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
  "0052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여부"])},
  "0053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
  "0054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시정지"])},
  "0055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별"])},
  "0056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경"])},
  "0057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료 여부"])},
  "0058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 여부"])},
  "0059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 여부"])},
  "0061": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("view")), "로 돌아가기"])},
  "0062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 이름 콘텐츠 URL 백그라운드 URL 검색"])},
  "0063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠"])},
  "0064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 이름"])},
  "0065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 URL"])},
  "0066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["백그라운드 URL"])},
  "0067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mp4파일만 등록해주세요"])},
  "0068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360도 배경 영상"])},
  "0069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content 정보"])},
  "0070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content 등록"])},
  "0071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content 수정"])},
  "0072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content 관리"])},
  "0073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO 룸 정보"])},
  "0074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 제목 호스트 검색"])},
  "0075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료"])},
  "0076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표 자료 유형"])},
  "0077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재생 상태"])},
  "0078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어명 검색"])},
  "0079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 관리"])},
  "0080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어"])},
  "0082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 ID"])},
  "0083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 생성 타입"])},
  "0084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 선택"])},
  "0085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 검색을 통해 선택해 주세요."])},
  "0086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 및 룸 정보"])},
  "0087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드 제목 검색"])},
  "0088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드 제목"])},
  "0089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드 부제목"])},
  "0090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드 수정"])},
  "0091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드 등록"])},
  "0092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드 정보"])},
  "0093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드 관리"])},
  "0094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁 내용 검색"])},
  "0095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁 수정"])},
  "0096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁 등록"])},
  "0097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁 정보"])},
  "0098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁 관리"])},
  "0099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 검색"])},
  "0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어"])},
  "0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 엑셀 전체 다운로드"])},
  "0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 엑셀 다운로드"])},
  "0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 파일을 다운로드 받아 양식을 수정하여 업로드해주세요"])},
  "0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 전체 일괄 등록"])},
  "0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 정보"])},
  "0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 일괄등록"])},
  "0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 관리"])},
  "0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 제목 광고 문구 광고 URL 검색"])},
  "0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 제목"])},
  "0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 문구"])},
  "0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 URL"])},
  "0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 URL 관리"])},
  "0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIMETABLE 보기"])},
  "0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 위치"])},
  "0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 색상"])},
  "0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서 LEVEL1 ‘-1’로 기재된 배너는 노출 순서 LEVEL2 순에 따라 화면에서 일정 간격으로 자동 롤링 처리 되 노출 됩니다"])},
  "0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 종류"])},
  "0119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼URL"])},
  "0120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서 변경"])},
  "0121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 배너 활성화"])},
  "0122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트배너 수정"])},
  "0123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트배너 정보"])},
  "0124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트배너 Timetable"])},
  "0125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트배너 등록"])},
  "0126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 관리"])},
  "0127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고배너 종류"])},
  "0128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼이름"])},
  "0129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼URL"])},
  "0130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면 팝업 리스트"])},
  "0131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 정보"])},
  "0132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 정보"])},
  "0133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 등록"])},
  "0134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 관리"])},
  "0135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 제목 검색"])},
  "0136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 관리"])},
  "0137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환영합니다!"])},
  "0138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님"])},
  "0139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "0140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이페이지"])},
  "0141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 관리"])},
  "0142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마 이름 검색"])},
  "0143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 지역"])},
  "0144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 이프스퀘어 테마 정보와 TRO 테마 오브젝트 정보의 매핑 정보를 관리합니다. 리소스 종류, 리소스 값이 공백일 경우 데이터가 삭제됩니다."])},
  "0145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지"])},
  "0146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마 이름"])},
  "0147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매핑 정보"])},
  "0148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마 객체 이름"])},
  "0149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 종류"])},
  "0150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 종류를 입력해 주세요"])},
  "0151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 값"])},
  "0152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 등록"])},
  "0153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 수정"])},
  "0154": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), "자 이하로 입력해 주세요"])},
  "0155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 타입"])},
  "0156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 수정"])},
  "0157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png파일만 등록해 주세요"])},
  "0158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 이름"])},
  "0159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 이름을 입력해 주세요"])},
  "0160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 선택"])},
  "0161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 검색"])},
  "0162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 검색을 통해 선택해 주세요"])},
  "0163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순번"])},
  "0164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
  "0165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
  "0166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 유형"])},
  "0167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제재 여부"])},
  "0168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS ID"])},
  "0169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 타입 선택"])},
  "0170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https:// 포함하여 입력해 주세요"])},
  "0171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 코드 선택"])},
  "0172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 ID 선택"])},
  "0173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너"])},
  "0174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 URL 등록"])},
  "0175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 URL 수정"])},
  "0176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 URL 정보"])},
  "0177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 일괄 등록"])},
  "0178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 등록"])},
  "0179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 수정"])},
  "0180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "0181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
  "0182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 정보"])},
  "0183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출"])},
  "0184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비노출"])},
  "0185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 관리"])},
  "0186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디, 이름, 이메일 검색"])},
  "0187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한"])},
  "0188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리 지역"])},
  "0189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 상태"])},
  "0190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상"])},
  "0191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 잠금"])},
  "0192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
  "0193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임, 회원번호, 내용 검색"])},
  "0194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 관리"])},
  "0195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 게시글 관리"])},
  "0196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 기간"])},
  "0197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제 여부"])},
  "0198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 여부"])},
  "0199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원번호"])},
  "0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좋아요 수"])},
  "0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 수"])},
  "0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 게시글 정보"])},
  "0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글 NO"])},
  "0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 게시글 내용"])},
  "0205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 답글 정보"])},
  "0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 답글 목록"])},
  "0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk 답글 관리"])},
  "0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 관리"])},
  "0209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 명 , 다이나믹 링크 검색"])},
  "0210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처\n카테고리"])},
  "0211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리"])},
  "0212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 명"])},
  "0213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 이미지"])},
  "0214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 없음"])},
  "0215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OG 이미지"])},
  "0216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다이나믹 링크"])},
  "0217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO 룸 연결"])},
  "0218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 정보"])},
  "0219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 태그"])},
  "0220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마중물 상단 고정"])},
  "0221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개수"])},
  "0222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 방 생성 여부"])},
  "0223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph 설정\n(딥링크 설정)"])},
  "0224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 등록"])},
  "0225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 수정"])},
  "0226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 이미지"])},
  "0227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴업체 방 이미지"])},
  "0228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 태그를 입력해 주세요"])},
  "0229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마중물 상단 고정 개수"])},
  "0230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 명을 입력해 주세요"])},
  "0231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 접속일시"])},
  "0232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 초기화"])},
  "0233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시스템 관리"])},
  "0234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 정보"])},
  "0235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 선택"])},
  "0236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마중물 상단 고정 개수를 입력해 주세요"])},
  "0237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본"])},
  "0238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동 설정"])},
  "0239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이틀"])},
  "0240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이틀을 입력해 주세요"])},
  "0241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
  "0242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명을 입력해 주세요"])},
  "0243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 ID, 방 제목, 방 이름, TRA의 PID 값 검색"])},
  "0244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방 제목"])},
  "0245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방 이름"])},
  "0246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRA의 PID 값"])},
  "0247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 적용"])},
  "0248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어 입력"])},
  "0249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 번호"])},
  "0250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 시작"])},
  "0251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 포함"])},
  "0252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 관리"])},
  "0253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 명 검색"])},
  "0254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 등록"])},
  "0255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 수정"])},
  "0256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 카테고리 명"])},
  "0257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리를 입력해 주세요"])},
  "0258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 정보"])},
  "0259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 정보"])},
  "0260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 이프스퀘어"])},
  "0261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
  "0262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어"])},
  "0263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 번호"])},
  "0264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 제목"])},
  "0265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 승인 관리"])},
  "0266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
  "0267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 요청 일시"])},
  "0268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 취소"])},
  "0269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업이력 관리"])},
  "0270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 카테고리"])},
  "0271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴"])},
  "0272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 기간"])},
  "0273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 유형"])},
  "0274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
  "0275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세"])},
  "0276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업내용, 작업자ID, 상세검색"])},
  "0277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 내용"])},
  "0278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 일시"])},
  "0279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업자 ID"])},
  "0280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역 관리"])},
  "0281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역 이름, 연락 이메일 검색"])},
  "0282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역 이름"])},
  "0283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락 이메일"])},
  "0284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역 정보"])},
  "0285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역 등록"])},
  "0286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역 수정"])},
  "0287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 관리"])},
  "0288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한명"])},
  "0289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 정보"])},
  "0290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 권한 설정"])},
  "0291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 권한"])},
  "0292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴명"])},
  "0293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펼치기"])},
  "0294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접기"])},
  "0295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 관리"])},
  "0296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴명 클릭 시 해당 메뉴 정보를 확인할 수 있습니다"])},
  "0297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규등록"])},
  "0298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 등록"])},
  "0299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 수정"])},
  "0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 정보"])},
  "0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 아이콘"])},
  "0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 경로"])},
  "0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 URL"])},
  "0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 아이디"])},
  "0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 노출 순서"])},
  "0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mydesk 전용"])},
  "0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전용"])},
  "0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 시간"])},
  "0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 등록"])},
  "0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 수정"])},
  "0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 이미지"])},
  "0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항을 입력해 주세요"])},
  "0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 선택"])},
  "0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 검색"])},
  "0407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])},
  "0408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분"])},
  "0409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 회원"])},
  "0410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 호스트"])},
  "0411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO TIMER 여부"])},
  "0412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글로벌 이프스퀘어"])},
  "0413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
  "0414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 관리"])},
  "0415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 NO, 이프스퀘어 ID, 이프스퀘어 제목 검색"])},
  "0416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "0417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 이프스퀘어 여부"])},
  "0418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 이프스퀘어 관리"])},
  "0419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 이프스퀘어 추가"])},
  "0420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 NO, 제목 검색"])},
  "0421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어 관리"])},
  "0422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 명, 이프스퀘어 NO, 이프스퀘어 ID 검색"])},
  "0423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 추천 여부"])},
  "0424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 번호"])},
  "0425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어 정보"])},
  "0426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처"])},
  "0427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어"])},
  "0428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어 등록"])},
  "0429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어 수정"])},
  "0430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 이프스퀘어 선택"])},
  "0431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 검색을 통해 선택해 주세요"])},
  "0432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 선택"])},
  "0433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 생성 타입 선택"])},
  "0434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 이프스퀘어 관리"])},
  "0435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 NO, 이프스퀘어 NO, 이프스퀘어 ID, 이프스퀘어 제목 검색"])},
  "0436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 NO"])},
  "0437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 정보"])},
  "0438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역"])},
  "0439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어 선택"])},
  "0440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 여부"])},
  "0441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별(★) 화면 노출 여부"])},
  "0442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자"])},
  "0443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커"])},
  "0444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아바타 노출"])},
  "0445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 사용자"])},
  "0446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마 유형"])},
  "0447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방청객 유무"])},
  "0448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트리밍 URL"])},
  "0449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 첨부"])},
  "0450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 서버"])},
  "0451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 설정"])},
  "0452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 모드"])},
  "0453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 Alert"])},
  "0454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 full Alert"])},
  "0455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 Anim"])},
  "0456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 스크린 Alert"])},
  "0457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 사운드"])},
  "0458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph 설정(딥링크 설정)"])},
  "0459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택적 렌더링 여부"])},
  "0460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 태그"])},
  "0461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트"])},
  "0462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표 자료 변경"])},
  "0463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 종료"])},
  "0464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표 자료"])},
  "0465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번호"])},
  "0466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF 파일찾기"])},
  "0467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료 URL을 입력해 주세요"])},
  "0468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 파일찾기"])},
  "0469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재생"])},
  "0470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앱 노출 순서"])},
  "0471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 관리로 돌아가기"])},
  "0472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 등록"])},
  "0473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 생성 이프스퀘어, 사용 여부가 N인 타입은 선택할 수 없습니다"])},
  "0474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 참여 형태"])},
  "0475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서를 입력해 주세요"])},
  "0476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 선택"])},
  "0477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 검색"])},
  "0478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행방법"])},
  "0479": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["STEP.", _interpolate(_named("num"))])},
  "0480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 선택 버튼 클릭"])},
  "0481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자세부 등록"])},
  "0482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 선택(결과)"])},
  "0483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 검색을 통해 선택해 주세요"])},
  "0484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해 주세요"])},
  "0485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용을 입력해 주세요"])},
  "0486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접입력"])},
  "0487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일첨부(PDF or IMAGE)"])},
  "0488": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("str")), " 등록"])},
  "0489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 이미지"])},
  "0490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규 이미지"])},
  "0491": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["대표 태그는 최대 ", _interpolate(_list(0)), "개 등록 가능합니다"])},
  "0492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 선택"])},
  "0493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 검색을 통해 선택해 주세요"])},
  "0494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 스피커는 최대 5명, 예약 사용자는 최대 20명까지 등록 가능합니다"])},
  "0495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처를 선택하세요"])},
  "0496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 추가"])},
  "0497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 사용자 추가"])},
  "0498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 수정"])},
  "0499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 검색"])},
  "0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 제목, 호스트 검색"])},
  "0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 (★) 여부"])},
  "0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앱 링크"])},
  "0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모니터링"])},
  "0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계"])},
  "0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료"])},
  "0506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 통계"])},
  "0507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 모니터링 및 제어"])},
  "0508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 정보"])},
  "0509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 언어 코드"])},
  "0510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 종료 여부"])},
  "0511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제 종료"])},
  "0512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 룸 생성 여부"])},
  "0513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 룸 생성"])},
  "0514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 접속자 수"])},
  "0515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 회원"])},
  "0516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 상태"])},
  "0517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이크 설정"])},
  "0518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 마이크 OFF"])},
  "0519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 마이크 ON"])},
  "0520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트만 말하기 설정"])},
  "0521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 실시간 공지 등록"])},
  "0522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지 내용"])},
  "0523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미등록"])},
  "0524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])},
  "0525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영정책위반(팝업)"])},
  "0526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트종료(Toast)"])},
  "0527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영상의종료(Toast)"])},
  "0528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFRESH"])},
  "0529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Unique User"])},
  "0530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Stay Time"])},
  "0531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 동접자수"])},
  "0532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 변경 횟수"])},
  "0533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 건수"])},
  "0534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명"])},
  "0535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건"])},
  "0536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 입/퇴장 이력"])},
  "0537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그래프 확대"])},
  "0538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIME"])},
  "0539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
  "0540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임시 이미지"])},
  "0541": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 입력해 주세요"])},
  "0542": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 입력해 주세요"])},
  "0543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 회원 가입"])},
  "0545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
  "0546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
  "0547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호와 일치하지 않습니다"])},
  "0548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소"])},
  "0549": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 선택해 주세요"])},
  "0550": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 선택해 주세요"])},
  "0551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영소문자, 숫자, 특수문자 각각 1개 이상 포함된 9~20자"])},
  "0552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 가입 신청이 완료되었습니다. 슈퍼 관리자가 승인하면 요청한 계정을 사용하실 수 있습니다"])},
  "0553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 아이디 입니다"])},
  "0554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "0555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디와 비밀번호를 입력해 주세요"])},
  "0556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디와 비밀번호가 유효한 값이 아닙니다"])},
  "0557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 계정이 5회 이상 비밀번호가 맞지 않아 계정이 잠겼습니다. 관리자에게 문의하시기 바랍니다."])},
  "0558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
  "0559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 저장"])},
  "0560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 하시려면 아이디 입력 후 FIDO2 로그인을 진행해주세요"])},
  "0561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디가 없는 경우, 회원가입을 통하여 계정을 새로 만들어 주세요"])},
  "0562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 등록을 하지 않은 경우, FIDO2 등록을 진행해주세요"])},
  "0563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland global admin"])},
  "0564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation And Maintenance"])},
  "0565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이 페이지"])},
  "0566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 정보"])},
  "0567": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " 변경"])},
  "0568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 이메일"])},
  "0569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 이메일 확인"])},
  "0570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 이메일과 일치하지 않습니다"])},
  "0571": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 확인해 주세요"])},
  "0572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일, 비밀번호를 입력해 주세요"])},
  "0573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 변경을 위해 현재 비밀번호를 확인합니다"])},
  "0574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 비밀번호"])},
  "0575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 비밀번호"])},
  "0576": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " 확인"])},
  "0577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영문, 숫자, 특수문자 포함한 9자리 이상 입력해 주세요"])},
  "0578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 비밀번호와 동일하게 입력해 주세요"])},
  "0579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 비밀번호 확인"])},
  "0580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 비밀번호와 일치하지 않습니다"])},
  "0582": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다국어는 최대 ", _interpolate(_named("num")), "개까지만 추가할 수 있습니다"])},
  "0583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 이름을 입력해 주세요"])},
  "0584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Length를 입력해 주세요"])},
  "0585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너명"])},
  "0586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https:// 또는 http://를 포함하여 입력해 주세요"])},
  "0587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력해 주세요"])},
  "0588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제"])},
  "0589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한명 검색"])},
  "0590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작날짜가 종료날짜보다 클 수 없습니다"])},
  "0591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수정"])},
  "0592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일을 첨부해 주세요"])},
  "0593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 다국어 언어 코드"])},
  "0594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 광고 배너 버튼 이름"])},
  "0595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복된 언어코드가 존재합니다"])},
  "0596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 선택"])},
  "0597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role 선택"])},
  "0598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 위치 선택"])},
  "0599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jumpvr:// 포함하여 입력해 주세요"])},
  "0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼명"])},
  "0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 되었습니다"])},
  "0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고배너 버튼\n이프스퀘어 NO"])},
  "0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 전면팝업 배너가 없습니다"])},
  "0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARGB 값"])},
  "0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 코드"])},
  "0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 이력 상세 Parameter"])},
  "0611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출순서 LEVEL1"])},
  "0612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출순서 LEVEL2"])},
  "0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 첨부파일을 첨부해 주세요"])},
  "0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 이름"])},
  "0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 URL"])},
  "0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 URL은 'jumpvr://'로 시작되어야 합니다"])},
  "0704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 URL은 'https://' 또는 'http://' 로 시작되어야 합니다"])},
  "0705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 이프스퀘어 NO"])},
  "0706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너의 중복된 언어코드가 존재합니다"])},
  "0707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 이프스퀘어 NO Validation을 진행해 주세요"])},
  "0708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 언어"])},
  "0709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 되었습니다"])},
  "0710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 되었습니다"])},
  "0711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 파일 포맷만 업로드 가능합니다"])},
  "0712": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["파일의 최대 사이즈는 ", _interpolate(_list(0)), "입니다"])},
  "0713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료날짜가 시작날짜보다 작을 수 없습니다"])},
  "0714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서를 선택해주세요"])},
  "0715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 URL"])},
  "0716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼URL은 'jumpvr://' 로 시작되어야 합니다"])},
  "0717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 URL은 'https://' 또는 'http://' 로 시작되어야합니다"])},
  "0718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 이프스퀘어 NO"])},
  "0719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 이프스퀘어 NO를 입력해주세요"])},
  "0720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 접근입니다"])},
  "0721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제 되었습니다"])},
  "0722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 광고 제목"])},
  "0723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 URL은 'https://' 또는 'http://' 로 시작되어야 합니다"])},
  "0724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 광고 문구"])},
  "0725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화할 데이터가 존재하지 않습니다"])},
  "0726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xlsx 파일 포맷만 업로드 가능합니다"])},
  "0727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일을 업로드 해주세요"])},
  "0728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 금칙어입니다"])},
  "0729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 내용"])},
  "0730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 언어 코드입니다"])},
  "0731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 예정된 툴팁이 이미 있습니다"])},
  "0732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 가이드 제목"])},
  "0733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 가이드 부제목"])},
  "0734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 가이드 버튼 명"])},
  "0735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 지역코드입니다"])},
  "0736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어가 종료되었습니다"])},
  "0737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류가 발생하였습니다"])},
  "0738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Length"])},
  "0739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "0740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation"])},
  "0741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
  "0742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 회원번호"])},
  "0743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이콘 클릭 시 앱 링크가 복사됩니다!"])},
  "0744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트리밍 URL 파일을 첨부해 주세요"])},
  "0745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 가이드 내용"])},
  "0746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 메뉴명"])},
  "0747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360도 배경 영상을 첨부해 주세요"])},
  "0749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 파일을 첨부해 주세요"])},
  "0750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mp4 파일 포맷만 업로드 가능합니다"])},
  "0751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복되는 권한명이 존재합니다"])},
  "0752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역 코드"])},
  "0753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 지역 코드입니다"])},
  "0754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 되었습니다"])},
  "0755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 초기화 되었습니다"])},
  "0756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제 되었습니다"])},
  "0757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복되는 아이디가 있습니다"])},
  "0758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 이미지를 첨부해 주세요"])},
  "0759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 이미지를 첨부해 주세요"])},
  "0760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴업체 방 이미지를 첨부해 주세요"])},
  "0761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지를 첨부해 주세요"])},
  "0762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 언어 코드"])},
  "0763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 제휴처 명"])},
  "0764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 제휴처로 등록된 이프스퀘어 정보가\n있어 노출 여부를 N 또는 SOON 으로 \n수정 불가합니다"])},
  "0765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, png 파일 포맷만 업로드 가능합니다"])},
  "0766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어를 1개 이상 선택해 주세요"])},
  "0767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 이프스퀘어로 설정 되었습니다"])},
  "0768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자"])},
  "0769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 제휴처 카테고리에 등록된 제휴처 정보가 있어 삭제가 불가능합니다. "])},
  "0770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 제휴처 카테고리"])},
  "0771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숨김 처리 되었습니다"])},
  "0772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 처리 되었습니다"])},
  "0773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 시작시간이 종료시간보다 클 수 없습니다"])},
  "0774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 이미지를 첨부해 주세요"])},
  "0775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항에 사용할 수 없는 문자열이 있습니다"])},
  "0776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역코드가 선택한 이프스퀘어 지역코드와 같거나 선택한 이프스퀘어가 global이어야 합니다"])},
  "0777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF 파일만 업로드 가능합니다"])},
  "0778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png 파일만 업로드 가능합니다"])},
  "0779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상 처리 되었습니다"])},
  "0780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간 공지가 전송되었습니다"])},
  "0781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 라운지는 한 개만 추가할 수 있습니다"])},
  "0783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어"])},
  "0784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 노출 여부가 Y일 경우만 스페셜 여부 적용이 가능합니다"])},
  "0785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 시작시간을 올바르게 입력하세요"])},
  "0786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 종료시간을 올바르게 입력하세요"])},
  "0787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별(★)화면 대표이미지를 등록하세요"])},
  "0788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별(★)화면 공지 사항에 사용할 수 없는 허용되지 않는 문자열이 포함되어있습니다"])},
  "0789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마"])},
  "0790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 시작시간"])},
  "0791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 종료시간"])},
  "0792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 시작시간이 종료시간보다 클 수 없습니다"])},
  "0793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF 파일 이름에 특수 문자를 빼주세요"])},
  "0794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdf, jpg, png 파일을 첨부해 주세요"])},
  "0795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규 이미지를 첨부해 주세요"])},
  "0796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처를 선택해주세요"])},
  "0797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 시작시간을 올바르게 입력하세요"])},
  "0798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈 종료시간을 올바르게 입력하세요"])},
  "0799": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최대 ", _interpolate(_named("num")), "명까지 선택 가능합니다"])},
  "0800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커를 1명 이상 선택하세요"])},
  "0801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약사용자를 1명 이상 선택하세요"])},
  "0802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 이프스퀘어 테마 이름"])},
  "0803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원을 1명 이상 선택해 주세요"])},
  "0804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화 할 데이터가 존재하지 않습니다"])},
  "0805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없는 페이지 입니다"])},
  "0806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["존재하지 않는 이프스퀘어 NO입니다"])},
  "0807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료된 이프스퀘어 NO 입니다"])},
  "0808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "]\n유효한 이프스퀘어 NO 입니다"])},
  "0809": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 변경 하시겠습니까?"])},
  "0810": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 변경 하시겠습니까?"])},
  "0811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃 하시겠습니까?"])},
  "0812": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 삭제하시겠습니까?"])},
  "0813": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 삭제하시겠습니까?"])},
  "0814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 추가정보"])},
  "0815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 광고 배너 버튼"])},
  "0816": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 저장 하시겠습니까?"])},
  "0817": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 저장 하시겠습니까?"])},
  "0818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너"])},
  "0819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 수정"])},
  "0820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 URL"])},
  "0821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["툴팁"])},
  "0822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드"])},
  "0823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글"])},
  "0824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답글"])},
  "0825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content"])},
  "0826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 이프스퀘어를 스페셜에 숨김 하시겠습니까?"])},
  "0827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 이프스퀘어를 스페셜에 노출 하시겠습니까?"])},
  "0828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 추천 이프스퀘어"])},
  "0829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 이프스퀘어를 추천 이프스퀘어로 설정 하시겠습니까?"])},
  "0830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png 파일 만 등록해 주세요 (20MB이하)"])},
  "0831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph 설정 정보를 포함하여 수정 하였습니다. 저장 할 경우 기존 생성된 딥링크는 사용하지 못하고, 저장시 생성되는 새 딥링크를 사용해야 합니다. 이프스퀘어 정보를 저장 하시겠습니까?"])},
  "0832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마"])},
  "0833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어를 종료하시겠습니까?"])},
  "0834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 이프스퀘어가 모두 변경됩니다. 변경하시겠습니까?"])},
  "0835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph 설정은 수정이 불가합니다. 해당 설정으로 진행하시겠습니까?"])},
  "0836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 이프스퀘어를 활성화 하겠습니까?"])},
  "0837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간 공지를 이프스퀘어에 전송 하시겠습니까?"])},
  "0838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 사용자 마이크를 OFF 하시겠습니까?"])},
  "0839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 이프스퀘어를 강제종료 하시겠습니까?"])},
  "0840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 이프스퀘어의 Telp룸을 생성 하시겠습니까?"])},
  "0841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트만 말하기를 ON(or OFF) 하시겠습니까?"])},
  "0842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴를 삭제 하시겠습니까? 포함된 하위 메뉴도 모두 삭제됩니다"])},
  "0843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[주의] 해당 관리자의 비밀번호를 0000으로 초기화 하시겠습니까? 해당 관리자에게 비밀번호 초기화 공지가 필요합니다"])},
  "0844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자로 승인하시겠습니까?"])},
  "0845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 계정의 계정 잠금을 해제하시겠습니까?"])},
  "0846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 관리자"])},
  "0847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 관리자를 등록하시겠습니까?"])},
  "0848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
  "0849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 요청을 승인 하시겠습니까?"])},
  "0850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 요청을 취소 하시겠습니까? 해당 레코드가 삭제 됩니다."])},
  "0851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 요청 정보"])},
  "0852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 제휴처로 등록된 이프스퀘어 정보가 있어 삭제가 불가합니다"])},
  "0853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없음"])},
  "0854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈"])},
  "0855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 만들기"])},
  "0856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 활동"])},
  "0857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
  "0858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인"])},
  "0859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 연결"])},
  "0860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내부 앱 내 동작"])},
  "0861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 시작 알람"])},
  "0862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 참가 연결"])},
  "0863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png 파일만 가능합니다"])},
  "0864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 사이즈를 확인하세요"])},
  "0865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 버튼 이름"])},
  "0866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 이프스퀘어 버튼"])},
  "0867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 이프스퀘어 NO를 입력해 주세요"])},
  "0868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 요청 일시"])},
  "0869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 권한 개수를 초과하였습니다"])},
  "0870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마 유형 선택"])},
  "0871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF 파일 / jpg, jpeg, png 파일만 업로드 가능합니다"])},
  "0872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland 초대"])},
  "0873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["너와 내가 만나는 메타버스 세상"])},
  "0874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App 생성 이프스퀘어"])},
  "0875": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["현재 접속중인 Admin 웹 사이트는 ", _interpolate(_named("env")), "입니다"])},
  "0876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메인"])},
  "0877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 이프스퀘어 번호"])},
  "0878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랜드 수정 해당 APP 생성 랜드가 종료 되어 수정이 불가합니다"])},
  "0879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 입력해 주세요"])},
  "0880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 비밀번호가 일치하지 않습니다"])},
  "0881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 아이디가 없습니다"])},
  "0882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 잠겨있습니다. 관리자에게 문의해주세요"])},
  "0883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 참여 형태"])},
  "0884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 호스트 회원번호"])},
  "0885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 NO 입력 범위를 초과하였습니다. 입력가능 범위는 [0 ~ 999999999] 입니다"])},
  "0886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영소문자로 시작하며 숫자 포함한 4~12자를 입력해 주세요"])},
  "0887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 시작일"])},
  "0888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 종료일"])},
  "0889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 NO"])},
  "0890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 NO"])},
  "0891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 번호"])},
  "0892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 가이드 NO"])},
  "0893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 NO"])},
  "0894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 정보"])},
  "0895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 테마 NO"])},
  "0896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 이프스퀘어 NO"])},
  "0897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 NO"])},
  "0898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 카테고리"])},
  "0899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 NO"])},
  "0900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 NO"])},
  "0901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 NO"])},
  "0902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 명"])},
  "0903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 NO"])},
  "0904": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 NO"])},
  "0905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 이력 NO"])},
  "0906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 선택"])},
  "0907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 제휴처 카테고리에 등록된 제휴처 정보가 있어 노출 여부 N으로 수정 불가합니다"])},
  "0908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천랜드일 경우 스피커 값은 필수입니다"])},
  "0912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강퇴"])},
  "0913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자 변경"])},
  "0914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이크 회수"])},
  "0915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전환"])},
  "0916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여상태"])},
  "0917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장제한 해제"])},
  "0918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이크 제공"])},
  "0919": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 변경"])},
  "0920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 설정 변경"])},
  "0921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 호스트"])},
  "0922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자 정보"])},
  "0923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자 없음 (누구나 제어)"])},
  "0924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료 상태"])},
  "0925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료 변경"])},
  "0926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아바타 참여제한\n여부"])},
  "0927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오디오 참여제한\n여부"])},
  "0928": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지 타입"])},
  "0929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장 가능"])},
  "0930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장 제한"])},
  "0931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 내 공지"])},
  "0932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버(월드) 공지"])},
  "0933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4D Video 상태"])},
  "0934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정지"])},
  "0935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자 상태를 변경하시겠습니까?"])},
  "0936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프미"])},
  "0937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오디오"])},
  "0938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강퇴한 회원의 재입장을 허용하시겠습니까?"])},
  "0939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강퇴한 회원은 동일 밋업룸에 재입장이 불가합니다. 진행하시겠습니까?"])},
  "0940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자를 변경 하시겠습니까?"])},
  "0941": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["회원번호:", _interpolate(_list(0)), " 의 마이크를 회수 하시겠습니까?"])},
  "0942": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["회원번호:", _interpolate(_list(0)), " 의 마이크를 제공 하시겠습니까?"])},
  "0943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회 실패"])},
  "0944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영상 미등록"])},
  "0945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경되었습니다"])},
  "0946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 설정을 변경하시겠습니까?"])},
  "0947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후원 설정이 변경되었습니다"])},
  "0948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료를 변경하시겠습니까?"])},
  "0949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트를 변경 하시겠습니까?"])},
  "0950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 활성화"])},
  "0953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제 종료 사유를 선택해주세요"])},
  "0959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 Video"])},
  "0965": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
  "0966": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "를 제거하시겠습니까?"])},
  "0967": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "을 제거하시겠습니까?"])},
  "0968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content를 선택해 주세요"])},
  "0969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content 선택"])},
  "0970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서 LEVEL1"])},
  "0971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서 LEVEL2"])},
  "0972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-1, 0, 1 이상의 양수를 입력해 주세요"])},
  "0973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거 되었습니다"])},
  "0974": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 확인해 주세요"])},
  "0975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼 명"])},
  "0976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미노출"])},
  "0977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 목록 미리보기"])},
  "0978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제 종료 사유 선택"])},
  "0979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 이프스퀘어가 없습니다"])},
  "0980": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 이하의 파일만 업로드 가능합니다"])},
  "0981": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 파일만 업로드 가능합니다"])},
  "0982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 명"])},
  "0983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 리스트 배너가 없습니다"])},
  "0984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경할 PDF 파일을 업로드 해주세요"])},
  "0985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료 URL을 입력해주세요"])},
  "0986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경할 이미지 파일을 업로드 해주세요"])},
  "0987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 NO"])},
  "0988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 전면팝업 배너가 없습니다"])},
  "0989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 리스트 배너가 없습니다"])},
  "0990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 별 이프스퀘어가 없습니다"])},
  "0991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 카테고리 명을 입력해 주세요"])},
  "0992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 제휴처 카테고리 명"])},
  "0993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 접속 중인 사이트는"])},
  "0994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland Admin System"])},
  "0995": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입니다"])},
  "0996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Refresh를 설정 하시겠습니까?\nAuto Refresh 설정 시 10초마다 화면이 Refresh 됩니다"])},
  "0997": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Refresh 설정을 해제 하시겠습니까?"])},
  "0998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참가 키"])},
  "0999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약서버 번호"])},
  "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 이프스퀘어 NO Validation을 진행해 주세요"])},
  "1001": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 삭제하시겠습니까?"])},
  "1002": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 삭제하시겠습니까?"])},
  "1003": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 입력해 주세요"])},
  "1004": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 입력해 주세요"])},
  "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표 자료 등록"])},
  "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강퇴한 회원은 동일 이프스퀘어에 재입장이 불가합니다. 진행 하시겠습니까?"])},
  "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이스페이스"])},
  "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+버튼"])},
  "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh 설정"])},
  "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 정보는 최대 2개만 등록 가능합니다"])},
  "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자가 변경 되었습니다"])},
  "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 이프스퀘어일 경우 스피커 값은 필수입니다"])},
  "1013": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 이하의 ", _interpolate(_list(1)), " 파일만 업로드 가능합니다"])},
  "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 이프스퀘어 테마가 없습니다"])},
  "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 제휴처 카테고리가 없습니다"])},
  "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 제휴처가 없습니다"])},
  "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 별 이프스퀘어가 없습니다"])},
  "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 스페셜 이프스퀘어가 없습니다"])},
  "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW(Y)로 변경시 TRO룸 연결을 진행 하셔야 합니다"])},
  "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 하나는 적용이 되어야 합니다"])},
  "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geofence 지역 코드"])},
  "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 접속 가능 여부"])},
  "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 지역"])},
  "1025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 관리"])},
  "1026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 신고 관리"])},
  "1027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 상태"])},
  "1028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 유형"])},
  "1029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 신고"])},
  "1030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 접수"])},
  "1031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 반려"])},
  "1032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처리 완료"])},
  "1033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음란, 청소년 유해"])},
  "1034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["욕설, 비방, 차별, 혐오"])},
  "1035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 노출, 거래"])},
  "1036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도배, 스팸"])},
  "1037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
  "1038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처리 결과\n(제재 유형)"])},
  "1039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 이력"])},
  "1040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접수"])},
  "1041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려"])},
  "1042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처리"])},
  "1043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제재 해제"])},
  "1044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 순번"])},
  "1045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 사용자 ID"])},
  "1046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 상태 변경"])},
  "1047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고 사유"])},
  "1048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 정보"])},
  "1049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운지 자료 관리"])},
  "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 정보"])},
  "1051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴처 등록 시 노출 여부를 SHOW로 선택할 수 없습니다"])},
  "1053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 혹은 비밀번호가 일치하지 않습니다"])},
  "1054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 계정 승인 후 로그인 가능합니다"])},
  "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드가 일치하지 않습니다"])},
  "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 인증 코드 입니다"])},
  "1057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 아이디로 등록된 관리자가 없습니다. 다시 확인해주세요"])},
  "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 처리 중 에러가 발생했습니다. \n관리자에게 문의 바랍니다"])},
  "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 라운지에서 발표자료를 변경하면 하위 라운지도 모두 변경됩니다. \n변경 하시겠습니까?"])},
  "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 라운지에서 이프스퀘어 실시간 공지를 등록하면 하위 라운지에도 모두 전송됩니다. \n실시간 공지를 라운지 이프스퀘어에 전송 하시겠습니까?"])},
  "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO룸 연결 후 노출 여부 Y로 변경이 가능합니다"])},
  "1063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jumpvr:// 로 시작하는 URL"])},
  "1064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
  "1065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어는 최대 2개까지 선택 가능합니다"])},
  "1066": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 선택해 주세요"])},
  "1067": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 선택해 주세요"])},
  "1068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 ID"])},
  "1069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리 지역을 1개 이상 선택해 주세요"])},
  "1070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 등록"])},
  "1071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 로그인"])},
  "1072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 회원가입"])},
  "1073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입을 위하여 회원가입 양식을 작성해 주세요"])},
  "1074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영소문자, 숫자, 특수문자 각각 1개 이상 포함된 9 ~ 20자"])},
  "1075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증코드 발송"])},
  "1076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드"])},
  "1077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드 확인"])},
  "1078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["슈퍼 관리자가 최종적으로 승인 후, 정상적으로 등록이 완료됩니다"])},
  "1079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
  "1080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 가입 > 관리자 계정 승인 후, FIDO2를 등록할 수 있습니다"])},
  "1081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디가 없는 경우, 계정을 새로 만들어 주세요"])},
  "1082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증이 완료 되었습니다"])},
  "1083": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["관리자 회원 가입 시 입력한 이메일 ", _interpolate(_list(0)), " 로 인증 코드가 발송되었습니다"])},
  "1084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드 확인을 진행해 주세요"])},
  "1085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 등록 완료되었습니다."])},
  "1086": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["FIDO2 등록에 실패했습니다. (", _interpolate(_list(0)), ")"])},
  "1087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2를 지원하는 환경이 아닙니다"])},
  "1088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO 인증이 수행되지 않았습니다"])},
  "1089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 등록 확인 후, 인증을 다시 시도해주세요"])},
  "1090": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["FIDO2 등록 확인 후, 인증을 다시 시도해주세요. (", _interpolate(_list(0)), ")"])},
  "1091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영소문자로 시작하고 숫자를 포함한 4 ~ 12자를 입력해 주세요"])},
  "1092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 비밀번호와 일치하지 않습니다"])},
  "1093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 비밀번호와, 재확인 비밀번호가 일치하지 않습니다"])},
  "1094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 일치"])},
  "1095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소를 확인해 주세요"])},
  "1096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 ID 선택"])},
  "1097": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["입력하신 이메일 ", _interpolate(_list(0)), " 로 인증 코드가 발송되었습니다"])},
  "1098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드 확인을 진행해 주세요"])},
  "1099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 선택된 권한 입니다"])},
  "1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 계정당 최대 부여 권한 개수는 20개 입니다"])},
  "1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서로 다른 권한 명을 선택해 주세요"])},
  "1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 명"])},
  "1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 코드"])},
  "1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더"])},
  "1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 등록"])},
  "1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 설명"])},
  "1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원"])},
  "1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 검색을 통해 리더를 선택해 주세요"])},
  "1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 검색을 통해 구성원을 선택해 주세요"])},
  "1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더 및 구성원"])},
  "1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 검색"])},
  "1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 정보"])},
  "1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 NO"])},
  "1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원 수"])},
  "1115": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 저장 하시겠습니까?"])},
  "1116": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 저장 하시겠습니까?"])},
  "1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹"])},
  "1118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 수정"])},
  "1119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1차 작업 완료 여부"])},
  "1120": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 삭제 하시겠습니까?"])},
  "1121": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 삭제 하시겠습니까?"])},
  "1122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 Key"])},
  "1123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 Key를 입력해 주세요"])},
  "1124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 선택"])},
  "1125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스등록"])},
  "1126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 정보"])},
  "1127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 ID"])},
  "1128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 명"])},
  "1129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 리소스 정보가 없습니다"])},
  "1130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 이력 아이디"])},
  "1131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 이력 아이디를 입력해 주세요"])},
  "1132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스"])},
  "1133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 사용자 그룹 개수를 초과하였습니다"])},
  "1134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서로 다른 사용자 그룹"])},
  "1135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 코드"])},
  "1136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 설명"])},
  "1137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 권한 및 메뉴 리소스 권한 선택"])},
  "1138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 선택해제"])},
  "1139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없습니다"])},
  "1140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주그룹"])},
  "1141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주 그룹 변경을 위해 현재 비밀번호를 확인합니다"])},
  "1142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주 그룹 변경"])},
  "1143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서"])},
  "1144": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["권한은 최대 ", _interpolate(_list(0)), "개까지 등록 가능합니다"])},
  "1145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 없음"])},
  "1146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 선택"])},
  "1147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹의 리더인 경우, 사용자 그룹 수정이 불가합니다"])},
  "1148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 관리 메뉴에서 사용자 그룹 리더를 변경한 후에 사용자 그룹을 수정할 수 있습니다"])},
  "1149": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["해당 관리자는 [", _interpolate(_list(0)), "]의 리더이므로 관리자 삭제가 불가 합니다. \n사용자 그룹 관리 메뉴에서 사용자 그룹 리더를 변경한 후에 관리자 삭제를 진행해 주세요"])},
  "1150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터가 없습니다"])},
  "1151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주"])},
  "1152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업자 IP"])},
  "1153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규"])},
  "1154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 승인 처리를 위하여 아래 항목을 입력해 주세요"])},
  "1155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 정보 요청"])},
  "1156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구분"])},
  "1157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기 승인"])},
  "1158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영문, 숫자, 특수문자 포함된 9 ~ 20자를 입력해 주세요"])},
  "1159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경 요청"])},
  "1160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경하신 지 6개월이 지났습니다"])},
  "1161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 문자가 4자리 이상 사용되었습니다. \n비밀번호를 다시 입력해 주세요"])},
  "1162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4회 이상 연속된 문자가 사용되었습니다. \n비밀번호를 다시 입력해 주세요"])},
  "1163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID가 포함된 문자는 비밀번호로 사용할 수 없습니다"])},
  "1164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 비밀번호와 동일합니다. \n새로운 비밀번호를 입력해 주세요"])},
  "1165": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["검색어는 ", _interpolate(_list(0)), "자 이상 입력해 주세요"])},
  "1166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 계정을 안전하게 보호하기 위해 비밀번호를 변경해 주세요"])},
  "1167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 변경하지 않을 경우 ifland Admin System 이용이 불가합니다"])},
  "1168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["허용되지 않는 특수문자가 포함되어 있습니다"])},
  "1169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 다 이프미 사용 여부"])},
  "1170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리뉴얼된 ifland Admin System 이용을 위해 아래 항목을 입력해 주세요"])},
  "1171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["슈퍼 관리자가 최종적으로 승인 후, 정상적으로 로그인 하실 수 있습니다"])},
  "1172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 히스토리"])},
  "1173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 항목"])},
  "1174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 전"])},
  "1175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 후"])},
  "1176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정자 IP"])},
  "1177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 히스토리 이미지"])},
  "1178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정자ID 검색"])},
  "1179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 사용자 그룹 key 입니다"])},
  "1180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 사용자 그룹 key 입니다"])},
  "1181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 정보 입력 요청"])},
  "1182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 정보 입력이 완료되었습니다. 슈퍼 관리자가 승인하면 기존 계정으로 ifland Admin System을 이용하실 수 있습니다"])},
  "1183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 그룹 key Validation을 진행해 주세요"])},
  "1184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin 테스트 이프스퀘어"])},
  "1185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin 운영 이프스퀘어"])},
  "1186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin 제휴 이프스퀘어"])},
  "1187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin 생성 이프스퀘어"])},
  "1188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Item 관리"])},
  "1189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래방 관리"])},
  "1190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신곡"])},
  "1191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기곡"])},
  "1192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 번호"])},
  "1193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 제목"])},
  "1194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트"])},
  "1195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내곡"])},
  "1196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체곡"])},
  "1197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가 정보"])},
  "1198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 설정"])},
  "1199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신곡 설정"])},
  "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신곡 리스트에 추가하고 싶은 곡"])},
  "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신곡 리스트에서 제외하고 싶은 곡"])},
  "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가곡 리스트"])},
  "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 검색을 통해 선택해 주세요"])},
  "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장르"])},
  "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 검색"])},
  "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기곡 설정"])},
  "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외곡 리스트"])},
  "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기곡 리스트에 추가하고 싶은 곡"])},
  "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기곡 리스트에서 제외하고 싶은 곡"])},
  "1210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 타입"])},
  "1211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가수"])},
  "1212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준"])},
  "1213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 리스트는 00시 00분 00초에 자동 갱신 됩니다"])},
  "1214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 관리"])},
  "1215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 타입"])},
  "1216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 NO"])},
  "1217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["썸네일"])},
  "1218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 이름"])},
  "1219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 등록"])},
  "1220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래"])},
  "1221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래, 가수 중 한가지 항목은 필수로 입력해 주세요"])},
  "1222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신순"])},
  "1223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기순"])},
  "1224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 순서"])},
  "1225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 수정"])},
  "1226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프렌더 검색"])},
  "1227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프렌더 검색을 통해 선택해 주세요"])},
  "1228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])},
  "1229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복되지 않은 태그를 입력하고 엔터키를 눌러주세요. (최대 5개)"])},
  "1230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 이름 검색"])},
  "1231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 정보"])},
  "1232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 관리"])},
  "1233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 등록"])},
  "1234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 수정"])},
  "1235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 정보"])},
  "1236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 NO"])},
  "1237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 코드"])},
  "1238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 이름"])},
  "1239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 방식"])},
  "1240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단위"])},
  "1241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 랭킹 노출 여부"])},
  "1242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 랭킹 노출 순서"])},
  "1243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PD 연결"])},
  "1244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 랭킹 노출 순서 변경"])},
  "1245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRA PID"])},
  "1246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프랍 아이디"])},
  "1247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRA 이름"])},
  "1248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 포인트"])},
  "1249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상"])},
  "1250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
  "1251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 관리"])},
  "1252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 등록"])},
  "1253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 수정"])},
  "1254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 정보"])},
  "1255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 NO"])},
  "1256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 계산 여부"])},
  "1257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이름"])},
  "1258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
  "1259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 기간"])},
  "1260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 랭킹"])},
  "1261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 기록 ID"])},
  "1262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 시점"])},
  "1263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
  "1264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상일자"])},
  "1265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 생성 이후 해당 룸에 연결된 Volumetric Content는 수정해도 반영되지 않습니다"])},
  "1266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 정보"])},
  "1267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이름 입력"])},
  "1268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내"])},
  "1269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝송"])},
  "1270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J-POP"])},
  "1271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템"])},
  "1272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트"])},
  "1273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 방식 선택"])},
  "1274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오름차순"])},
  "1275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내림차순"])},
  "1276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
  "1277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
  "1278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주차"])},
  "1279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 제외곡으로 설정된 곡이 포함되어 있습니다"])},
  "1280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 추가곡으로 설정된 곡이 포함되어 있습니다"])},
  "1281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 순서가 중복된 곡이 있습니다"])},
  "1282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡"])},
  "1283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이미지"])},
  "1284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상은 최대 10개까지 등록 가능합니다"])},
  "1285": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["보상 TOP ", _interpolate(_list(0)), "을 입력해 주세요"])},
  "1286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 플레이 아이템 이름"])},
  "1287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 단위"])},
  "1288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 플레이 아이템 코드 입니다"])},
  "1289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 문구"])},
  "1290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 가능일"])},
  "1291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출처"])},
  "1292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 검색"])},
  "1293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 검색을 통해 선택해 주세요"])},
  "1294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마"])},
  "1295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 문구 미 입력 시 해당 숏폼 테마는 사용자 APP에 미노출 됩니다"])},
  "1296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 이상의 숫자를 입력해 주세요. 최근 N(입력 숫자)일 내의 콘텐츠가 사용자 APP에 노출 됩니다"])},
  "1297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가수+노래"])},
  "1298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래 only"])},
  "1299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가수 only"])},
  "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과"])},
  "1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프네컷"])},
  "1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정사용자"])},
  "1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 전체"])},
  "1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프추천"])},
  "1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 랭킹 순서"])},
  "1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 플레이 아이템이 없습니다"])},
  "1307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이미지 검색"])},
  "1308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 NO"])},
  "1309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "1310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일명"])},
  "1311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max 값을 입력해 주세요"])},
  "1312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이미지 검색을 통해 선택해 주세요"])},
  "1313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 플레이 컴포넌트 이름"])},
  "1314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 계산 여부 > max를 입력해 주세요"])},
  "1315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 숏폼 테마 이름"])},
  "1316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 설명"])},
  "1317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 - 이프렌더"])},
  "1318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 - 효과"])},
  "1319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 마케팅 문구"])},
  "1320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해외곡"])},
  "1321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스크린"])},
  "1322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래방"])},
  "1323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출처 선택"])},
  "1324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과는 최대 10개까지 등록 가능합니다"])},
  "1325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 - 효과를 입력해 주세요"])},
  "1326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복된 효과가 존재합니다"])},
  "1327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 컴포넌트 이미지를 선택해 주세요"])},
  "1328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["크로마키 영상 여부"])},
  "1329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드 유효시간이 만료되었습니다"])},
  "1330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력 가능한 횟수를 초과하여 계정이 잠겼습니다.\n관리자에게 문의해 주세요."])},
  "1331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 검색 후 곡을 1개 이상 선택해 주세요"])},
  "1332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프렌더를 1명 이상 선택해 주세요"])},
  "1333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프렌더 검색 후 이프렌더를 선택해 주세요"])},
  "1334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡을 1개 이상 선택해 주세요"])},
  "1335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 여부"])},
  "1336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패 사유"])},
  "1337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 처리"])},
  "1338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상을 재 지급 하시겠습니까?"])},
  "1339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상이 지급되었습니다"])},
  "1340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곡 리스트는 매일 00시에 자동 갱신 됩니다"])},
  "1341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상처리에 실패했습니다."])},
  "1342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PD ID"])},
  "1343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROP 이름"])},
  "1344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROP 설명"])},
  "1345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 인기 소식 관리"])},
  "1346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명예의 전당"])},
  "1347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물 ID"])},
  "1348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성 내용"])},
  "1349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물 등록 일시"])},
  "1350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은 숏폼"])},
  "1351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼"])},
  "1352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 검색"])},
  "1353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 검색을 통해 선택해 주세요"])},
  "1354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 인기 소식"])},
  "1355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 보기"])},
  "1356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 검색 후 숏폼을 선택해 주세요"])},
  "1357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 인기 소식 정보"])},
  "1358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 인기 소식 수정"])},
  "1359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 인기 소식 등록"])},
  "1360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물 상태"])},
  "1361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검수 상태"])},
  "1362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영자 확인"])},
  "1363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검수 결과"])},
  "1364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨텐츠 번호"])},
  "1365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검수 성인 결과"])},
  "1366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검수 선정적 결과"])},
  "1367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검수 종류"])},
  "1368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 이름 선택"])},
  "1369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 ID"])},
  "1370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사전 차단된 게시물"])},
  "1371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정책위반으로 인한 삭제"])},
  "1372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정책 위반"])},
  "1373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 탈퇴"])},
  "1374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글 삭제"])},
  "1375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기"])},
  "1376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안심"])},
  "1377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인 완료"])},
  "1378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미확인"])},
  "1379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏폼 테마 이름을 선택해 주세요"])},
  "1380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 범위"])},
  "1381": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["발표 자료 변경에 실패했습니다\n[", _interpolate(_list(0)), "]"])},
  "1382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 명"])},
  "1383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 추가"])},
  "1384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 검색"])},
  "1385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 명 검색"])},
  "1386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 된 파일이 유효하지 않습니다"])},
  "1387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 검색을 통해 선택해 주세요"])},
  "1388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과를 1개 이상 선택해 주세요"])},
  "1389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 효과로 등록된 숏폼 테마 정보가 있어 삭제가 불가합니다"])},
  "1390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 효과 명 입니다"])},
  "1391": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "]\n배너의 노출순서가 없어서 저장이 되지 않고 있으니 해당 배너에 가서 노출순서를 저장하세요"])},
  "1392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노래방/이프네컷"])},
  "1393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
  "1394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 이름 입력"])},
  "1395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 서버"])},
  "1396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmasking 관리"])},
  "1397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 입력 후 인증이 완료 되면 사용자 정보가 Unmasking 됩니다"])},
  "1398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증이 완료 되었습니다"])},
  "1399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 포인트 획득 모니터링"])},
  "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["획득 재화"])},
  "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["획득 일시"])},
  "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 unmasking 인증 상태입니다"])},
  "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 유효기간"])},
  "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 연장하기"])},
  "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 재입력합니다"])},
  "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증은 인증 시점 부터 30분간 유효합니다"])},
  "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 인증 유효기간을 확인해 주세요"])},
  "1408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여상태가 이프미가 아닌 유저는 호스트로 변경 할 수 없습니다"])},
  "1409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여상태가 이프미가 아닌 유저는 발표자변경을 할 수 없습니다"])},
  "1410": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Telp 오류가 발생했습니다\n(error code: ", _interpolate(_list(0)), " / 오류 사유: ", _interpolate(_list(1)), ")"])},
  "1411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 관리"])},
  "1412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경"])},
  "1413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["집"])},
  "1414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 공개 여부"])},
  "1415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 여부"])},
  "1416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 NO"])},
  "1417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공간 이미지"])},
  "1418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어 점수"])},
  "1419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today 방문자수"])},
  "1420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total 방문자수"])},
  "1421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
  "1422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화 여부"])},
  "1423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 정보"])},
  "1424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 상세 정보"])},
  "1425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동적 Prop 최대 개수"])},
  "1426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 현황"])},
  "1427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최종 방문 일시"])},
  "1428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 참여 인원"])},
  "1429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 저장 경로"])},
  "1430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공간 이미지 수정 가능 여부"])},
  "1431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome으로 설정되어 있어 삭제가 불가합니다"])},
  "1432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 및 집이 변경되면 ifhome이 초기화 됩니다. 정말 변경 하시겠습니까?"])},
  "1433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 모니터링 및 제어"])},
  "1434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 생성 여부"])},
  "1435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 상태"])},
  "1436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 상태 변경"])},
  "1437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제 비공개 사유 선택"])},
  "1438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제 비공개"])},
  "1439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 초기화 선택시, 배경/집만 유지 됩니다 (ifhome 최초 생성 상태로 처리)"])},
  "1440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정말 초기화 하시겠습니까?"])},
  "1441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 발표 자료"])},
  "1442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 재생 여부"])},
  "1443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액자 정보"])},
  "1444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 액자 자료"])},
  "1445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액자 자료 변경"])},
  "1448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDIA"])},
  "1449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMAGE"])},
  "1450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개"])},
  "1451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영 정책 위반"])},
  "1452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영상 비공개"])},
  "1453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 처리"])},
  "1454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개"])},
  "1455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개로 전환 하시겠습니까?"])},
  "1456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NONE"])},
  "1457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
  "1458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경할 mp4 파일을 업로드 해주세요"])},
  "1459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 관리"])},
  "1460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 여부"])},
  "1461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커버 이미지"])},
  "1462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 매핑"])},
  "1463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 매핑"])},
  "1464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 정보"])},
  "1465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 등록"])},
  "1466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 수정"])},
  "1467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커버 이미지를 첨부해 주세요"])},
  "1468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 계정 매핑"])},
  "1469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["집 선택"])},
  "1470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".json 파일"])},
  "1471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["txt"])},
  "1472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 여부"])},
  "1473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매핑 계정"])},
  "1474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 검색을 통해 선택해 주세요"])},
  "1475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 이미 매핑된 계정은 조회되지 않습니다"])},
  "1476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 검색"])},
  "1477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 Prop 매핑"])},
  "1478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP Refresh 설정"])},
  "1479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매핑 NO"])},
  "1480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop NO"])},
  "1481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop PD ID"])},
  "1482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 개수"])},
  "1483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 Refresh 되었습니다"])},
  "1484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 Prop 매핑 등록"])},
  "1485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 설정 Json"])},
  "1486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매핑 Prop"])},
  "1487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop"])},
  "1488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 검색"])},
  "1489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Item 여부"])},
  "1490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 프랍 여부"])},
  "1491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 카테고리"])},
  "1492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 json 규격"])},
  "1493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome 관리"])},
  "1494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스패셜 ifhome NO"])},
  "1495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 명"])},
  "1496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome NO"])},
  "1497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome 미리보기"])},
  "1498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 스페셜 ifhome이 없습니다"])},
  "1499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은 스페셜 ifhome"])},
  "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 검색"])},
  "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome"])},
  "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 검색 후 제휴 ifhome을 선택해 주세요"])},
  "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 제휴 ifhome을 스페셜 ifhome으로 설정 하시겠습니까?"])},
  "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome으로 설정 되었습니다"])},
  "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome 정보"])},
  "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome 상단 고정 등록"])},
  "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome  검색"])},
  "1508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 관리"])},
  "1509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문 기록 저장 여부"])},
  "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아바타 참여수"])},
  "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음성 참여수"])},
  "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 상세 정보"])},
  "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome"])},
  "1514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 수정"])},
  "1515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 공개 여부 선택"])},
  "1516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문 기록 저장 여부 선택"])},
  "1517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 공개"])},
  "1518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 관리"])},
  "1519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너"])},
  "1520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 목록 미리보기"])},
  "1521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 ifhome 배너가 없습니다"])},
  "1522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 등록"])},
  "1523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 종류"])},
  "1524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 종류 선택"])},
  "1525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 URL"])},
  "1526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome 관리"])},
  "1527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 에서 제공하는 오늘의 ifhome 목록과 기본적으로 동일한 ifhome이 제공 됩니다. (점수 순으로 100개)"])},
  "1528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome 기준  :  ifhome에서 아이템 수가 많은 순이며 아이템 수가 같을 시, 보유 아이템 카테고리가 더 다양한 순으로 제공 됩니다."])},
  "1529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome 등록을 통해 관리자가 직접 오늘의 ifhome 설정이 가능하며 해당 ifhome은  상단에 노출 됩니다."])},
  "1530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은 오늘의 ifhome"])},
  "1531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 검색을 통해 선택해 주세요"])},
  "1532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome"])},
  "1533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome 등록"])},
  "1534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome 수정"])},
  "1535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팔로잉"])},
  "1536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP 코드"])},
  "1537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 마케팅 URL 관리"])},
  "1538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 마케팅 URL 등록"])},
  "1539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 마케팅 URL 정보"])},
  "1540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 마케팅 URL 수정"])},
  "1541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개로 전환하시겠습니까?"])},
  "1542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프홈 사용자의 차단 목록에 추가됩니다. 진행 하시겠습니까?"])},
  "1543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내보내기"])},
  "1544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사용자를 내보내기 하시겠습니까?"])},
  "1545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome"])},
  "1546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 ifhome 수정"])},
  "1548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin 차단 회원"])},
  "1549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프홈 사용자의 차단 목록에 추가됩니다.\n팔로워, 팔로잉, 새소식 까지 삭제됩니다.\n진행하시겠습니까?"])},
  "1550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 사용자만 말하기\nON"])},
  "1551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 사용자만 말하기\nOFF"])},
  "1552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 이프스퀘어 NO"])},
  "1553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 이프스퀘어 NO Validation을 진행해 주세요"])},
  "1554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 버튼 ifhome User ID"])},
  "1555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 버튼 ifhome User ID Validation을 진행해 주세요"])},
  "1556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처"])},
  "1557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop 검색을 통해 선택해 주세요"])},
  "1558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 정보"])},
  "1559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 Prop 매핑 수정"])},
  "1560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 마케팅 URL"])},
  "1561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 제휴처 Prop 매핑 정보"])},
  "1562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 검색 후 계정을 선택해주세요"])},
  "1563": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 파일만 등록해 주세요(", _interpolate(_list(1)), "이하)"])},
  "1564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 선택"])},
  "1565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome"])},
  "1566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원번호 입력 후 validation을 진행해 주세요"])},
  "1567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원번호 validation을 진행해 주세요"])},
  "1568": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), " / ", _interpolate(_list(1)), "] 입니다"])},
  "1569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["존재하지 않는 회원 입니다"])},
  "1570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 검색 후 ifhome을 선택해 주세요"])},
  "1571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 ifhome을 오늘의 ifhome으로 설정 하시겠습니까?"])},
  "1572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 ifhome으로 설정 되었습니다"])},
  "1573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 오늘의 ifhome이 없습니다"])},
  "1574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP에서는 랜덤하게 제공되므로 노출 순서가 다를 수 있습니다."])},
  "1575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 고정 여부"])},
  "1576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 ifhome 배너가 없습니다"])},
  "1578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프홈 회원 연결"])},
  "1579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 URL은 'jumpvr://'로 시작되어야 합니다"])},
  "1580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 URL은 'https://' 또는 'http://' 로 시작되어야 합니다"])},
  "1581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차단 해제"])},
  "1582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차단을 해제 하시겠습니까?"])},
  "1583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 모니터링 및 제어"])},
  "1584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 고정 순서변경"])},
  "1585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 고정 등록"])},
  "1586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커, 예약 사용자를 1개 이상 선택해 주세요"])},
  "1587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스피커 및 예약 사용자 검색 후 스피커 및 예약 사용자를 선택해 주세요"])},
  "1588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 오늘의 ifhome"])},
  "1589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELOAD"])},
  "1590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어"])},
  "1591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 스페셜 ifhome"])},
  "1592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 RELOAD 되었습니다"])},
  "1593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 ifhome 검색시 회원번호나 닉네임을 필수로 입력해야지 일반 ifhome 검색이 가능합니다."])},
  "1594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방청객 최대 동접자수"])},
  "1595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관찰자 최대 동접자수"])},
  "1596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "1597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 json 파일입니다"])},
  "1598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 종류"])},
  "1599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 종류 선택"])},
  "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUSH 타이틀"])},
  "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUSH 템플릿"])},
  "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목별 입력 방식"])},
  "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 시작 월"])},
  "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 시작 일"])},
  "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 명"])},
  "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 금액"])},
  "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 타입"])},
  "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검증"])},
  "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스톤"])},
  "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
  "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 전송 여부"])},
  "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 실패 사유"])},
  "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재보상"])},
  "1614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송"])},
  "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 처리"])},
  "1616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림을 재전송 하시겠습니까?"])},
  "1617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송 되었습니다."])},
  "1620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 전송에 실패했습니다"])},
  "1621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 종료 월"])},
  "1622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹 산정 종료 일"])},
  "1623": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 검증해 주세요"])},
  "1624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 PUSH 템플릿입니다. 항목별 입력방식을 확인해 주세요"])},
  "1625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 수신 동의용 사용 여부"])},
  "1626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 수신 동의 유도 배너의 경우 노출순서 세팅과 관계없이, 14일마다 수신여부 체크 후 맨 첫번째 순서 배너로 노출됩니다"])},
  "1627": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이미 노출 중인 마케팅 수신 동의 배너가 있습니다. 확인 버튼 클릭 시 [", _interpolate(_list(0)), "]로 교체됩니다.\n정말로 저장 하시겠습니까?"])},
  "1628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
  "1629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅"])},
  "1630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 종류"])},
  "1631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 종류 선택"])},
  "1632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 문구 관리"])},
  "1633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문구 관리"])},
  "1634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문구 NO"])},
  "1635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문구 제목"])},
  "1636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문구 내용"])},
  "1637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 가입"])},
  "1638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 문구 등록"])},
  "1639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 문구 수정"])},
  "1640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 문구 정보"])},
  "1641": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["노출 기간 내 노출 중인 ", _interpolate(_list(0)), "가 이미 있습니다"])},
  "1642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈방지 팝업 문구 관리"])},
  "1646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈방지 팝업 문구 정보"])},
  "1647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈방지 팝업 문구 수정"])},
  "1648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈방지 팝업 문구 등록"])},
  "1649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈방지 팝업 문구"])},
  "1650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 문구"])},
  "1651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 정보는 최대 2개만 등록 가능 합니다"])},
  "1652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 종류"])},
  "1653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 종류 선택"])},
  "1654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 이름"])},
  "1655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 URL"])},
  "1656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 문구 내용"])},
  "1657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 팝업 버튼 이름"])},
  "1658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 다국어 언어 코드"])},
  "1659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 처리에 실패했습니다"])},
  "1660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 버튼 종류"])},
  "1661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고 배너 버튼 종류"])},
  "1662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 버튼 종류"])},
  "1663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 정보"])},
  "1664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앱 실행 로딩"])},
  "1665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 스퀘어/라운지"])},
  "1666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 스퀘어/라운지"])},
  "1667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게스트 이프홈"])},
  "1668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 이프홈"])},
  "1669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩"])},
  "1670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애니메이션 json"])},
  "1671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 유형"])},
  "1672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이 이프홈"])},
  "1673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타인 이프홈 첫번째"])},
  "1674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 첫번째"])},
  "1675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운지 첫번째"])},
  "1676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타인 이프홈 두번째"])},
  "1677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 두번째"])},
  "1678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운지 두번째"])},
  "1679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 URL은 'https://' 또는 'http://' 로 시작되어야 합니다"])},
  "1680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 버튼 URL은 'jumpvr://'로 시작되어야 합니다"])},
  "1681": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 파일을 업로드 해주세요"])},
  "1682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 타입"])},
  "1683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 타입 선택"])},
  "1684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 기간 내 노출 중인 이탈방지 팝업 문구가 이미 있습니다"])},
  "1685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크"])},
  "1686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 명"])},
  "1687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 종류"])},
  "1688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 URL"])},
  "1689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객문의"])},
  "1690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴문의"])},
  "1691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도움말 랜딩"])},
  "1692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 유튜브"])},
  "1693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 인스타그램"])},
  "1694": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "px * ", _interpolate(_list(1)), "px 사이즈 이하만 업로드 가능합니다"])},
  "1695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 화면"])},
  "1696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프홈"])},
  "1697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 화면 항목"])},
  "1698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드"])},
  "1699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 APP 화면 항목이 없습니다"])},
  "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 APP 화면 항목이 없습니다"])},
  "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운지"])},
  "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 이프랜드"])},
  "1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디폴트 언어"])},
  "1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디폴트 언어 선택 및 외부 링크 URL을 입력해 주세요"])},
  "1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 코드를 선택해 주세요."])},
  "1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 모델하우스 관리"])},
  "1707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 모델하우스 정보"])},
  "1708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 모델하우스 등록"])},
  "1709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 모델하우스 수정"])},
  "1710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 모델하우스"])},
  "1711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용 중인 ifhome 모델하우스가 있습니다"])},
  "1712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 중인 ifhome 모델하우스는 삭제 불가합니다. 사용 여부 N으로 변경 후에 삭제를 진행해 주세요"])},
  "1713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별 언어 관리"])},
  "1714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별 언어 정보"])},
  "1715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별 언어 등록"])},
  "1716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별 언어 수정"])},
  "1717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별 언어"])},
  "1718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용 메뉴"])},
  "1719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리 제목"])},
  "1720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 선택"])},
  "1721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 관리"])},
  "1722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 정보"])},
  "1723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 등록"])},
  "1724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 수정"])},
  "1725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회수"])},
  "1726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 제목"])},
  "1727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 태그 설정"])},
  "1728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 태그 명"])},
  "1729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 코드 설정"])},
  "1730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기 상세"])},
  "1731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 해상도"])},
  "1732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpeg,  png 파일 만 등록해 주세요 (5MB이하)"])},
  "1733": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] 공지사항 제목을 입력해 주세요"])},
  "1734": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] 공지사항 내용을 입력해 주세요"])},
  "1735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 코드를 1개 이상 추가해 주세요"])},
  "1736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 관리"])},
  "1737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 등록"])},
  "1738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 정보"])},
  "1739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 수정"])},
  "1740": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] FAQ 제목을 입력해 주세요"])},
  "1741": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] FAQ 내용을 입력해 주세요"])},
  "1742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
  "1743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 카테고리"])},
  "1744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 카테고리 명"])},
  "1745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 카테고리 코드"])},
  "1746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 FAQ 카테고리 명을 입력해 주세요"])},
  "1747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 언어 코드를 선택해 주세요"])},
  "1748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 FAQ 카테고리가 없습니다"])},
  "1749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 FAQ가 없습니다"])},
  "1750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 공지사항이 없습니다"])},
  "1751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 집에 있는 친구 관리"])},
  "1752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 집에 있는 친구 등록"])},
  "1753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 집에 있는 친구 정보"])},
  "1754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 집에 있는 친구 수정"])},
  "1755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 집에 있는 친구"])},
  "1756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 지금 집에 있는 친구를 제거 하시겠습니까?"])},
  "1757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 급상승 ifhome 관리"])},
  "1758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 급상승 ifhome 정보"])},
  "1759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 급상승 ifhome 등록"])},
  "1760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 급상승 ifhome 수정"])},
  "1761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 급상승 ifhome"])},
  "1762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 인기 급상승 ifhome을 제거 하시겠습니까?"])},
  "1763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 인기 급상승 ifhome이 없습니다"])},
  "1764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 지금 집에 있는 친구가 없습니다"])},
  "1765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 인기 급상승 ifhome이 없습니다"])},
  "1766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 인기 게시물이 없습니다"])},
  "1767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 게시물 관리"])},
  "1768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 게시물 정보"])},
  "1769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 게시물 등록"])},
  "1770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 게시물 수정"])},
  "1771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 게시물"])},
  "1772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 인기 게시물이 없습니다"])},
  "1773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물 검색을 통해 선택해 주세요"])},
  "1774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은 게시물"])},
  "1775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값"])},
  "1776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로퍼티 정보"])},
  "1777": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["최대 ", _interpolate(_list(0)), "개만 등록 가능합니다"])},
  "1778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 화면 항목 이름"])},
  "1779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP 화면 구분"])},
  "1781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 사이즈 규격을 확인해 주세요"])},
  "1782": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["선택된 ", _interpolate(_list(0)), "을 제거 하시겠습니까?"])},
  "1783": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["선택된 ", _interpolate(_list(0)), "를 제거 하시겠습니까?"])},
  "1784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물"])},
  "1785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 메뉴입니다"])},
  "1786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은"])},
  "1787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 메뉴가 포함되어 있습니다"])},
  "1788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 지역"])},
  "1789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 가능 지역 (Geofence)"])},
  "1790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 선호 지역 (접속 가능 지역이 모든 지역인 경우만 활성화)"])},
  "1791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 스페셜 ifhome이 없습니다"])},
  "1792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 기간 내 노출 중인 외부 링크가 이미 있습니다"])},
  "1793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 공통"])},
  "1794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩 관리"])},
  "1795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ 카테고리 관리"])},
  "1796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 태그"])},
  "1797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 상단 태그는 상단 고정 설정된 공지사항 제목 앞에 노출 됩니다."])},
  "1798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["튜토리얼 라운지"])},
  "1799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["튜토리얼 이프홈"])},
  "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 데이터가 없습니다"])},
  "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 관리"])},
  "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 등록"])},
  "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 수정"])},
  "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 정보"])},
  "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 내용"])},
  "1806": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이미 노출 중인 태그가 있습니다. 확인 버튼 클릭 시 [", _interpolate(_list(0)), "]로 교체됩니다.\n정말로 저장 하시겠습니까?"])},
  "1807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시물 검색 후 게시물을 선택해 주세요"])},
  "1808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 지역 변경 시, 상단 고정이 해지될 수 있습니다. 정말 변경 하시겠습니까?"])},
  "1809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 여부 변경 시, 상단 고정이 해지될 수 있습니다. 정말 변경 하시겠습니까?"])},
  "1810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "1811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 관리"])},
  "1812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 하는 질문 여부"])},
  "1815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 하는 질문 미리보기"])},
  "1816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 하는 질문 목록 미리보기"])},
  "1817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 자주 하는 질문이 없습니다"])},
  "1818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 자주 하는 질문이 없습니다."])},
  "1819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출시킬 공지사항이 없습니다"])},
  "1820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성된 내용이 있습니다. 삭제 하시곘습니까?"])},
  "1821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 하는 질문 순서 변경"])},
  "1822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 URL은 'http://' 또는 'https://' 로 시작되어야 합니다"])},
  "1823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스트 검색 후 호스트를 선택해 주세요"])},
  "1824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크 URL 정보에서 디폴트 언어로 선택한 언어 코드 또는 외부 링크 URL을 입력해 주세요"])},
  "1825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png 파일만 등록해 주세요 (100MB이하)"])},
  "1826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 서버 연동 오류가 발생했습니다. 잠시 후 다시 시도해 주세요"])},
  "1827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 사이즈 규격 : 1920 px * 900 px, png 파일 만 등록해 주세요 (20MB이하)"])},
  "1828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 ifhome 입니다"])},
  "1829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 게시물 입니다"])},
  "1830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 인기 소식 입니다"])},
  "1831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애니메이션 사이즈 규격 : 2400 px * 1080 px, "])},
  "1832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디폴트 언어로 선택한 언어 코드를 외부링크 URL 정보 내 입력해 주세요"])},
  "1833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 스페셜 ifhome 입니다"])},
  "1834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 통신 이벤트 이력"])},
  "1835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 이벤트 No"])},
  "1836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통신 이벤트 유형"])},
  "1837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정상 종료 여부"])},
  "1838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성 일시"])},
  "1839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 회원 번호"])},
  "1840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 입장 일시"])},
  "1841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 체류 시간"])},
  "1842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동접자 수"])},
  "1843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어 관리"])},
  "1844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 이름"])},
  "1845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 언어 코드 입니다."])},
  "1846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 태그 관리"])},
  "1847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할 언어 태그가 없습니다"])},
  "1848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 지역별로 스페셜 ifhome 상단 고정 등록 및 삭제를 관리 합니다."])},
  "1849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페셜 ifhome 상단 고정으로 등록되어도 접속 지역에 따라 APP에서는 노출되지 않을 수 있습니다."])},
  "1850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어 등록"])},
  "1851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어 정보"])},
  "1852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 언어 수정"])},
  "1853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 태그 등록"])},
  "1854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 태그 정보"])},
  "1855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 태그 수정"])},
  "1856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 지역 이름"])},
  "1857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 관리"])},
  "1858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 등록"])},
  "1859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 수정"])},
  "1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드"])},
  "1861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 그룹 코드"])},
  "1862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 추가"])},
  "1863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 삭제"])},
  "1864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 명"])},
  "1865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["속성"])},
  "1866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제할 행을 선택해 주세요"])},
  "1867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 행을 삭제 하시겠습니까?"])},
  "1868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력된 내용을 초기화 하시겠습니까?"])},
  "1869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화 되었습니다"])},
  "1870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값이 유효하지 않습니다"])},
  "1871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용 중인 공통 코드가 있습니다"])},
  "1872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용 중인 공통 코드 명이 있습니다"])},
  "1873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 입력 항목입니다. 반드시 입력해 주세요"])},
  "1874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 뜨는 아이템 관리"])},
  "1875": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 뜨는 아이템 영역에 상단 고정할 아이템을 조회/등록/삭제할 수 있습니다."])},
  "1876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 고정 순서 변경은 관리 화면에서 선택된 지역 코드와 카테고리를 기준으로 노출됩니다."])},
  "1877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역 별로 최대 등록 가능한 개수는 15개입니다"])},
  "1878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템 번호"])},
  "1879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템 이름"])},
  "1880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템 종류"])},
  "1881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천"])},
  "1882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템"])},
  "1883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템 검색을 통해 선택해 주세요"])},
  "1884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템 검색"])},
  "1885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단에 고정하고 싶은 아이템"])},
  "1886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
  "1887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코스튬"])},
  "1888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모션"])},
  "1889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상단 고정 등록 가능한 최대 개수는 15개 입니다"])},
  "1890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 아이템 입니다"])},
  "1891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서변경 할  지금 뜨는 아이템이 없습니다"])},
  "1892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 그룹 코드 제목"])},
  "1893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 명(ko)"])},
  "1894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 명(en)"])},
  "1895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 코드 설명"])},
  "1896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통 그룹 코드 설명"])},
  "1897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 추가 버튼을 클릭하여 공통 코드를 등록할 수 있습니다"])},
  "1898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 뜨는 아이템"])},
  "1899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 최적화 (PNG > JPEG)"])},
  "1900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일 용량"])},
  "1901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나를 위한 추천 친구"])},
  "1902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘의 숏폼"])},
  "1903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구의 새소식"])},
  "1904": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마케팅 배너"])},
  "1905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘 꾸민 이프홈"])},
  "1906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 선호 지역"])},
  "1907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 지역 코드"])},
  "1908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 지역 코드 선택"])},
  "1909": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "은 최대 ", _interpolate(_list(1)), "개까지 선택 가능합니다"])},
  "1910": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "는 최대 ", _interpolate(_list(1)), "개까지 선택 가능합니다"])},
  "1911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 주제"])},
  "1912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이프스퀘어 주제 관리"])},
  "1913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주제"])},
  "1914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주제 추천 제목"])},
  "1915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주제 NO"])},
  "1916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, png 파일 만 등록해 주세요 (20MB이하)"])},
  "1917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 언어 코드"])},
  "1918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["노출 가능한 이프스퀘어 주제는 최대 8개 입니다. "])},
  "1919": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["순서변경 할 ", _interpolate(_list(0)), "이 없습니다"])},
  "1920": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["순서변경 할 ", _interpolate(_list(0)), "가 없습니다"])},
  "1921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 언어 코드는 최대 3개까지 선택 가능합니다"])},
  "1922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 룸"])},
  "1923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 최적화 옵션이 ‘ON’이고, 첨부파일 포멧이 ‘PNG’인 경우에만 배너 저장 시점에 첨부파일 포멧이 ‘JPEG’로 변환됩니다"])},
  "1924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 제휴 ifhome"])},
  "1925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹"])},
  "1926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 라운지"])},
  "1927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 룸"])},
  "1928": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 검색을 통해 선택해 주세요"])},
  "1929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 번호"])},
  "1930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 명"])},
  "1931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 내용"])},
  "1932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 검색"])},
  "1933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP HOTEL 관리"])},
  "1934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 팝업 문구 관리"])},
  "1935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팝업 문구"])},
  "1936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통"])},
  "1937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최적화된 첨부파일 용량"])},
  "1938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 검색 후 아티스트 그룹을 선택해 주세요"])},
  "1939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버 마이 이프홈 첫번째"])},
  "1940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS 관리"])},
  "1941": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 상태"])},
  "1942": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETE"])},
  "1943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR"])},
  "1944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 진도"])},
  "1945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환 요청 일시"])},
  "1946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환 완료 일시"])},
  "1947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재생 시간(ms)"])},
  "1948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 스트리밍 URL"])},
  "1949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 제목"])},
  "1950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동영상"])},
  "1951": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 진행 중..."])},
  "1952": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 완료 되었습니다"])},
  "1953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 업로드 처리 중… 잠시만 기다려주세요"])},
  "1954": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장에 실패했습니다"])},
  "1955": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key값"])},
  "1956": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobid"])},
  "1957": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 코드"])},
  "1958": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 메시지"])},
  "1959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원본 URL"])},
  "1960": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해상도별 결과물"])},
  "1961": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해상도 width"])},
  "1962": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해상도 height"])},
  "1963": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 bitrate"])},
  "1964": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "을 첨부해 주세요"])},
  "1965": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "를 첨부해 주세요"])},
  "1966": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 내용"])},
  "1967": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환 요청 일자"])},
  "1968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이콘 클릭 시 URL이 복사됩니다!"])},
  "1969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 로딩 관리"])},
  "1970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실층"])},
  "1971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운지 이미지"])},
  "1972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 배지 이미지"])},
  "1973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 아티스트 그룹 명"])},
  "1974": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 멤버"])},
  "1975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실번호"])},
  "1976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 명"])},
  "1977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 번호"])},
  "1978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 이미지"])},
  "1979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 선택"])},
  "1980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 아티스트 명"])},
  "1981": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 관리"])},
  "1982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 번호"])},
  "1983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 명"])},
  "1984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 내용"])},
  "1985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 이미지"])},
  "1986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수행 알림 내용"])},
  "1987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션"])},
  "1988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 수행완료 알림 내용"])},
  "1989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 보상 관리"])},
  "1990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 보상"])},
  "1991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 보상 번호"])},
  "1992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 보상 여부"])},
  "1993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬미션 보상명"])},
  "1994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 아이템 번호"])},
  "1995": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["유효한 ", _interpolate(_list(0)), " 입니다"])},
  "1996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 아이템"])},
  "1997": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["존재하지 않는 ", _interpolate(_list(0)), " 입니다"])},
  "1998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 번호"])},
  "1999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 보상 정보"])},
  "2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상 이미지"])},
  "2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 로딩"])},
  "2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 팝업 문구"])},
  "2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 팬 미션"])},
  "2004": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 검색을 통해 연결해 주세요"])},
  "2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아티스트 그룹 팬미션 번호"])},
  "2006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 달성 확인 여부"])},
  "2007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 달성 일시"])},
  "2008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 랜딩 파라미터"])},
  "2009": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Validation을 진행해 주세요"])},
  "2010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달성기준"])},
  "2011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달성 카운트"])},
  "2012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 상태"])},
  "2013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예정"])},
  "2014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
  "2015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달성"])},
  "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 불러오기"])},
  "2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 검색"])},
  "2018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 다국어"])},
  "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 선택"])},
  "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팬 미션 불러오기는 필수로 수행해야 합니다"])},
  "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복된 회원번호가 존재합니다"])},
  "2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 가이드 내용"])},
  "2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 사유"])},
  "2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송 상태"])},
  "2025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송 일시"])},
  "2026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송 실패 사유"])},
  "2027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
  "2028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패"])},
  "2029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["존재하는 회원번호가 없습니다"])},
  "2030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS 이메일 발송 이력"])},
  "2031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캐시 갱신"])},
  "2032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 캐시 갱신 되었습니다"])},
  "2033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캐시 갱신 실패했으니, 재시도 해주세요. 재 실패시 관리자에게 문의바랍니다."])},
  "2034": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이미 등록된 ", _interpolate(_list(0)), "이 있습니다."])},
  "2035": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이미 등록된 ", _interpolate(_list(0)), "가 있습니다."])},
  "2036": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이미지 사이즈 규격 : ", _interpolate(_list(0))])},
  "2037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 로고 이미지"])},
  "2038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 다국어 메시지"])},
  "2039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 다국어 메시지 관리"])},
  "2040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 컬럼 명"])},
  "2041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 명"])},
  "2042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 그룹 Key"])},
  "2043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 다국어 메시지 정보"])},
  "2044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP 다국어 메시지 수정"])},
  "2045": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["노출 기간 내 노출 중인 ", _interpolate(_list(0)), "이 이미 있습니다"])},
  "2046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 그룹 명"])},
  "2047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어 메시지"])},
  "2048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 테마"])},
  "2049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO 룸 정보 관리"])},
  "2050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌석 수"])},
  "2051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룸 타입"])},
  "2052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 타입"])},
  "2053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO 룸"])},
  "2054": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[게시물 ID: ", _interpolate(_list(0)), "]인 숏폼의 노출순서가 없어서 저장이 되지 않고 있으니 해당 숏폼에 가서 노출순서를 저장하세요"])},
  "2055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 룸"])},
  "2056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 룸"])},
  "2057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월드"])},
  "2058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY HOME"])},
  "2059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨퍼런스 룸"])},
  "2060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방청 룸"])},
  "2061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨퍼런스 이벤트"])},
  "2062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨퍼런스 이벤트 방청룸"])},
  "2063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입학식"])},
  "2064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단독 컨퍼런스"])},
  "2065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴 컨퍼런스"])},
  "2066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 달성자 엑셀 다운로드"])},
  "2067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수행 카운트 입력 여부"])},
  "2068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수행 카운트"])},
  "2069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수행 카운트 입력 사유"])},
  "2070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈시간의 종료시간을 현재시간 이후로 설정해 주세요"])},
  "2071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 번호"])},
  "2072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 다운로드가 완료되었습니다."])},
  "2073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 다운로드 실패했으니, 재시도 해주세요. 재 실패시 관리자에게 문의바랍니다."])},
  "2074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg, jpeg, png 파일 만 등록해 주세요 (1MB이하)"])},
  "2075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 로그인하지 않은 사용자가 호스트로 지정 되었습니다. 모니터링 제어 화면에서 호스트를 변경해 주세요."])},
  "2076": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 파일은 업로드 할 수 없습니다."])},
  "2077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avi"])},
  "2078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수행카운트 입력값은 1회성으로 저장되며, +, -, 0의 정수를 입력할 수 있습니다."])}
}