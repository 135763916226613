const menuStore = {
    namespaced: true,
    state: {
        menuId: null,
        menuNm: '',
        menuNmPath: [],
        randomId: null,
        listPath: '',
    },
    getters: {
        GET_MENU_NM: state => state.menuNm,
        GET_MENU_NM_PATH: state => state.menuNmPath,
        GET_MENU_LIST_PATH: state => state.listPath,
    },
    mutations: {
        SET_MENU_NM: (state, menuNm) => {
            state.menuNm = menuNm;
        },
        SET_MENU_NM_PATH: (state, array) => {
            state.menuNmPath = array;
        },
        SET_MENU_ID: (state, menuId) => {
            state.menuId = menuId;
            state.randomId = Math.random().toString(36).substr(2, 16);
        },
        SET_MENU_LIST_PATH: (state, path) => {
            state.listPath = path;
        },
    },
    actions: {
        SET_MENU_NM: ({ commit }, menuNm) => {
            commit('SET_MENU_NM', menuNm);
        },
        SET_MENU_NM_PATH: ({ commit }, array) => {
            commit('SET_MENU_NM_PATH', array);
        },
        SET_MENU_ID: ({ commit }, menuId) => {
            commit('SET_MENU_ID', menuId);
        },
        SET_MENU_LIST_PATH: ({ commit }, path) => {
            commit('SET_MENU_LIST_PATH', path);
        },
    },
};

export default menuStore;
