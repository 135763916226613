import { createI18n } from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    // json 파일을 읽어들이기 위한 function
    const requireLang = require.context('@/locales', true, /\.json$/);
    const messages = {};
    // json file read
    for (const file of requireLang.keys()) {
        if (file === './index.js') continue; // index.js 파일 제외
        const path = file.replace(/(\.\/|\.json$)/g, '').split('/');
        path.reduce((o, s, i) => {
            if (o[s]) return o[s];
            o[s] = i + 1 === path.length ? requireLang(file).default : {};
            return o[s];
        }, messages);
    }
    return messages;
}

const browserLanguage = navigator.language.replace(/^(\w{2})-.+$/, '$1');

const i18n = createI18n({
    locale: localStorage.getItem('common.locale.lang') || browserLanguage,
    fallbackLocale: 'ko',
    silentTranslationWarn: true,
    globalInjection: true,
    messages: loadLocaleMessages(),
});

function getLocale() {
    return i18n.global.locale;
}

function changeLocale(locale) {
    i18n.global.locale = locale;
    localStorage.setItem('common.locale.lang', locale);
    document.querySelector('html').setAttribute('lang', locale);
}

export { i18n, changeLocale, getLocale };
