const searchHistoryStore = {
    namespaced: true,
    state: {
        history: {},
    },
    getters: {
        GET_HISTORY: state => state.history,
    },
    mutations: {
        SET_SEARCH_HISTORY(state, payload) {
            state.history[payload.namespace] = payload.history;
        },
        CLEAR_SEARCH_HISTORY(state, payload) {
            state.history = {};
        },
        DELETE_SEARCH_HISTORY(state, payload) {
            delete state.history[payload.namespace];
        },
    },
    actions: {
        SET_SEARCH_HISTORY({ commit }, payload) {
            commit('SET_SEARCH_HISTORY', payload);
        },
        CLEAR_SEARCH_HISTORY({ commit }) {
            commit('CLEAR_SEARCH_HISTORY');
        },
        DELETE_SEARCH_HISTORY({ commit }, payload) {
            commit('DELETE_SEARCH_HISTORY', payload);
        },
    },
};

export default searchHistoryStore;
