export default {
  "0001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "0002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "0003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "0005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter list"])},
  "0006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "0007": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["View by ", _interpolate(_list(0))])},
  "0008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "0009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "0010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "0012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant ID"])},
  "0013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration date"])},
  "0014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor ID"])},
  "0015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date edited"])},
  "0016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "0017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "0018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "0019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])},
  "0020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "0021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
  "0022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
  "0023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure status"])},
  "0024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area code"])},
  "0025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure duration"])},
  "0026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure range"])},
  "0027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure order"])},
  "0028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure date"])},
  "0029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
  "0030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual"])},
  "0031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner image"])},
  "0032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner title"])},
  "0033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])},
  "0034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language code"])},
  "0035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure position"])},
  "0036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "0037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "0038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached file"])},
  "0040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find file"])},
  "0041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
  "0042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional multilingual data"])},
  "0043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data recently changed"])},
  "0044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required fields"])},
  "0045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
  "0046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
  "0047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled status"])},
  "0048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "0049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "0050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
  "0051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "0052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "0053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "0054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
  "0055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star"])},
  "0056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "0057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End status"])},
  "0058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public status"])},
  "0059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation status"])},
  "0061": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Return to ", _interpolate(_named("view"))])},
  "0062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for content names, content URLs, or background URLs"])},
  "0063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "0064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content name"])},
  "0065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content URL"])},
  "0066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background URL"])},
  "0067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only MP4 files"])},
  "0068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360-degree background video"])},
  "0069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content data"])},
  "0070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Volumetric Content"])},
  "0071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Volumetric Content"])},
  "0072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Volumetric Content"])},
  "0073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO room data"])},
  "0074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square titles and hosts"])},
  "0075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
  "0076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation type"])},
  "0077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play status"])},
  "0078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square names"])},
  "0079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if square"])},
  "0080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square"])},
  "0082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square ID"])},
  "0083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square creation type"])},
  "0084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select if square"])},
  "0085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for if squares."])},
  "0086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel & Room Data"])},
  "0087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for guide titles"])},
  "0088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide title"])},
  "0089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide subtitle"])},
  "0090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit guest guide"])},
  "0091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register guest guide"])},
  "0092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest guide data"])},
  "0093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage guest guide"])},
  "0094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for tooltip details"])},
  "0095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tooltip"])},
  "0096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register tooltip"])},
  "0097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltip data"])},
  "0098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage tooltips"])},
  "0099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for banned words"])},
  "0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned words"])},
  "0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all banned words in Excel"])},
  "0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download banned words in Excel"])},
  "0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the form by downloading the file, making edits, and uploading it"])},
  "0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch register all banned words"])},
  "0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned words data"])},
  "0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch register banned words"])},
  "0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage banned words"])},
  "0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for ad titles, ad copies, or ad URLs"])},
  "0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad title"])},
  "0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad copy"])},
  "0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad URL"])},
  "0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage marketing URL"])},
  "0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View TIMETABLE"])},
  "0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner position"])},
  "0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background colors"])},
  "0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banners listed as Exposure Order LEVEL1 '-1' are shown while automatically rolling on the screen at a certain interval, in the order of Exposure Order LEVEL 2."])},
  "0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner type"])},
  "0119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner button URL"])},
  "0120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])},
  "0121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable if square banner"])},
  "0122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit list banner"])},
  "0123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner data"])},
  "0124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner Timetable"])},
  "0125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register list banner"])},
  "0126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage list banner"])},
  "0127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner type"])},
  "0128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button name"])},
  "0129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button URL"])},
  "0130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstitial pop-up list"])},
  "0131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstitial pop-up data"])},
  "0132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button data"])},
  "0133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register interstitial pop-up banner"])},
  "0134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage interstitial pop-up banner"])},
  "0135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for banner titles"])},
  "0136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Management"])},
  "0137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
  "0138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "0140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My page"])},
  "0141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if square theme"])},
  "0142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for theme names"])},
  "0143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure area"])},
  "0144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Manages the mapping data of if square theme data and TRO theme object data. If the resource type and the resource value are blank, their data will be deleted."])},
  "0145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "0146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme name"])},
  "0147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping data"])},
  "0148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme object name"])},
  "0149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
  "0150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter resource type"])},
  "0151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource value"])},
  "0152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register if square theme"])},
  "0153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit if square theme"])},
  "0154": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter no more than ", _interpolate(_named("num")), " characters"])},
  "0155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square theme type"])},
  "0156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit interstitial pop-up"])},
  "0157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only JPG, JPEG, or PNG files"])},
  "0158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square theme name"])},
  "0159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter if square theme name"])},
  "0160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a member"])},
  "0161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for members"])},
  "0162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for members"])},
  "0163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "0164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "0165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
  "0166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS type"])},
  "0167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctioned"])},
  "0168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS ID"])},
  "0169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select if square theme type"])},
  "0170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please include \"https://\""])},
  "0171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language code"])},
  "0172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select if square ID"])},
  "0173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner"])},
  "0174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register marketing URL"])},
  "0175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit marketing URL"])},
  "0176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing URL data"])},
  "0177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch register banned words"])},
  "0178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register banned words"])},
  "0179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit banned words"])},
  "0180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "0181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "0182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square theme data"])},
  "0183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expose"])},
  "0184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceal"])},
  "0185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage admin"])},
  "0186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for ID, names, or emails"])},
  "0187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
  "0188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage area"])},
  "0189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account status"])},
  "0190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "0191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock account"])},
  "0192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "0193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for nicknames, member numbers, or details"])},
  "0194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])},
  "0195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Talk Post"])},
  "0196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register period"])},
  "0197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
  "0198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported"])},
  "0199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member number"])},
  "0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of likes"])},
  "0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of reports"])},
  "0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk Post data"])},
  "0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post NO"])},
  "0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk Post details"])},
  "0205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk Reply data"])},
  "0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk Reply list"])},
  "0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Talk Replies"])},
  "0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage affiliates"])},
  "0209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for affiliate name and dynamic link"])},
  "0210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate\ncategory"])},
  "0211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate category"])},
  "0212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate name"])},
  "0213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo image"])},
  "0214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image"])},
  "0215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OG image"])},
  "0216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic links"])},
  "0217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to TRO room"])},
  "0218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate data"])},
  "0219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate tag"])},
  "0220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins on top"])},
  "0221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
  "0222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created App room status"])},
  "0223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph settings\n(Deep Link settings)"])},
  "0224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register affiliate"])},
  "0225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit affiliate"])},
  "0226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background image"])},
  "0227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate room image"])},
  "0228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter affiliate tag"])},
  "0229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pins on top"])},
  "0230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter affiliate name"])},
  "0231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent login date"])},
  "0232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "0233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage system"])},
  "0234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin data"])},
  "0235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select affiliate category"])},
  "0236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter number of pins on top"])},
  "0237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "0238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual Settings"])},
  "0239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "0240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title"])},
  "0241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "0242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a description"])},
  "0243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square ID, room titles, room names, or PID values in TRA"])},
  "0244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room title"])},
  "0245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room name"])},
  "0246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID value in TRA"])},
  "0247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply affiliate"])},
  "0248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter for search terms"])},
  "0249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member number"])},
  "0250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start nickname"])},
  "0251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include nickname"])},
  "0252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage affiliate category"])},
  "0253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for affiliate and category name"])},
  "0254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register affiliate category"])},
  "0255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit affiliate category"])},
  "0256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate category names"])},
  "0257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter affiliate category"])},
  "0258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate category data"])},
  "0259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star if square data"])},
  "0260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if square"])},
  "0261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "0262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star if square"])},
  "0263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square number"])},
  "0264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square title"])},
  "0265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage admin approvals"])},
  "0266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "0267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request approval date"])},
  "0268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke approval"])},
  "0269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage task history"])},
  "0270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu category"])},
  "0271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "0272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task period"])},
  "0273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task type"])},
  "0274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "0275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed"])},
  "0276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for task details, Editor ID, or details"])},
  "0277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task details"])},
  "0278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task date"])},
  "0279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor ID"])},
  "0280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage service area"])},
  "0281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for area names and contact emails"])},
  "0282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area name"])},
  "0283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email"])},
  "0284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service area data"])},
  "0285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register service area"])},
  "0286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit service area"])},
  "0287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage access"])},
  "0288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access names"])},
  "0289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access data"])},
  "0290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu access settings"])},
  "0291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced access"])},
  "0292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu names"])},
  "0293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "0294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "0295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage menu"])},
  "0296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you click on the menu names, you can check the menu details"])},
  "0297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New registration"])},
  "0298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register access"])},
  "0299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit access"])},
  "0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu data"])},
  "0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu icon"])},
  "0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu route"])},
  "0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu URL"])},
  "0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu ID"])},
  "0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu exposure order"])},
  "0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive to mydesk"])},
  "0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])},
  "0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
  "0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Star if square"])},
  "0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Star if square"])},
  "0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative image"])},
  "0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter notice"])},
  "0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Star if square"])},
  "0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if squares"])},
  "0407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hr"])},
  "0408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
  "0409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host member"])},
  "0410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial host"])},
  "0411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO TIMER status"])},
  "0412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global if square"])},
  "0413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "0414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Star if square"])},
  "0415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square NO, if square ID, or if square titles"])},
  "0416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "0417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if square status"])},
  "0418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage recommended if square"])},
  "0419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add recommended if square"])},
  "0420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square NO and titles"])},
  "0421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage affiliate if square"])},
  "0422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for affiliate names, if square NO, or if square ID"])},
  "0423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Recommendation"])},
  "0424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate number"])},
  "0425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate if square data"])},
  "0426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate"])},
  "0427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate if square"])},
  "0428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register affiliate if square"])},
  "0429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit affiliate if square"])},
  "0430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select affiliate if square"])},
  "0431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for if squares"])},
  "0432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select affiliate"])},
  "0433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select created if square type"])},
  "0434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage special if square"])},
  "0435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Star if square NO, if square NO, if square ID, or if square title"])},
  "0436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star if square NO"])},
  "0437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square details"])},
  "0438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service area"])},
  "0439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select service language"])},
  "0440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
  "0441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star (★) exposure on screen"])},
  "0442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speakers and scheduled users"])},
  "0443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speak"])},
  "0444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar Exposure"])},
  "0445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled users"])},
  "0446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme type"])},
  "0447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of audience"])},
  "0448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming URL"])},
  "0449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach image"])},
  "0450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled server"])},
  "0451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift settings"])},
  "0452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift mode"])},
  "0453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift alert"])},
  "0454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift full alert"])},
  "0455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift animation"])},
  "0456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift screen alert"])},
  "0457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift sound"])},
  "0458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph settings (Deep Link settings)"])},
  "0459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selective rendering status"])},
  "0460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative tags"])},
  "0461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "0462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change presentation"])},
  "0463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End if square"])},
  "0464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
  "0465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "0466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find PDF"])},
  "0467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the presentation URL"])},
  "0468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find image file"])},
  "0469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "0470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App exposure order"])},
  "0471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to if square management"])},
  "0472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register if square"])},
  "0473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't select if squares created on the App or with the usage status N"])},
  "0474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square participation status"])},
  "0475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the order of exposure"])},
  "0476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the speakers and scheduled users"])},
  "0477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for speakers and scheduled users"])},
  "0478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to proceed"])},
  "0479": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["STEP.", _interpolate(_named("num"))])},
  "0480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on speaker and scheduled user select button"])},
  "0481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register user details"])},
  "0482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select speaker and scheduled user (Results)"])},
  "0483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select by searching for speakers and scheduled users"])},
  "0484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter title"])},
  "0485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter details"])},
  "0486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input manually"])},
  "0487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach file (PDF or IMAGE)"])},
  "0488": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Register ", _interpolate(_named("str"))])},
  "0489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing image"])},
  "0490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New image"])},
  "0491": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can register up to ", _interpolate(_list(0)), " representative tags"])},
  "0492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select host"])},
  "0493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for hosts"])},
  "0494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Up to 5 speakers and up to 20 scheduled users can be registered"])},
  "0495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select affiliate"])},
  "0496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add speaker"])},
  "0497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add scheduled user"])},
  "0498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit if square"])},
  "0499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for hosts"])},
  "0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for if square titles and hosts"])},
  "0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star (★) status"])},
  "0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App link"])},
  "0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
  "0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "0506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square statistics"])},
  "0507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square monitoring and control"])},
  "0508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic data"])},
  "0509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used language code"])},
  "0510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square end status"])},
  "0511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force shutdown"])},
  "0512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Telp room status"])},
  "0513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Telp room"])},
  "0514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of current users"])},
  "0515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participating members"])},
  "0516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation status"])},
  "0517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone settings"])},
  "0518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn all microphones OFF"])},
  "0519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn all microphones ON"])},
  "0520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute all except host settings"])},
  "0521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register if square real-time notice"])},
  "0522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice details"])},
  "0523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unregistered"])},
  "0524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "0525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Policy Violations (Pop-up)"])},
  "0526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host shutdown (Toast)"])},
  "0527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational shutdown (Toast)"])},
  "0528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFRESH"])},
  "0529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Unique User"])},
  "0530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Stay Time"])},
  "0531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max concurrent users"])},
  "0532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of host changes"])},
  "0533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of reports"])},
  "0534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage and enter/exit history"])},
  "0537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand graph"])},
  "0538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIME"])},
  "0539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "0540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary image"])},
  "0541": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("field"))])},
  "0542": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("field"))])},
  "0543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin sign up"])},
  "0545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "0546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check password"])},
  "0547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password doesn't match"])},
  "0548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "0549": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select ", _interpolate(_named("field"))])},
  "0550": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select ", _interpolate(_named("field"))])},
  "0551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9 to 20 characters, including at least one lowercase alphabetic letter, number, and special character"])},
  "0552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your admin application is complete. You can use the requested account with the Super Admin's approval."])},
  "0553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ID is already registered"])},
  "0554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "0555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID and password"])},
  "0556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID and password are invalid"])},
  "0557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account has been locked, because your password has been entered incorrectly more than 5 times. Please contact the admin."])},
  "0558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-up"])},
  "0559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save ID"])},
  "0560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to log in, log in via FIDO2 after entering your ID"])},
  "0561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't have an ID, create a new account by signing up"])},
  "0562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you haven't registered for FIDO2, please register in FIDO2"])},
  "0563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland global admin"])},
  "0564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation And Maintenance"])},
  "0565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My page"])},
  "0566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data"])},
  "0567": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Change ", _interpolate(_named("field"))])},
  "0568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed email"])},
  "0569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check changed email"])},
  "0570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It doesn't match the changed email"])},
  "0571": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please check ", _interpolate(_named("field"))])},
  "0572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email and password"])},
  "0573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your current password to change your email"])},
  "0574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
  "0575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed password"])},
  "0576": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check ", _interpolate(_named("field"))])},
  "0577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 9 characters, including alphabetic letters, numbers, and special characters"])},
  "0578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the same password as the changed password"])},
  "0579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check changed password"])},
  "0580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't match the changed password"])},
  "0582": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can only add up to ", _interpolate(_named("num")), " languages for the multi-language feature"])},
  "0583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the content name"])},
  "0584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Video Length"])},
  "0585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner name"])},
  "0586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please include \"https://\" or \"http://\""])},
  "0587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter"])},
  "0588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "0589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for access names"])},
  "0590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date can't be later than the end date"])},
  "0591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit host"])},
  "0592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the files"])},
  "0593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button multilingual language code"])},
  "0594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual ad banner button name"])},
  "0595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an overlapping language code"])},
  "0596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
  "0597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select role"])},
  "0598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select exposure location"])},
  "0599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please include \"jumpvr://\""])},
  "0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button name"])},
  "0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been changed"])},
  "0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button\nif square NO"])},
  "0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no interstitial pop-up banners to expose"])},
  "0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARGB Value"])},
  "0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language code"])},
  "0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work History detail parameter"])},
  "0611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure order LEVEL 1"])},
  "0612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure order LEVEL 2"])},
  "0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach multilingual files"])},
  "0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button name"])},
  "0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button URL"])},
  "0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button URL must start with \"jumpvr://\""])},
  "0704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button URL must start with \"https://\" or \"http://\""])},
  "0705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad banner button if square NO"])},
  "0706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlapping language code exists in the banner"])},
  "0707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with if square NO Validation in the Ad banner button"])},
  "0708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Language"])},
  "0709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been saved"])},
  "0710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been edited"])},
  "0711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload image file formats"])},
  "0712": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The maximum size of a file is ", _interpolate(_list(0))])},
  "0713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date cannot be earlier than the start date"])},
  "0714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select exposure location"])},
  "0715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner button URL"])},
  "0716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner button URL must start with \"jumpvr://\""])},
  "0717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List URL must start with \"https://\" or \"http://\""])},
  "0718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner button if square NO"])},
  "0719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter list banner button if square NO"])},
  "0720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid access"])},
  "0721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been deleted"])},
  "0722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Ad Title"])},
  "0723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad URLs must start with \"https://\" or \"http://\""])},
  "0724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual ad copy"])},
  "0725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data to enable"])},
  "0726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload an XLSX file format"])},
  "0727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a file"])},
  "0728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This banned word is already registered"])},
  "0729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Details"])},
  "0730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language code is already registered"])},
  "0731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a tooltip that is scheduled to be exposed"])},
  "0732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual guide title"])},
  "0733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual guide subtitle"])},
  "0734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual guide button name"])},
  "0735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This area code is already registered"])},
  "0736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square has ended"])},
  "0737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
  "0738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Length"])},
  "0739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "0740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation"])},
  "0741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
  "0742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host member number"])},
  "0743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app link will be copied when the icon is clicked!"])},
  "0744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the streaming URL file"])},
  "0745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual guide details"])},
  "0746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual menu name"])},
  "0747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach a 360-degree background video"])},
  "0749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach content file"])},
  "0750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload an MP4 file format"])},
  "0751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an overlapping access name"])},
  "0752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area code"])},
  "0753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This area code is already registered"])},
  "0754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been approved"])},
  "0755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been reset"])},
  "0756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been removed"])},
  "0757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a duplicate ID"])},
  "0758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach a logo image"])},
  "0759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the background image"])},
  "0760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the image of the affiliate's room"])},
  "0761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach an image"])},
  "0762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Language Code"])},
  "0763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Affiliate Name"])},
  "0764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to change the exposure status to N or SOON,\nbecause this affiliate already has\nif square data registered"])},
  "0765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload a JPG or PNG file format"])},
  "0766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 1 or more if square(s)"])},
  "0767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been set as a recommended if square"])},
  "0768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "0769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete, because affiliate data is registered in this affiliate category."])},
  "0770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual affiliate category"])},
  "0771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been hidden"])},
  "0772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been exposed"])},
  "0773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exposure start time can't be later than the end time"])},
  "0774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a representative image"])},
  "0775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a text string that can't be used in notices"])},
  "0776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The area code has to be the same as the one for the if square, or the selected if square must be global"])},
  "0777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload PDF files"])},
  "0778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload only a JPG, JPEG or PNG file"])},
  "0779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been processed successfully"])},
  "0780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The real-time notice has been sent"])},
  "0781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only add one of the above lounges"])},
  "0783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service language"])},
  "0784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special status can be applied only if the Star exposure is Y"])},
  "0785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the correct exposure start time"])},
  "0786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the correct exposure end time"])},
  "0787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a representative image for the Star (★) screen"])},
  "0788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unusable or unacceptable string is included in the Star (★) screen notice"])},
  "0789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
  "0790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open start time"])},
  "0791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open end time"])},
  "0792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open start time cannot be later than the end time"])},
  "0793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please remove special characters from the PDF file name"])},
  "0794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach PDF, JPG, or PNG files"])},
  "0795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the new image"])},
  "0796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select affiliate"])},
  "0797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the correct open start time"])},
  "0798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the correct open end time"])},
  "0799": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can select up to ", _interpolate(_named("num"))])},
  "0800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or more speaker"])},
  "0801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or more reserved user"])},
  "0802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual if square theme name"])},
  "0803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 1 or more member(s)"])},
  "0804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data to enable"])},
  "0805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is unauthorized"])},
  "0806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This if square NO doesn't exist"])},
  "0807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This if square NO has ended"])},
  "0808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "]\nValid if square NO"])},
  "0809": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change ", _interpolate(_named("field")), "?"])},
  "0810": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change ", _interpolate(_named("field")), "?"])},
  "0811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to log out?"])},
  "0812": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete ", _interpolate(_named("field")), "?"])},
  "0813": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete ", _interpolate(_named("field")), "?"])},
  "0814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual additional data"])},
  "0815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponding ad banner button"])},
  "0816": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to save ", _interpolate(_named("field")), "?"])},
  "0817": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to save ", _interpolate(_named("field")), "?"])},
  "0818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstitial pop-up banner"])},
  "0819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit interstitial pop-up banner"])},
  "0820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing URL"])},
  "0821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips"])},
  "0822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Guide"])},
  "0823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "0824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
  "0825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Content"])},
  "0826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to hide the selected if square from Specials?"])},
  "0827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to expose the selected if square to Specials?"])},
  "0828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended if squares selected"])},
  "0829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to designate the selected if square as the recommended if square?"])},
  "0830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only JPG, JPEG, or PNG files (Under 20 MB)"])},
  "0831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited with Open Graph settings data. If you save, the existing deep link will be unusable, and you will have to use the new deep link created. Do you want to save the if square data?"])},
  "0832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square Theme"])},
  "0833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to end the if square?"])},
  "0834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All selected if squares will be changed. Do you want to proceed?"])},
  "0835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to edit Open Graph settings. Do you want to proceed with these settings?"])},
  "0836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to enable this if square?"])},
  "0837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send the real-time notice to the if square?"])},
  "0838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to turn every user's microphone OFF?"])},
  "0839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to force close this if square?"])},
  "0840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create this if square's Telp room?"])},
  "0841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to turn ON (or OFF) Mute All Except Host?"])},
  "0842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the menu? Any sub-menus included will also be deleted."])},
  "0843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Caution] Do you want to reset this admin's password to \"0000\"? You will need to notify this admin of the password reset."])},
  "0844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to approve this account as an admin?"])},
  "0845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to unlock this account?"])},
  "0846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponding admin"])},
  "0847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to register a new admin?"])},
  "0848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "0849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to approve the admin's request?"])},
  "0850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to cancel the approval request? This record will be deleted."])},
  "0851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin requested data"])},
  "0852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete, because an if square's data is registered in this affiliate"])},
  "0853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "0854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "0855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create room"])},
  "0856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My activity"])},
  "0857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "0858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
  "0859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to an external link"])},
  "0860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaviors within internal app"])},
  "0861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square start notification"])},
  "0862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect if square participation"])},
  "0863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, JPEG, or PNG files only"])},
  "0864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the file size"])},
  "0865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual button name"])},
  "0866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List banner button if square button"])},
  "0867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter if square NO in the list banner button"])},
  "0868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-up request date"])},
  "0869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceeded the number of registered access"])},
  "0870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select theme"])},
  "0871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only upload PDF files / JPG, JPEG, or PNG files"])},
  "0872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland invitation"])},
  "0873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Metaverse World where you and I meet"])},
  "0874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square created on the App"])},
  "0875": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The admin website you are currently accessing is ", _interpolate(_named("env"))])},
  "0876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "0877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star if square number"])},
  "0878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app-created if square can't be edited because it has ended"])},
  "0879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete all fields"])},
  "0880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It doesn't match the existing password"])},
  "0881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ID has been entered"])},
  "0882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is locked. Please contact the admin."])},
  "0883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room participation type"])},
  "0884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial host member number"])},
  "0885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceeded if square NO input range. The input range is [0 ~ 999999999]."])},
  "0886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 to 12 characters, starting with a lowercase alphabetic letter and including numbers"])},
  "0887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date of exposure"])},
  "0888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date of exposure"])},
  "0889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner NO"])},
  "0890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads NO"])},
  "0891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned Words Number"])},
  "0892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Guide NO"])},
  "0893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square NO"])},
  "0894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Data"])},
  "0895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square Theme NO"])},
  "0896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended if square NO"])},
  "0897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Category NO"])},
  "0898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Category"])},
  "0899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate NO"])},
  "0900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content NO"])},
  "0901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin NO"])},
  "0902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Name"])},
  "0903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval NO"])},
  "0904": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access NO"])},
  "0905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work History NO"])},
  "0906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
  "0907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to edit exposure to N because affiliate data is registered in this affiliate category"])},
  "0908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of a recommended if square, speaker value is required"])},
  "0912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])},
  "0913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change presenter"])},
  "0914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve microphone"])},
  "0915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "0916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation status"])},
  "0917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove entry restrictions"])},
  "0918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide Microphone"])},
  "0919": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Host"])},
  "0920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Gift Settings"])},
  "0921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Host"])},
  "0922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenter Data"])},
  "0923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No presenter (anyone can control)"])},
  "0924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation Status"])},
  "0925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change presentation"])},
  "0926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar participation\nrestrictions"])},
  "0927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio participation\nrestrictions"])},
  "0928": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice Type"])},
  "0929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Able to enter"])},
  "0930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Restrictions"])},
  "0931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Notice"])},
  "0932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server (World) Notice"])},
  "0933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4D Video Status"])},
  "0934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "0935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the participant status?"])},
  "0936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if me"])},
  "0937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "0938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to let the dropped member in again?"])},
  "0939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropped members won't be able to join the same Meet-Up room again. Do you want to proceed?"])},
  "0940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the presenter?"])},
  "0941": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Member Number: Do you want to retrieve ", _interpolate(_list(0)), "'s microphone?"])},
  "0942": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Member Number: Do you want to provide ", _interpolate(_list(0)), "'s microphone?"])},
  "0943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search failed"])},
  "0944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video unregistered"])},
  "0945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been changed"])},
  "0946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the gift settings?"])},
  "0947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gift settings have been changed"])},
  "0948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the presentation?"])},
  "0949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the host?"])},
  "0950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Room"])},
  "0953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a reason for the forced shutdown"])},
  "0959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Video"])},
  "0965": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "0966": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to remove ", _interpolate(_named("field")), "?"])},
  "0967": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to remove ", _interpolate(_named("field")), "?"])},
  "0968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select Volumetric Content"])},
  "0969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Volumetric Content"])},
  "0970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure Order LEVEL 1"])},
  "0971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure Order LEVEL 2"])},
  "0972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter -1, 0, 1, or a positive number greater than 1"])},
  "0973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been removed"])},
  "0974": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please check ", _interpolate(_named("field"))])},
  "0975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button name"])},
  "0976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceal"])},
  "0977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview the list banner"])},
  "0978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a reason for the forced shutdown."])},
  "0979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no if squares to change orders for"])},
  "0980": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can only upload files under ", _interpolate(_list(0))])},
  "0981": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can only upload ", _interpolate(_list(0)), " files"])},
  "0982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate category name"])},
  "0983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no list banners to expose"])},
  "0984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a PDF file to change"])},
  "0985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the presentation URL"])},
  "0986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload an image file to change"])},
  "0987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned words NO"])},
  "0988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no interstitial pop-up banners to change orders for"])},
  "0989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no list banners to change orders for"])},
  "0990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Star if squares to expose"])},
  "0991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name of the affiliate category"])},
  "0992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual affiliate category name"])},
  "0993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website you are currently visiting is"])},
  "0994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifland Admin System"])},
  "0995": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "0996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to activate Auto Refresh?\nActivating Auto Refresh will refresh the page every 10 seconds."])},
  "0997": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to deactivate Auto Refresh?"])},
  "0998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Key"])},
  "0999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Server Number"])},
  "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the list banner button if square NO and select Validation"])},
  "1001": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to delete ", _interpolate(_list(0)), "?"])},
  "1002": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to delete ", _interpolate(_list(0)), "?"])},
  "1003": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Enter ", _interpolate(_list(0))])},
  "1004": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Enter ", _interpolate(_list(0))])},
  "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register presentation data"])},
  "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed members cannot re-enter the same if square. Do you want to proceed?"])},
  "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Space"])},
  "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+button"])},
  "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh setting"])},
  "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can register up to 2 items of ad banner button data."])},
  "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The presenter has changed."])},
  "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker value must be entered for a recommended if square."])},
  "1013": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can only upload ", _interpolate(_list(1)), " files under ", _interpolate(_list(0))])},
  "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no if square themes to change orders for"])},
  "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no affiliate categories to change orders for"])},
  "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no affiliates to change orders for"])},
  "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Star if squares to change orders for"])},
  "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no special if squares to change orders for"])},
  "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to connect to the TRO Room if you change the setting to SHOW(Y)"])},
  "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one affiliate must be entered."])},
  "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geofence area code"])},
  "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest access setting"])},
  "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas"])},
  "1025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage banners"])},
  "1026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage member reports"])},
  "1027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report status"])},
  "1028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report types"])},
  "1029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial report"])},
  "1030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin receipt"])},
  "1031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report rejected"])},
  "1032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing complete"])},
  "1033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obscenities, harmful to minors"])},
  "1034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profanity, slander, discrimination, hatred"])},
  "1035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposing and trading of personal information"])},
  "1036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flooding, spamming"])},
  "1037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "1038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process result\n(restriction type)"])},
  "1039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report history"])},
  "1040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "1041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "1042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "1043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove restrictions"])},
  "1044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report order"])},
  "1045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS User ID"])},
  "1046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change report settings"])},
  "1047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for reporting"])},
  "1048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member data"])},
  "1049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage lounge data"])},
  "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstitial pop-up banner data"])},
  "1051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure setting cannot be changed to Y after registering as an affiliate."])},
  "1053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ID or password is incorrect."])},
  "1054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can log in after your account is approved by an admin."])},
  "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code is incorrect."])},
  "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authentication code is not valid."])},
  "1057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no admin registered by the ID you have entered. Please check the ID."])},
  "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There has been an error in processing the data.\nPlease contact the admin."])},
  "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes made to the presentation data on the upper lounge will apply to all lower lounges.\nDo you want to make this change?"])},
  "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting a real-time if square notice on the upper lounge will send it to all lower lounges.\nDo you want to send the real-time notice to the lounge if square?"])},
  "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change the exposure to Y after connecting to the TRO Room"])},
  "1063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A URL that begins with jumpvr://"])},
  "1064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
  "1065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select up to 2 service languages."])},
  "1066": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please select ", _interpolate(_list(0))])},
  "1067": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please select ", _interpolate(_list(0))])},
  "1068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room ID"])},
  "1069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select 1 or more areas to manage"])},
  "1070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for FIDO2"])},
  "1071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO2 log in"])},
  "1072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up as an Admin"])},
  "1073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the registration form to sign up."])},
  "1074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9 to 20 characters, including at least one lowercase alphabetic letter, number, and special character"])},
  "1075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a code"])},
  "1076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Code"])},
  "1077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Confirmation"])},
  "1078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration will be complete after the final approval is given by the Super Admin."])},
  "1079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "1080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can register for FIDO2, after you sign up as a new member and obtain approval for an admin account."])},
  "1081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not have an ID, please create a new account."])},
  "1082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email authentication was successful."])},
  "1083": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your authentication code has been sent to the email address ", _interpolate(_list(0)), ", which you entered when registering as an admin member."])},
  "1084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the authentication code confirmation."])},
  "1085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully registered for FIDO2."])},
  "1086": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["FIDO2 registration was unsuccessful. (", _interpolate(_list(0)), ")"])},
  "1087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your device environment does not support FIDO2."])},
  "1088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIDO authentication is not complete."])},
  "1089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your FIDO2 registration and try it again."])},
  "1090": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please check your FIDO2 registration and try it again. (", _interpolate(_list(0)), ")"])},
  "1091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 to 12 characters, starting with a lowercase alphabetic letter and including numbers"])},
  "1092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not match"])},
  "1093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password you entered does not match the confirmed password"])},
  "1094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password matched"])},
  "1095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your e-mail address"])},
  "1096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a room ID"])},
  "1097": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The authentication code has been sent to the email address you have entered: ", _interpolate(_list(0))])},
  "1098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the code confirmation"])},
  "1099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access is already selected"])},
  "1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of access that can be granted for an account is 20"])},
  "1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select different names for the access"])},
  "1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Name"])},
  "1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Code"])},
  "1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader"])},
  "1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Registration"])},
  "1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Description"])},
  "1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a leader through Admin Search."])},
  "1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a member through Admin Search."])},
  "1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaders and members"])},
  "1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Search"])},
  "1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Information"])},
  "1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group NO"])},
  "1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of members"])},
  "1115": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to save ", _interpolate(_list(0)), "?"])},
  "1116": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to save ", _interpolate(_list(0)), "?"])},
  "1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
  "1118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User Group"])},
  "1119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary task completion status"])},
  "1120": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to delete ", _interpolate(_list(0)), "?"])},
  "1121": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to delete ", _interpolate(_list(0)), "?"])},
  "1122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Key"])},
  "1123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the User Group Key"])},
  "1124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select user group"])},
  "1125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Registration"])},
  "1126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Information"])},
  "1127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource ID"])},
  "1128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Name"])},
  "1129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no resource information registered"])},
  "1130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task History ID"])},
  "1131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Task History ID"])},
  "1132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
  "1133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the maximum number of registered user groups"])},
  "1134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique User Groups"])},
  "1135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Code"])},
  "1136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Description"])},
  "1137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose menu access and menu resource access"])},
  "1138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncheck all"])},
  "1139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have access"])},
  "1140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Group"])},
  "1141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm the password to change the main group"])},
  "1142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the main group"])},
  "1143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure Order"])},
  "1144": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can register up to ", _interpolate(_list(0)), " types of accesses"])},
  "1145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Groups Found"])},
  "1146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Accesses"])},
  "1147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not edit the user group when you are the leader of the user group."])},
  "1148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change the user group after changing the user group leader from the Manage User Groups menu."])},
  "1149": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We are unable to delete this admin, as this admin is the leader of ", _interpolate(_list(0)), ". Please proceed after changing the group leader from the Manage User Groups menu."])},
  "1150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data"])},
  "1151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "1152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor IP"])},
  "1153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "1154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter below information to proceed with the admin approval."])},
  "1155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request admin data"])},
  "1156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "1157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "1158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter 9 to 20 characters including English letters, numbers, and special characters."])},
  "1159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request password change"])},
  "1160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not changed your password for the last 6 months."])},
  "1161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An identical character has been entered more than 4 times.\nPlease re-enter the password."])},
  "1162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 4 consecutive characters have been entered.\nPlease re-enter the password."])},
  "1163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot include your ID in your password."])},
  "1164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is identical to the previous one.\nPlease enter a new password."])},
  "1165": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please enter more than ", _interpolate(_list(0)), " characters to search."])},
  "1166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please change your password to protect the Admin account."])},
  "1167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won't be able to access the ifland Admin System if you don't change your password."])},
  "1168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have included invalid special characters"])},
  "1169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone is if me usage status"])},
  "1170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the following fields to use the renewed ifland Admin System"])},
  "1171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may log in after obtaining approval from the Super Admin"])},
  "1172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit history"])},
  "1173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items edited"])},
  "1174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before the edit"])},
  "1175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the edit"])},
  "1176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor IP"])},
  "1177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit history images"])},
  "1178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Editor ID"])},
  "1179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user group key is valid"])},
  "1180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user group key is invalid"])},
  "1181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request admin data registration"])},
  "1182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have entered the admin data. You may use ifland Admin System from this account with approval from the Super Admin."])},
  "1183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the user group key validation"])},
  "1184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-tested if square"])},
  "1185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-operated if square"])},
  "1186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-affiliated if square"])},
  "1187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-created if square"])},
  "1188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Play Items"])},
  "1189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Karaoke"])},
  "1190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent songs"])},
  "1191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular songs"])},
  "1192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song number"])},
  "1193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song title"])},
  "1194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist"])},
  "1195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP"])},
  "1196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All songs"])},
  "1197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country info"])},
  "1198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song settings"])},
  "1199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings for Recent Songs"])},
  "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs to add to the Recent Songs list"])},
  "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs to remove from the Recent Songs list"])},
  "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added songs list"])},
  "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select by searching for songs"])},
  "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genres"])},
  "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search from Songs"])},
  "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings for Popular Songs"])},
  "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed songs list"])},
  "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs to add to the Popular Songs list"])},
  "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs to remove from the Popular Songs list"])},
  "1210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song type"])},
  "1211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singer"])},
  "1212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standards"])},
  "1213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The song list will automatically be updated at 00:00:00"])},
  "1214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage short-form themes"])},
  "1215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form theme types"])},
  "1216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form theme NO"])},
  "1217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbnail"])},
  "1218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form theme names"])},
  "1219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a short-form theme"])},
  "1220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs"])},
  "1221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter one of the following: song or singer"])},
  "1222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by date"])},
  "1223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by popularity"])},
  "1224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents order"])},
  "1225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit short-form theme"])},
  "1226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search iflander"])},
  "1227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select by using iflander search"])},
  "1228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "1229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter unique tags and press enter (max. 5)."])},
  "1230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for short-form theme name"])},
  "1231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form theme info"])},
  "1232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage play items"])},
  "1233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a play item"])},
  "1234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit play item"])},
  "1235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item info"])},
  "1236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item NO"])},
  "1237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item code"])},
  "1238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item name"])},
  "1239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranks assessment method"])},
  "1240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "1241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home ranks exposure"])},
  "1242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home ranks exposure order"])},
  "1243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to PD"])},
  "1244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change home ranks exposure order"])},
  "1245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRA PID"])},
  "1246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop ID"])},
  "1247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRA name"])},
  "1248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward points"])},
  "1249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards"])},
  "1250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
  "1251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage play components"])},
  "1252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a play component"])},
  "1253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit play component"])},
  "1254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play component info"])},
  "1255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play component NO"])},
  "1256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranks assessment status"])},
  "1257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play component name"])},
  "1258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
  "1259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play period"])},
  "1260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item rank"])},
  "1261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play record ID"])},
  "1262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play perspective"])},
  "1263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "1264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward date"])},
  "1265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After creating a room, any changes made to the Volumetric Content for the room will not be applied"])},
  "1266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents info"])},
  "1267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a play component name"])},
  "1268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP"])},
  "1269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop songs"])},
  "1270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J-POP"])},
  "1271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play item"])},
  "1272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play component"])},
  "1273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a ranks assessment method"])},
  "1274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascending order"])},
  "1275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descending order"])},
  "1276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "1277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "1278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "1279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have included a song that has already been set as a removed song"])},
  "1280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have included a song that has already been set as an added song"])},
  "1281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is another song with an identical exposure order"])},
  "1282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song"])},
  "1283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play component image"])},
  "1284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may register up to 10 rewards"])},
  "1285": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please enter a reward TOP ", _interpolate(_list(0))])},
  "1286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual play item name"])},
  "1287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual unit"])},
  "1288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This play item code is already registered"])},
  "1289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing phrase"])},
  "1290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposable date"])},
  "1291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "1292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for contents"])},
  "1293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for contents"])},
  "1294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form theme"])},
  "1295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This short-form theme will not be exposed to the user app without a marketing phrase"])},
  "1296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a whole number bigger than 0. Contents posted within the last N (entered number) day(s) will be exposed on the user app."])},
  "1297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singer+Song"])},
  "1298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song only"])},
  "1299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singer only"])},
  "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effects"])},
  "1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Booth"])},
  "1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A specific user"])},
  "1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All short-forms"])},
  "1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if recommendation"])},
  "1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home ranks order"])},
  "1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no play item to change orders for"])},
  "1307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a play component image"])},
  "1308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File NO"])},
  "1309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "1310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
  "1311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the maximum value"])},
  "1312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select after searching for a play component image"])},
  "1313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual play component name"])},
  "1314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter \"max\" from the Ranks Assessment Status"])},
  "1315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual short-form theme name"])},
  "1316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual description"])},
  "1317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents - iflander"])},
  "1318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents - Effects"])},
  "1319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual marketing phrase"])},
  "1320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign songs"])},
  "1321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screen"])},
  "1322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karaoke"])},
  "1323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select source"])},
  "1324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may register up to 10 effects"])},
  "1325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the effect from \"Contents\""])},
  "1326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an identical effect"])},
  "1327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a play component image"])},
  "1328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bluescreen video status"])},
  "1329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your authentication code has expired"])},
  "1330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been locked due to exceeding the number of tries.\nPlease contact the Admin."])},
  "1331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select more than one song after searching for the song."])},
  "1332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select more than one iflander."])},
  "1333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an iflander after searching for an iflander."])},
  "1334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select more than one song."])},
  "1335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Status"])},
  "1336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the failure"])},
  "1337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Process"])},
  "1338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to redistribute the reward?"])},
  "1339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reward has been distributed."])},
  "1340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The song list is updated every day at OO."])},
  "1341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward distribution was unsuccessful."])},
  "1342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PD ID"])},
  "1343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROP name"])},
  "1344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROP description"])},
  "1345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Short-Form Popular News"])},
  "1346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Hall of Fame"])},
  "1347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postring ID"])},
  "1348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the posting"])},
  "1349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time of registration"])},
  "1350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form to fix on the top"])},
  "1351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form"])},
  "1352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a short-form video"])},
  "1353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a selection by searching for a short-form."])},
  "1354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-Form Popular News"])},
  "1355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show details"])},
  "1356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for a short-form and select one."])},
  "1357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-Form Popular News Info"])},
  "1358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Short-Form Popular News"])},
  "1359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post a Short-Form Popular News"])},
  "1360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting Status"])},
  "1361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
  "1362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Confirmation"])},
  "1363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Result"])},
  "1364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Number"])},
  "1365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Results for Mature Audience Content"])},
  "1366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Results for Suggestive Content"])},
  "1367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Inspection"])},
  "1368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a short-form theme name"])},
  "1369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-form ID"])},
  "1370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-screened posts"])},
  "1371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed due to a violation of the policy"])},
  "1372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violation of the policy"])},
  "1373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw membership"])},
  "1374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete post"])},
  "1375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait"])},
  "1376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe"])},
  "1377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation complete"])},
  "1378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconfirmed"])},
  "1379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a name for the Short-Form Theme."])},
  "1380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire range"])},
  "1381": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Failed to edit the presentation material\n[", _interpolate(_list(0)), "]"])},
  "1382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect name"])},
  "1383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an effect"])},
  "1384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an effect"])},
  "1385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an effect name"])},
  "1386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The uploaded file is invalid"])},
  "1387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a selection by searching for an effect"])},
  "1388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select more than one effect"])},
  "1389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete, because a short-form theme data is registered in this effect"])},
  "1390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This effect is already registered."])},
  "1391": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "]\nThe banner is not saved due to the lack of the exposure order. Please go to banners and set up the exposure order"])},
  "1392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karaoke/Photo Booth"])},
  "1393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
  "1394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the play item name"])},
  "1395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task server"])},
  "1396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage unmasking"])},
  "1397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data is unmasked upon authentication after entering the password."])},
  "1398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication is complete"])},
  "1399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring of the acquired play item points"])},
  "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquired currency"])},
  "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition date"])},
  "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently authenticated for unmasking"])},
  "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication expiration time"])},
  "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend authentication time"])},
  "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the password"])},
  "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication expires in 30 minutes"])},
  "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the authentication expiration time below."])},
  "1408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users not participating in if me mode cannot be changed to the host."])},
  "1409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users not participating in if me mode cannot be changed to the presenter."])},
  "1410": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There has been a Telp error.\n(error code: ", _interpolate(_list(0)), "/ error caused by: ", _interpolate(_list(1)), ")"])},
  "1411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage affiliate if homes"])},
  "1412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "1413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "1414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show friends"])},
  "1415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
  "1416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate NO"])},
  "1417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space image"])},
  "1418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "1419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of today's visitors"])},
  "1420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of visitors"])},
  "1421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account(s)"])},
  "1422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize"])},
  "1423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate if home info"])},
  "1424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate if home details"])},
  "1425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of kinetic props"])},
  "1426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access status"])},
  "1427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the previous visit"])},
  "1428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home participants"])},
  "1429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save routes of the contents"])},
  "1430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the space image"])},
  "1431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this if home, as it is set as a Special if home."])},
  "1432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the background and home will initialize the if home. Are you sure you want to proceed?"])},
  "1433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring and controlling of affiliate if homes"])},
  "1434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the if home"])},
  "1435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open/Close"])},
  "1436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open/Close the if home"])},
  "1437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a reason for the forced closure"])},
  "1438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced closure"])},
  "1439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Selecting initialization will only retain the background and \bhome (everything will go back to how it was when the if home was first created)."])},
  "1440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to initialize?"])},
  "1441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current presentation data"])},
  "1442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Autoplay"])},
  "1443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame data"])},
  "1444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current frame data"])},
  "1445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the frame data"])},
  "1448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
  "1449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "1450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "1451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violation of the operations policy"])},
  "1452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed for operations"])},
  "1453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "1454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "1455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to close it to the public?"])},
  "1456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "1457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
  "1458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a mp4 file to change."])},
  "1459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if home affiliates"])},
  "1460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expose"])},
  "1461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover image"])},
  "1462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account mapping"])},
  "1463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop mapping"])},
  "1464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate info"])},
  "1465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register an if home affiliate"])},
  "1466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit if home affiliates"])},
  "1467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach a cover image."])},
  "1468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate account mapping"])},
  "1469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a home"])},
  "1470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".json file"])},
  "1471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["txt"])},
  "1472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])},
  "1473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping account"])},
  "1474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an account to select one."])},
  "1475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* You can not inquire for an already mapped account."])},
  "1476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an account"])},
  "1477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate prop mapping"])},
  "1478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP Refresh setting"])},
  "1479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping NO"])},
  "1480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop NO"])},
  "1481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop PD ID"])},
  "1482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of props"])},
  "1483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully refreshed."])},
  "1484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Prop mapping of if home affiliate"])},
  "1485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop setting json"])},
  "1486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping props"])},
  "1487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop"])},
  "1488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a prop"])},
  "1489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use play items"])},
  "1490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use if home props"])},
  "1491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop categories"])},
  "1492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid json format"])},
  "1493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Special if home"])},
  "1494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if home NO"])},
  "1495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the affiliate if home"])},
  "1496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home NO"])},
  "1497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Special if home"])},
  "1498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Special if homes to expose."])},
  "1499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if home to be fixed at the top"])},
  "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an if home"])},
  "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home"])},
  "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an affiliate if home to select one."])},
  "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to set selected the if home as a Special if home?"])},
  "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This if home has been set as a Special if home."])},
  "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if home details"])},
  "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix Special if home at the top"])},
  "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an affiliate if home"])},
  "1508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage ordinary if home"])},
  "1509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guestbook"])},
  "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of avatar participants"])},
  "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of voice participants"])},
  "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of ordinary if home"])},
  "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinary if home"])},
  "1514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit ordinary if home"])},
  "1515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show friends"])},
  "1516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the guestbook"])},
  "1517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show friends"])},
  "1518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if home banners"])},
  "1519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home banners"])},
  "1520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview if home banner list"])},
  "1521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no if home banners to change orders for."])},
  "1522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register an if home banner"])},
  "1523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of if home banners"])},
  "1524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of if home banner"])},
  "1525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home banner button URL"])},
  "1526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Today's if home"])},
  "1527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home provided will be fundamentally identical to Today's if home list provided by the APP (100 if homes, in the order of best score)."])},
  "1528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria for Today's if home: the if home with the most number of items. When the numbers of items are identical, it will be provided to the one that possesses more varieties of item categories."])},
  "1529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admin may select an if home as Today's if home through the if home registration, and this if home will be exposed at the top."])},
  "1530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's if home to be fixed at the top"])},
  "1531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an if home to select one."])},
  "1532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's if home"])},
  "1533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Today's if home"])},
  "1534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Today's if home"])},
  "1535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
  "1536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP Code"])},
  "1537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if home marketing URL"])},
  "1538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register if home marketing URL"])},
  "1539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home marketing URL data"])},
  "1540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit if home marketing URL"])},
  "1541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to open to the public?"])},
  "1542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will add this to the if home user's block list. Would you like to proceed?"])},
  "1543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick out"])},
  "1544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to kick out this user?"])},
  "1545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate if home"])},
  "1546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the affiliate if home"])},
  "1548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin blocked members"])},
  "1549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will add this to the if home user's block list.\nFollowers, followings, and news update will be deleted.\nWould you like to proceed?"])},
  "1550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatting allowed only with the account users\nON"])},
  "1551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatting allowed only with the account users\nOFF"])},
  "1552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home banner button if square NO"])},
  "1553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the if home banner button if square NO Validation."])},
  "1554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner button if home User ID"])},
  "1555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the banner button if home User ID Validation."])},
  "1556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate"])},
  "1557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for a prop to select one."])},
  "1558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinary if home info"])},
  "1559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate prop mapping edit"])},
  "1560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home marketing URL"])},
  "1561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home affiliate prop mapping data"])},
  "1562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an account to select one."])},
  "1563": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please register only ", _interpolate(_list(0)), " file (Under ", _interpolate(_list(1)), ")"])},
  "1564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a background"])},
  "1565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special if home"])},
  "1566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the validation, after entering the membership number."])},
  "1567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the membership number validation."])},
  "1568": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["It is [", _interpolate(_list(0)), "/", _interpolate(_list(1)), "]."])},
  "1569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This member does not exist."])},
  "1570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an if home to select."])},
  "1571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to designate the selected if home as Today's if home?"])},
  "1572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been selected as Today's if home."])},
  "1573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Today's if homes, to change the order."])},
  "1574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may be exposed in a different order, as it is provided randomly on the APP."])},
  "1575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix at the top"])},
  "1576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no if home banners to expose."])},
  "1578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to an if home member"])},
  "1579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home banner button URL must start with 'jumpvr://'."])},
  "1580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home banner buttons must start with 'https://' or 'http://'."])},
  "1581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
  "1582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to unblock?"])},
  "1583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring and controlling of ordinary if homes"])},
  "1584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order of the if homes fixed at the top"])},
  "1585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register to fix at the top"])},
  "1586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select more than one speaker and scheduled user."])},
  "1587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the speakers and scheduled users, after searching for the speakers and scheduled users."])},
  "1588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's if home selection"])},
  "1589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELOAD"])},
  "1590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "1591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected special if home"])},
  "1592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELOAD successful"])},
  "1593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a membership number or a nickname to enable if home search."])},
  "1594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max concurrent audience"])},
  "1595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max concurrent observers"])},
  "1596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "1597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid json file"])},
  "1598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Type"])},
  "1599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the reward type"])},
  "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUSH Title"])},
  "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUSH Template"])},
  "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Method for Each Item"])},
  "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Assessment Commencement Date - Month"])},
  "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Assessment Commencement Date - Day"])},
  "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Item Name"])},
  "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Amount"])},
  "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Type"])},
  "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stone"])},
  "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point"])},
  "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Sent"])},
  "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the Reward Delivery Failure"])},
  "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-reward"])},
  "1614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeliver"])},
  "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Notification"])},
  "1616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to resend the notification?"])},
  "1617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been resent."])},
  "1620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send the notification."])},
  "1621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Assessment Due Date — Month"])},
  "1622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Assessment Due Date — Day"])},
  "1623": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please verify ", _interpolate(_list(0)), "."])},
  "1624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This PUSH template is invalid. Please check the input method for each item."])},
  "1625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement to receive marketing information"])},
  "1626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The marketing information agreement banner will be the first to be exposed after checking the user agreement every 14 days, regardless of the exposure setting."])},
  "1627": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are already exposing a marketing information agreement banner. Clicking the confirm button will replace it with [", _interpolate(_list(0)), "]. Are you sure you want to save?"])},
  "1628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "1629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
  "1630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner Type"])},
  "1631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the banner type"])},
  "1632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Reward Text"])},
  "1633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Text"])},
  "1634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text No."])},
  "1635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Title"])},
  "1636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Details"])},
  "1637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "1638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Reward Text"])},
  "1639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Reward Text"])},
  "1640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Text Data"])},
  "1641": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are already exposing ", _interpolate(_list(0)), " during the exposure period."])},
  "1642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Churn Preventing Pop-up Text"])},
  "1646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Churn Preventing Pop-up Text Data"])},
  "1647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Churn Preventing Pop-up Text"])},
  "1648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Churn Preventing Pop-up Text"])},
  "1649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Churn Preventing Pop-up Text"])},
  "1650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Text"])},
  "1651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can register up to 2 items of pop-up button data."])},
  "1652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up Button Type"])},
  "1653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the pop-up button type"])},
  "1654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up Button Name"])},
  "1655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up Button URL"])},
  "1656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Text Details"])},
  "1657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Pop-up Button Name"])},
  "1658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up Button Multilingual Language Code"])},
  "1659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward distribution failure."])},
  "1660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home Banner Button Type"])},
  "1661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Banner Button Type"])},
  "1662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Banner Button Type"])},
  "1663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up Button Data"])},
  "1664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Start Loading"])},
  "1665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest square/lounge"])},
  "1666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log-in square/lounge"])},
  "1667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest if home"])},
  "1668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log-in if home"])},
  "1669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "1670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation json"])},
  "1671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure Type"])},
  "1672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My if home"])},
  "1673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others' if home first"])},
  "1674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square first"])},
  "1675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lounge first"])},
  "1676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others' if home second"])},
  "1677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square second"])},
  "1678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lounge second"])},
  "1679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pop-up button URL must begin with 'https://' or 'http://'"])},
  "1680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pop-up button URL must begin with 'jumpvr://'"])},
  "1681": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please upload a ", _interpolate(_list(0)), " file."])},
  "1682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner Type"])},
  "1683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the banner type"])},
  "1684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a churn preventing pop-up text being exposed during the exposure period."])},
  "1685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Link"])},
  "1686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Link Name"])},
  "1687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Link Type"])},
  "1688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Link URL"])},
  "1689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "1690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership Inquiry"])},
  "1691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips Landing"])},
  "1692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS Youtube"])},
  "1693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS Instagram"])},
  "1694": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You may upload files under the size of ", _interpolate(_list(0)), "px * ", _interpolate(_list(1)), "px."])},
  "1695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP screen"])},
  "1696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home"])},
  "1697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP screen item"])},
  "1698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "1699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no APP screen items to change the order for."])},
  "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no APP screen items to expose."])},
  "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounge"])},
  "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS ifland"])},
  "1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default language"])},
  "1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the default language and enter an external link URL."])},
  "1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a language code"])},
  "1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if home model house"])},
  "1707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home model house details"])},
  "1708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as an if home model house"])},
  "1709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit if home model house"])},
  "1710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home model house"])},
  "1711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an if home model house in use."])},
  "1712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete the if home model house in use. Please change the usage setting to N and then delete it."])},
  "1713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage service area language"])},
  "1714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service area language details"])},
  "1715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a service area language"])},
  "1716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Service Area Language"])},
  "1717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Area Language"])},
  "1718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied Menu"])},
  "1719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management title"])},
  "1720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select menu"])},
  "1721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage notice"])},
  "1722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice Details"])},
  "1723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a notice"])},
  "1724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit notice"])},
  "1725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
  "1726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice Title"])},
  "1727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Tag Settings"])},
  "1728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Tag Name"])},
  "1729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Code Settings"])},
  "1730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview details"])},
  "1731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum resolution"])},
  "1732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only jpeg, png files (under 5MB)"])},
  "1733": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] Please enter the notice title"])},
  "1734": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] Please enter the notice details"])},
  "1735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add one language code at the minimum"])},
  "1736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage FAQ"])},
  "1737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a FAQ"])},
  "1738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ details"])},
  "1739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit FAQ"])},
  "1740": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] Please enter the FAQ title"])},
  "1741": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[", _interpolate(_list(0)), "] Please enter the FAQ details"])},
  "1742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "1743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ Category"])},
  "1744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ Category Name"])},
  "1745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ Category Code"])},
  "1746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the multilingual FAQ category name"])},
  "1747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a Multilingual Language Code"])},
  "1748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no FAQ categories to change orders for"])},
  "1749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no FAQs to change orders for"])},
  "1750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no notices to change orders for"])},
  "1751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage friends at home now"])},
  "1752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as friends at home now"])},
  "1753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends at home now details"])},
  "1754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit friends at home now"])},
  "1755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends at home now"])},
  "1756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to remove the selected friends at home now?"])},
  "1757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage trending if home"])},
  "1758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending if home details"])},
  "1759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a trending if home"])},
  "1760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit trending if home"])},
  "1761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending if home"])},
  "1762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove the selected trending if home?"])},
  "1763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no trending if homes to expose."])},
  "1764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no friends at home now to change orders for."])},
  "1765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no trending if homes to change orders for."])},
  "1766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no popular posts to change orders for."])},
  "1767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage popular posts"])},
  "1768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular post details"])},
  "1769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a popular post"])},
  "1770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit popular posts"])},
  "1771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular posts"])},
  "1772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no popular post to expose."])},
  "1773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a selection by searching for a post."])},
  "1774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post to fix on the top"])},
  "1775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "1776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property details"])},
  "1777": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You may register up to ", _interpolate(_list(0)), "."])},
  "1778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP screen item name"])},
  "1779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APP screen category"])},
  "1781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the image size standards."])},
  "1782": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to remove the selected ", _interpolate(_list(0)), "?"])},
  "1783": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to remove the selected ", _interpolate(_list(0)), "?"])},
  "1784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "1785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This menu is already registered"])},
  "1786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to fix on the top"])},
  "1787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An already-registered menu is included."])},
  "1788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access location"])},
  "1789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled areas (Geofence)"])},
  "1790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred access location (Only activated when all areas are enabled areas)"])},
  "1791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no special if homes to change orders for."])},
  "1792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an external link being exposed during the exposure period."])},
  "1793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common for all if homes"])},
  "1794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage loading"])},
  "1795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage FAQ Category"])},
  "1796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag on top"])},
  "1797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tag on top you entered will be exposed next to the notice title fixed on top."])},
  "1798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial lounge"])},
  "1799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial if home"])},
  "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data to expose."])},
  "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage tag"])},
  "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register tag"])},
  "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tag"])},
  "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag info"])},
  "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag details"])},
  "1806": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are already exposing a tag. Clicking the confirm button will replace it with [", _interpolate(_list(0)), "]. Are you sure you want to save?"])},
  "1807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for a posting to select one."])},
  "1808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the access location might cancel the fixation on the top. Are you sure you want to make the change?"])},
  "1809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the exposure setting might cancel the fixation on the top. Are you sure you want to make the change?"])},
  "1810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "1811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage category"])},
  "1812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included as an FAQ or not"])},
  "1815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview the FAQs."])},
  "1816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview the list of FAQs."])},
  "1817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no FAQs to expose."])},
  "1818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no FAQs to change orders for."])},
  "1819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no notices to expose."])},
  "1820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete what you have written?"])},
  "1821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the orders for FAQs."])},
  "1822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An external link URL must start with 'http://' or 'https://'."])},
  "1823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for a host to select one."])},
  "1824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an external link URL or the code of the selected default language."])},
  "1825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only jpg, jpeg, or png files (under 100MB)"])},
  "1826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while connecting to an external server. Please try again later."])},
  "1827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size standards: 1920 px * 900 px, please register only png files (under 20MB)"])},
  "1828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This if home is already registered."])},
  "1829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post is already registered."])},
  "1830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This popular news is already registered."])},
  "1831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation size standards: 2400 px * 1080 px, "])},
  "1832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an language code of the selected default language in external link URL Info."])},
  "1833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This special if home is already registered"])},
  "1834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home communication event history"])},
  "1835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home event No"])},
  "1836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication event type"])},
  "1837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User shutdown normal"])},
  "1838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of creation"])},
  "1839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if home member number"])},
  "1840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of user participation"])},
  "1841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration time on if home"])},
  "1842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent users"])},
  "1843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage service language"])},
  "1844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language name"])},
  "1845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language code is already registered"])},
  "1846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage language tag"])},
  "1847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no language codes to change orders for"])},
  "1848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can fix or unfix special if homes for each service area on top."])},
  "1849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A special if home fixed on top may not appear in the app depending on the access location."])},
  "1850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register service language"])},
  "1851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service language details"])},
  "1852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit service language"])},
  "1853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register language tag"])},
  "1854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language tag details"])},
  "1855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit language tag"])},
  "1856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language area name"])},
  "1857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage common code"])},
  "1858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register common code"])},
  "1859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit common code"])},
  "1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common code"])},
  "1861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common group code"])},
  "1862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row"])},
  "1863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete row"])},
  "1864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common code name"])},
  "1865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
  "1866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the row to delete."])},
  "1867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the selected row?"])},
  "1868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reset what is written?"])},
  "1869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been reset."])},
  "1870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is invalid."])},
  "1871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A common code already in use is included."])},
  "1872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A common code name already in use is included."])},
  "1873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a required field. Please fill it out."])},
  "1874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage trending items"])},
  "1875": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view/register/delete items to fix on the top of the trending items area."])},
  "1876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case where you want to change orders for items to fix on the top, you can view the items on the administrator screen sorted by the area code or the category."])},
  "1877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can register up to 15 items for each region."])},
  "1878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item number"])},
  "1879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item name"])},
  "1880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item type"])},
  "1881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
  "1882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
  "1883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for and select an item."])},
  "1884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for items"])},
  "1885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item to fix on the top"])},
  "1886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "1887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costume"])},
  "1888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion"])},
  "1889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can fix up to 15 items on the top."])},
  "1890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is already registered."])},
  "1891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no trending items to change orders for."])},
  "1892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common group code title"])},
  "1893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common code name (ko)"])},
  "1894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common code name (en)"])},
  "1895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common code description"])},
  "1896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common group code description"])},
  "1897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1897"])},
  "1898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending items"])},
  "1899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image optimization (PNG > JPEG)"])},
  "1900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached file volume"])},
  "1901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended friends for me"])},
  "1902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's short-forms"])},
  "1903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends' news"])},
  "1904": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing banner"])},
  "1905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well-decorated if home"])},
  "1906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred access location"])},
  "1907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp area code"])},
  "1908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Telp area code"])},
  "1909": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can select up to ", _interpolate(_list(1)), " ", _interpolate(_list(0)), "."])},
  "1910": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can select up to ", _interpolate(_list(1)), " ", _interpolate(_list(0)), "."])},
  "1911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if square topic"])},
  "1912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage if square topic"])},
  "1913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
  "1914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended title for the topic"])},
  "1915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic NO"])},
  "1916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only jpg or png files (under 20MB)."])},
  "1917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same language code"])},
  "1918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 8 if square topics can be exposed."])},
  "1919": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are no ", _interpolate(_list(0)), " to change orders for."])},
  "1920": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are no ", _interpolate(_list(0)), " to change orders for."])},
  "1921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select up to 3 of the same language code."])},
  "1922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended room"])},
  "1923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The attachment format is converted to ’JPEG’ upon saving the banner only if the image optimization option is set as ’ON’ and if the attachment format is ’PNG.’"])},
  "1924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP partnership if home"])},
  "1925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist group"])},
  "1926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist lounge"])},
  "1927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist room"])},
  "1928": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please search for ", _interpolate(_list(0)), " and make a selection"])},
  "1929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist group NO"])},
  "1930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist group name"])},
  "1931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist group details"])},
  "1932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for artist groups"])},
  "1933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage K-POP HOTEL"])},
  "1934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage K-POP pop-up text"])},
  "1935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up text"])},
  "1936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
  "1937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimized attachment volume"])},
  "1938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search for an artist group to select one."])},
  "1939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member's my if home first"])},
  "1940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage CMS"])},
  "1941": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Status"])},
  "1942": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETE"])},
  "1943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR"])},
  "1944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Progress"])},
  "1945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion Request Date"])},
  "1946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion Completion Date"])},
  "1947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Time (ms)"])},
  "1948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative Streaming URL"])},
  "1949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Title"])},
  "1950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "1951": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
  "1952": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded."])},
  "1953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading the server. Please wait."])},
  "1954": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save."])},
  "1955": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Value"])},
  "1956": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job ID"])},
  "1957": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Code"])},
  "1958": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Message"])},
  "1959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original URL"])},
  "1960": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output per Resolution"])},
  "1961": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution Width"])},
  "1962": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution Height"])},
  "1963": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Bitrate"])},
  "1964": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please attach ", _interpolate(_list(0))])},
  "1965": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please attach ", _interpolate(_list(0))])},
  "1966": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Details"])},
  "1967": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion Request Date"])},
  "1968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can copy the URL by clicking the icon."])},
  "1969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage K-POP Loading"])},
  "1970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guestroom Floor"])},
  "1971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounge Image"])},
  "1972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Badge Image"])},
  "1973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Artist Group Names"])},
  "1974": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Group Member"])},
  "1975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Number"])},
  "1976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Name"])},
  "1977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Number"])},
  "1978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Image"])},
  "1979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Artist Group"])},
  "1980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Artist Names"])},
  "1981": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Fan Missions"])},
  "1982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Number"])},
  "1983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Name"])},
  "1984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Details"])},
  "1985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Image"])},
  "1986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Notification"])},
  "1987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission"])},
  "1988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Completion Notification"])},
  "1989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Fan Mission Rewards"])},
  "1990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Reward"])},
  "1991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Reward Number"])},
  "1992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative Reward"])},
  "1993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Reward Name"])},
  "1994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Item Number"])},
  "1995": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This ", _interpolate(_list(0)), " is valid."])},
  "1996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Item"])},
  "1997": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This ", _interpolate(_list(0)), " does not exist."])},
  "1998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Number"])},
  "1999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan Mission Reward Info"])},
  "2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Image"])},
  "2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP Loading"])},
  "2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP Pop-Up Text"])},
  "2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Group Fan Mission"])},
  "2004": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Connect via searching for ", _interpolate(_list(0))])},
  "2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Group Fan Mission Number"])},
  "2006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Accomplishment Confirmed by Admin"])},
  "2007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Accomplishment Date and Time"])},
  "2008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Landing Parameter"])},
  "2009": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please proceed with the ", _interpolate(_list(0)), " validation"])},
  "2010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplishment conditions"])},
  "2011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplished missions"])},
  "2012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "2013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
  "2014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
  "2015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplished"])},
  "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load fan missions"])},
  "2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for fan missions"])},
  "2018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual fan missions"])},
  "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select fan missions"])},
  "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading fan missions is required"])},
  "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a duplicate Member Number"])},
  "2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Guide Details"])},
  "2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the closure"])},
  "2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping status"])},
  "2025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping date"])},
  "2026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the shipping failure"])},
  "2027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion"])},
  "2028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
  "2029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no member number"])},
  "2030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS Email Shipping History"])},
  "2031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh Cache"])},
  "2032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cache has been successfully refreshed"])},
  "2033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cache refresh has failed, please retry. If it fails again, contact the admin."])},
  "2034": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This ", _interpolate(_list(0)), " is already registered."])},
  "2035": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This ", _interpolate(_list(0)), " is already registered."])},
  "2036": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Image size standards : ", _interpolate(_list(0))])},
  "2037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Logo Image"])},
  "2038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP Multilingual Message"])},
  "2039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage K-POP Multilingual Message"])},
  "2040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Column Name"])},
  "2041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Name"])},
  "2042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Group Key"])},
  "2043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K-POP Multilingual Message Info"])},
  "2044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit K-POP Multilingual Message"])},
  "2045": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are already exposing ", _interpolate(_list(0)), " during the exposure period."])},
  "2046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Group Name"])},
  "2047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Message"])},
  "2048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Themes"])},
  "2049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage TRO Room Info"])},
  "2050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seating Capacity"])},
  "2051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Type"])},
  "2052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Type"])},
  "2053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRO Room"])},
  "2054": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The short-form with [Post ID: ", _interpolate(_list(0)), "] is not saved due to the no exposure order of the short-form, Please go to the short-form and save the exposure order."])},
  "2055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Room"])},
  "2056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 Room"])},
  "2057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World"])},
  "2058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My home"])},
  "2059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conference Room"])},
  "2060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience Room"])},
  "2061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conference Event Room"])},
  "2062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conference event viewing room"])},
  "2063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrance ceremony"])},
  "2064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive conference"])},
  "2065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alliance conference"])},
  "2066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Mission Achiever Excel"])},
  "2067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Execution Count Input Status"])},
  "2068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Execution Count"])},
  "2069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Execution Count Input Reason"])},
  "2070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set the end time of the open time to after the current time."])},
  "2071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Number"])},
  "2072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel download has been completed."])},
  "2073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel download failed, please try again. If it fails again, please contact the administrator."])},
  "2074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register only jpg, jpeg or png files (under 1MB)."])},
  "2075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user who is not logged in to SNS has been designated as the host. Please change the host on the monitoring control screen."])},
  "2076": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " file cannot be uploaded."])},
  "2077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avi"])},
  "2078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Admin Execution Count input value is saved one time, and integers such as +, -, and 0 can be entered."])}
}