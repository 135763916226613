export default {
    menu(state, value) {
        state.menu = value;
    },
    user(state, value) {
        state.user = value;
    },
    locale(state, value) {
        state.locale = value;
    },
    SET_IS_CHANGE_GROUP_PAGE: (state, isDisable) => {
        state.isChangeGroupPage = isDisable;
    },
};
