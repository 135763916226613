import { createStore } from 'vuex';

import mutations from './mutations';
import actions from './actions';

import sessionStore from '@/store/modules/sessionStore';
import menuStore from '@/store/modules/menuStore';
import searchHistoryStore from '@/store/modules/searchHistoryStore';
import loadingStore from '@/store/modules/loadingStore';
import layerStore from '@/store/modules/layerStore';
import dialogStore from '@/store/modules/dialogStore';
import progressStore from '@/store/modules/progressStore';

export default createStore({
    modules: {
        sessionStore,
        menuStore,
        layerStore,
        searchHistoryStore,
        loadingStore,
        dialogStore,
        progressStore,
    },
    state: {
        menu: null,
        user: null,
        locale: null,
        isChangeGroupPage: false,
    },
    mutations,
    actions,
});
