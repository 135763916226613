export default {
    SET_IS_CHANGE_GROUP_PAGE: ({ commit }, isDisable) => {
        commit('SET_IS_CHANGE_GROUP_PAGE', isDisable);
    },
    // SET_PAGING_HISTORY({ commit }, payload) {
    //     commit('pagingHistory', payload);
    // },
    // CLEAR_PAGING_HISTORY({ commit }) {
    //     commit('pagingHistory', null);
    // },
};
