import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mitt from 'mitt';

import ElementPlus from 'element-plus';
import { ElMessageBox, ElMessage } from 'element-plus';
import ko from 'element-plus/es/locale/lang/ko';
import 'element-plus/dist/index.css';
import '@/assets/scss/style.scss';
import { i18n } from '@/i18n.js';
import access from '@/plugin/access';
import VueDayjs from 'vue3-dayjs-plugin';
import axiosApi from '@/api/index';

const emitter = mitt();
const app = createApp(App)
    .use(VueDayjs)
    .use(router)
    .use(store)
    .use(emitter)
    .use(ElementPlus)
    .use(ElMessageBox)
    .use(ElMessage)
    .use(i18n)
    .use(access)
    .use(ko);

app.config.globalProperties.$eventBus = emitter;
app.config.globalProperties.$router = router;

window.FV = function () {
    const version = process.env[`VUE_APP_VERSION`];
    console.log(`%c${version}`, 'color: #5f2cdf;font-size:80px;font-weight:bold');
};

window.AV = function () {
    axiosApi.get('/api/monitor/info').then(result => {
        const version = result.application.version;
        console.log(`%c${version}`, 'color: #B22222;font-size:80px;font-weight:bold');
    });
};

app.mount('#app');
