const loadingStore = {
    namespaced: true,
    state: {
        isLoading: false,
        isMountedLoading: false,
    },
    getters: {
        GET_IS_LOADING: state => state.isLoading,
        GET_IS_MOUNTED_LOADING: state => state.isMountedLoading,
    },
    mutations: {
        SET_IS_LOADING: state => {
            state.isLoading = !state.isLoading;
        },
        SET_IS_MOUNTED_LOADING: state => {
            state.isMountedLoading = !state.isMountedLoading;
        },
        CLEAR_LOADING: state => {
            state.isLoading = false;
            state.isMountedLoading = false;
        },
    },
    actions: {
        SET_IS_LOADING: ({ commit }) => {
            commit('SET_IS_LOADING');
        },
        SET_IS_MOUNTED_LOADING: ({ commit }) => {
            commit('SET_IS_MOUNTED_LOADING');
        },
        CLEAR_LOADING: ({ commit }) => {
            commit('CLEAR_LOADING');
        },
    },
};

export default loadingStore;
