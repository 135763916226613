const progressStore = {
    namespaced: true,
    state: {
        progress: false,
    },
    getters: {
        GET: state => state.progress,
    },
    mutations: {
        SET(state, value) {
            state.progress = value;
        },
    },
    actions: {
        SET_PROGRESS({ commit }, payload) {
            commit('SET', payload);
        },
        CLEAR_PROGRESS({ commit }) {
            commit('SET', false);
        },
    },
};

export default progressStore;
