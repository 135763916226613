<template>
    <div class="pop-wrapper msg-box">
        <div class="modal" ref="modalBg"></div>
        <div class="popup" :style="{ width: '500px' }">
            <div class="popup-header">
                <div class="title">{{ dialog.title || '' }}</div>
                <button class="btn pop-close" type="button" v-if="dialog.type === 'A'" @click="excute"></button>
                <button class="btn pop-close" type="button" v-else-if="dialog.type === 'C'" @click="close"></button>
            </div>
            <div class="popup-content">
                <div class="popup-txt" v-html="text || ''"></div>
            </div>

            <div class="popup-footer" v-if="dialog.type === 'A'">
                <button class="btn primary round" type="button" @click="excute" ref="focusRef">
                    {{ $t('message.0181') }}
                </button>
            </div>
            <div class="popup-footer" v-else-if="dialog.type === 'C'">
                <button class="btn outline round" type="button" @click="close" ref="focusRef">
                    {{ $t('message.0018') }}
                </button>
                <button class="btn round primary" type="button" @click="excute">{{ $t('message.0181') }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const focusRef = ref(null);

const dialog = store.getters['dialogStore/GET_DIALOG'];
const layerCount = store.getters['layerStore/GET_COUNT'];

const text = computed(() => {
    let text = dialog.text;
    if (text && text.indexOf('\n') !== -1) {
        text = text.replace(/\n/g, '<br>');
    }
    return text;
});

const handleBodyStyle = () => {
    if (layerCount === 0) {
        document.body.style.setProperty('overflow', 'unset', 'important');
    }
};

const close = () => {
    if (dialog.cancelCallBack) {
        dialog.cancelCallBack();
    }
    store.dispatch('dialogStore/CLOSE');
    handleBodyStyle();
};
const excute = () => {
    if (dialog.callBack) {
        dialog.callBack();
    }
    store.dispatch('dialogStore/CLOSE');
    handleBodyStyle();
};

onMounted(() => {
    nextTick(() => {
        document.body.style.overflow = 'hidden';
        focusRef.value.focus();
    });
});
</script>
