export default {
  "password.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the password."])},
  "id.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the User ID."])},
  "verifyCode.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the authenticode."])},
  "email.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the e-mail address."])},
  "advertBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This attached file of the browser pop-up banner is not a valid file."])},
  "advertBannerLangAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This attached file of the multilingual browser pop-up banner is not a valid file."])},
  "listBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This attached file of the list banner is not a valid file."])},
  "listBannerLangAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This attached file of the multilingual list banner is not a valid file."])},
  "prohibitBatchAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This prohibited words in Excel is not a valid file."])},
  "streamingUrl.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This streaming URL is not a valid file."])},
  "imageAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This image is not a valid file."])},
  "ptrshImgFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This representative image is not a valid file."])},
  "ogFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Open Graph image is not a valid file."])},
  "presentAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This presentation document is not a valid file."])},
  "resourceAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This resource value is not a valid file."])},
  "cardImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This logo image is not a valid file."])},
  "ptnrImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This background image is not a valid file."])},
  "roomImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This affiliate's room image is not a valid file."])},
  "objCtntMp4.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This content is not a valid file."])},
  "bgCtntMp4.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 360 background video clip is not an available file."])},
  "playitemCode.AlphaNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only English letters and numbers can be used for play item codes."])},
  "logoImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This logo image is not a valid file."])},
  "coverImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cover image is not a valid file."])},
  "animationJson.JsonFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This animation json is not a valid file."])},
  "imageFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This image is not a valid file."])},
  "ifhomeBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This attached file of the if home banner is not a valid file."])}
}