import loginApi from '@/api/login/login';

const sessionStore = {
    namespaced: true,
    state: {
        id: null,
        menus: null,
        resources: null,
        roles: null,
        currentGroupNo: null,
        groups: null,
        restrictYn: null,
        hasSession: false,
    },
    getters: {
        GET_MENUS: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.menus;
                } catch (error) {
                    return;
                }
            } else {
                return state.menus;
            }
        },
        GET_RESOURCES: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.resources;
                } catch (error) {
                    return;
                }
            } else {
                return state.resources;
            }
        },
        GET_ROLES: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.roles;
                } catch (error) {
                    return;
                }
            } else {
                return state.roles;
            }
        },
        GET_USER_ID: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.id;
                } catch (error) {
                    return;
                }
            } else {
                return state.id;
            }
        },
        GET_CURRENT_GROUPNO: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.currentGroupNo;
                } catch (error) {
                    return;
                }
            } else {
                return state.currentGroupNo;
            }
        },
        GET_GROUPS: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.groups;
                } catch (error) {
                    return;
                }
            } else {
                return state.groups;
            }
        },
        GET_RESTRICT_YN: async state => {
            if (!state.hasSession) {
                try {
                    await getSession(state);
                    return state.restrictYn;
                } catch (error) {
                    return;
                }
            } else {
                return state.restrictYn;
            }
        },
    },
    mutations: {
        SET_MENUS: (state, menus) => {
            state.menus = menus;
        },
        SET_RESOURCES: (state, resources) => {
            state.resources = resources;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_USER_ID: (state, id) => {
            state.id = id;
        },
        SET_CURRENT_GROUPNO: (state, groupNo) => {
            state.currentGroupNo = groupNo;
        },
        SET_GROUPS: (state, groups) => {
            state.groups = groups;
        },
        SET_RESTRICT_YN: (state, restrictYn) => {
            state.restrictYn = restrictYn;
        },
    },
    actions: {
        SET_MENUS: ({ commit }, menus) => {
            commit('SET_MENUS', menus);
        },
        SET_RESOURCES: ({ commit }, resources) => {
            commit('SET_RESOURCES', resources);
        },
        SET_ROLES: ({ commit }, roles) => {
            commit('SET_ROLES', roles);
        },
        SET_USER_ID: ({ commit }, id) => {
            commit('SET_USER_ID', id);
        },
        SET_CURRENT_GROUPNO: ({ commit }, groupNo) => {
            commit('SET_CURRENT_GROUPNO', groupNo);
        },
        SET_GROUPS: ({ commit }, groups) => {
            commit('SET_GROUPS', groups);
        },
        SET_RESTRICT_YN: ({ commit }, restrictYn) => {
            commit('SET_RESTRICT_YN', restrictYn);
        },
        CLEAR_SESSION: ({ commit }) => {
            commit('SET_MENUS', null);
            commit('SET_RESOURCES', null);
            commit('SET_ROLES', null);
            commit('SET_USER_ID', null);
            commit('SET_USER_ID', null);
            commit('SET_CURRENT_GROUPNO', null);
            commit('SET_GROUPS', null);
            commit('SET_RESTRICT_YN', null);
        },
    },
};

const getSession = async state => {
    const { payload } = await loginApi.getSessionInfo();
    state.id = payload.id;
    state.menus = payload.menus;
    state.resources = payload.resources;
    state.roles = payload.roles;
    state.currentGroupNo = payload.selectGroupNo;
    state.groups = payload.groups;
    state.restrictYn = payload.restrictYn;
    
    state.hasSession = true;
};

export default sessionStore;
