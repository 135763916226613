export default {
  "password.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패스워드를 입력해 주세요"])},
  "id.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 입력해 주세요"])},
  "verifyCode.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증코드를 입력해 주세요"])},
  "email.NotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 입력해 주세요"])},
  "advertBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 첨부파일은 유효하지 않은 파일입니다"])},
  "advertBannerLangAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전면팝업 배너 다국어 첨부파일은 유효하지 않은 파일입니다"])},
  "listBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 첨부파일은 유효하지 않은 파일입니다"])},
  "listBannerLangAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 배너 다국어 첨부파일은 유효하지 않은 파일입니다"])},
  "prohibitBatchAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금칙어 엑셀은 유효하지 않은 파일입니다"])},
  "streamingUrl.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트리밍 URL은 유효하지 않은 파일입니다"])},
  "imageAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지는 유효하지 않은 파일입니다"])},
  "ptrshImgFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 이미지는 유효하지 않은 파일입니다"])},
  "ogFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Graph 이미지는 유효하지 않은 파일입니다"])},
  "presentAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자료는 유효하지 않은 파일입니다"])},
  "resourceAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리소스 값은 유효하지 않은 파일입니다"])},
  "cardImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 이미지는 유효하지 않은 파일입니다"])},
  "ptnrImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경 이미지는 유효하지 않은 파일입니다"])},
  "roomImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴업체 방 이미지는 유효하지 않은 파일입니다"])},
  "objCtntMp4.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠는 유효하지 않은 파일입니다"])},
  "bgCtntMp4.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360도 배경 영상은 유효하지 않은 파일입니다"])},
  "playitemCode.AlphaNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이 아이템 코드는 영문, 숫자만 입력 가능합니다"])},
  "logoImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 이미지는 유효하지 않은 파일입니다"])},
  "coverImg.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커버 이미지는 유효하지 않은 파일입니다"])},
  "animationJson.JsonFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애니메이션 json은 유효하지 않은 파일입니다"])},
  "imageFile.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지는 유효하지 않은 파일입니다."])},
  "ifhomeBannerAttach.IsFileExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ifhome 배너 첨부파일은 유효하지 않은 파일입니다"])}
}