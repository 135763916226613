import { parse, stringify } from 'qs';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { i18n } from '@/i18n.js';

import { useDialog } from '@/hook/useDialog';
import { useProgress } from '@/hook/useProgress';

import { PROGRESS_STATUS } from '@/constants/status';

const { alert } = useDialog();
const { setProgress } = useProgress();

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

const paramsSerializer = {
    encode: parse,
    serialize: stringify,
};

instance.interceptors.request.use(
    // 요청 성공 직전 호출됩니다.
    // 2024-04-03 프로그레스 로딩 추가
    function (config) {
        config.headers['X-Requested-With'] = 'XMLHttpRequest';

        if (config.method === 'get') {
            config.paramsSerializer = paramsSerializer;
        }

        if (config.isProgressLoading) {
            config.onUploadProgress = progressEvent => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                let status = PROGRESS_STATUS.PROGRESS;

                setProgress(percentage, status);
            };
        } else {
            store.dispatch('loadingStore/SET_IS_LOADING');
        }

        return config;
    },
    function (error) {
        // 요청 에러 직전 호출됩니다.
        if (store.getters['loadingStore/GET_IS_LOADING']) {
            store.dispatch('loadingStore/SET_IS_LOADING');
        } else {
            store.dispatch('progressStore/CLEAR_PROGRESS');
            setProgress(40, PROGRESS_STATUS.EXCEPTION);
        }
        return Promise.reject(error);
    },
);

// 응답 인터셉터
instance.interceptors.response.use(
    function (response) {
        /**
         * http status가 200인 경우
         * 응답 성공 직전 호출됩니다.
         * .then() 으로 이어집니다.
         */
        let resData = response.data;
        // if (!resData) {
        //     return Promise.reject({ message: 'invalid data format' });
        // }

        // if (undefined === resData.status) {
        //     return Promise.reject({ message: 'status not exist in body' });
        // }

        // // 사용자 error 체크 메시지 출력
        // if (0 < resData.status) {
        //     return Promise.reject({ message: resData.message, status: resData.status });
        // }

        if (response.config.isProgressLoading) {
            if (resData.code === '0000') {
                setProgress(100, PROGRESS_STATUS.SUCCESS);
            }
        }

        if (store.getters['loadingStore/GET_IS_LOADING']) {
            store.dispatch('loadingStore/SET_IS_LOADING');
        }

        return resData;
    },

    function (error) {
        const { t } = i18n.global;

        const errorAlertRouting = text => {
            alert({
                text,
                callBack: function () {
                    const path = store.getters['menuStore/GET_MENU_LIST_PATH'];
                    router.push({ path });
                },
            });
        };

        /**
         * http status가 200이 아닌 경우
         * 응답 에러 직전 호출됩니다.
         * .catch() 으로 이어집니다.
         */
        const status = error.response.status;
        const data = error.response.data;

        if (error.config.isProgressLoading) {
            setProgress(40, PROGRESS_STATUS.EXCEPTION);
        } else {
            store.dispatch('loadingStore/CLEAR_LOADING');

            if (status === 400) {
                if (data.code === '1000') {
                    errorAlertRouting(t('message.0720'));
                }

                // const e = data.payload;
                // alert({ text: t('validate["' + e.field + '.' + e.code + '"]') });
            } else if (401 === status) {
                store.dispatch('sessionStore/CLEAR_SESSION');
                router.replace({ name: 'Login' });
            } else if (403 === status) {
                alert({ text: t('message.1139') });
            } else if (422 === status) {
                if (data.code === '4002') {
                    alert({ text: t('message.1410', [data.payload[0], data.payload[1]]) });
                } else if (data.code === '9997') {
                    alert({ text: data.payload[0] });
                } else if (data.code === '9998') {
                    const payload = [...data.payload];
                    alert({ text: t('message.' + payload.splice(0, 1), payload.splice(0, payload.length)) });
                } else if (data.code === '3100' || data.code === '9999') {
                    alert({ text: t('message.1059') });
                } else if (data.code === '3001') {
                    errorAlertRouting(t('message.0720'));
                }
            } else if (500 === status) {
                if (data.code === '3003') {
                    alert({ text: t('message.2021') });
                } else {
                    alert({ text: t('message.1059') });
                }
            }
        }

        return Promise.reject(data ? data : error);
    },
);

export default instance;
