const layerStore = {
    namespaced: true,
    state: {
        actives: [],
    },
    getters: {
        IS_OPENED: state => id => {
            return state.actives.includes(id);
        },
        GET_COUNT: state => {
            return state.actives.length;
        },
    },
    mutations: {
        ADD: (state, id) => {
            state.actives.push(id);
        },
        POP: state => {
            state.actives = state.actives.slice(0, state.actives.length - 1);
        },
    },
    actions: {
        OPEN: ({ commit }, id) => {
            commit('ADD', id);
        },
        CLOSE: ({ commit }) => {
            commit('POP');
        },
    },
};

export default layerStore;
