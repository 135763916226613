import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import loginApi from '@/api/login/login';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/index',
    },
    // 레이아웃 포함 페이지
    {
        path: '/',
        name: 'layoutDefault',
        component: () => import('@/layout/LayoutDefault.vue'),
        children: [
            {
                path: '/',
                redirect: '/index',
            },
            {
                path: '/index',
                name: 'index',
                component: () => import('@/views/Index.vue'),
            },
            /**
             * 서비스 관리 > 배너관리 > 전면팝업 배너 관리
             */
            {
                path: '/service/front-popup-banner',
                name: 'FrontPopupBanner',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0001',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/FrontPopupBanner.vue'),
            },
            {
                path: '/service/front-popup-banner/view/:advrtBannerNo',
                name: 'FrontPopupBannerView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0001',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/FrontPopupBannerView.vue'),
            },
            {
                path: '/service/front-popup-banner/create',
                name: 'FrontPopupBannerCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0001',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/FrontPopupBannerCreate.vue'),
            },
            {
                path: '/service/front-popup-banner/update/:advrtBannerNo',
                name: 'FrontPopupBannerUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0001',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/FrontPopupBannerCreate.vue'),
            },

            /**
             * 서비스 관리 > 배너관리 > 리스트 배너 관리
             */
            {
                path: '/service/list-banner',
                name: 'ListBanner',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0002',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/ListBanner.vue'),
            },
            {
                path: '/service/list-banner/timetable',
                name: 'ListBannerTimetable',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0002',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ListBannerTimetable.vue'),
            },
            {
                path: '/service/list-banner/view/:advrtBannerNo',
                name: 'ListBannerView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0002',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ListBannerView.vue'),
            },
            {
                path: '/service/list-banner/create',
                name: 'ListBannerCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0002',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ListBannerCreate.vue'),
            },
            {
                path: '/service/list-banner/update/:advrtBannerNo',
                name: 'ListBannerUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0002',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ListBannerCreate.vue'),
            },

            /**
             * 서비스 관리 > 배너관리 > 마케팅 URL 관리
             */
            {
                path: '/service/marketing-url',
                name: 'MarketingUrl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0011',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/service/MarketingUrl.vue'),
            },
            {
                path: '/service/marketing-url/create',
                name: 'MarketingUrlCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0011',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/MarketingUrlCreate.vue'),
                props: true,
            },
            {
                path: '/service/marketing-url/view/:advrtBannerNo',
                name: 'MarketingUrlView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0011',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/MarketingUrlView.vue'),
            },
            {
                path: '/service/marketing-url/update/:advrtBannerNo',
                name: 'MarketingUrlUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0011',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/MarketingUrlCreate.vue'),
            },

            /**
             * 서비스 관리 > 배너관리 > ifhome 마케팅 URL 관리
             */
            {
                path: '/service/ifhome-marketing-url',
                name: 'IfhomeMarketingUrl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0039',
                    roles: [],
                },
                component: () => import('@/views/service/IfhomeMarketingUrl.vue'),
            },
            {
                path: '/service/ifhome-marketing-url/create',
                name: 'IfhomeMarketingUrlCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0039',
                    roles: [],
                },
                component: () => import('@/views/service/IfhomeMarketingUrlCreate.vue'),
                props: true,
            },
            {
                path: '/service/ifhome-marketing-url/view/:advrtBannerNo',
                name: 'IfhomeMarketingUrlView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0039',
                    roles: [],
                },
                component: () => import('@/views/service/IfhomeMarketingUrlView.vue'),
                props: true,
            },
            {
                path: '/service/ifhome-marketing-url/update/:advrtBannerNo',
                name: 'IfhomeMarketingUrlUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0039',
                    roles: [],
                },
                component: () => import('@/views/service/IfhomeMarketingUrlCreate.vue'),
                props: true,
            },
            /**
             * 서비스 관리 > 문구관리 > 이탈방지 팝업 문구 관리
             */
            {
                path: '/service/member-retention-popup-text',
                name: 'MemberRetentionPopupText',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0040',
                    roles: [],
                },
                component: () => import('@/views/service/MemberRetentionPopupText.vue'),
            },
            {
                path: '/service/member-retention-popup-text/create',
                name: 'MemberRetentionPopupTextCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0040',
                    roles: [],
                },
                component: () => import('@/views/service/MemberRetentionPopupTextCreate.vue'),
            },
            {
                path: '/service/member-retention-popup-text/update/:advrtTextNo',
                name: 'MemberRetentionPopupTextUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0040',
                    roles: [],
                },
                component: () => import('@/views/service/MemberRetentionPopupTextCreate.vue'),
            },
            {
                path: '/service/member-retention-popup-text/view/:advrtTextNo',
                name: 'MemberRetentionPopupTextView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0040',
                    roles: [],
                },
                component: () => import('@/views/service/MemberRetentionPopupTextView.vue'),
            },
            /**
             * 서비스 관리 > 배너관리 > ifhome 배너 관리
             */
            {
                path: '/service/ifhome-banner',
                name: 'IfhomeBanner',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0038',
                    roles: [],
                },
                component: () => import('@/views/service/IfhomeBanner.vue'),
            },
            {
                path: '/service/ifhome-banner/view/:advrtBannerNo',
                name: 'IfhomeBannerView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0038',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/IfhomeBannerView.vue'),
            },
            {
                path: '/service/ifhome-banner/create',
                name: 'IfhomeBannerCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0038',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/IfhomeBannerCreate.vue'),
            },
            {
                path: '/service/ifhome-banner/update/:advrtBannerNo',
                name: 'IfhomeBannerUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0038',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/IfhomeBannerCreate.vue'),
            },
            /**
             * 서비스 관리 > 금칙어 관리
             */
            {
                path: '/service/banned-words',
                name: 'BannedWords',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0012',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/service/BannedWords.vue'),
            },
            {
                path: '/service/banned-words/create',
                name: 'BannedWordsCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0012',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/BannedWordsCreate.vue'),
            },
            {
                path: '/service/banned-words/update',
                name: 'BannedWordsUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0012',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/BannedWordsCreate.vue'),
            },
            {
                path: '/service/banned-words/create-all',
                name: 'BannedWordsCreateAll',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0012',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/BannedWordsCreateAll.vue'),
            },
            {
                path: '/service/banned-words/view',
                name: 'BannedWordsView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0012',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/BannedWordsView.vue'),
            },

            /**
             * 서비스 관리 > 툴팁 관리
             */
            {
                path: '/service/tooltip',
                name: 'Tooltip',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0013',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/service/Tooltip.vue'),
            },
            {
                path: '/service/tooltip/view/:notiNo',
                name: 'TooltipView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0013',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/TooltipView.vue'),
            },
            {
                path: '/service/tooltip/create',
                name: 'TooltipCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0013',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/TooltipCreate.vue'),
            },
            {
                path: '/service/tooltip/update/:notiNo',
                name: 'TooltipUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0013',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/TooltipCreate.vue'),
            },

            /**
             * 서비스 관리 > 게스트 가이드 관리
             */
            {
                path: '/service/guest-guide',
                name: 'GuestGuide',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0014',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/service/GuestGuide.vue'),
            },
            {
                path: '/service/guest-guide/create',
                name: 'GuestGuideCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0014',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/GuestGuideCreate.vue'),
            },
            {
                path: '/service/guest-guide/update/:guestGudMgNo',
                name: 'GuestGuideUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0014',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/GuestGuideCreate.vue'),
            },
            {
                path: '/service/guest-guide/view/:guestGudMgNo',
                name: 'GuestGuideView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0014',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/service/GuestGuideView.vue'),
            },
            /**
             * 서비스 관리 > 외부 링크 관리
             */
            {
                path: '/service/external-links',
                name: 'ExternalLink',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0043',
                    roles: [],
                },
                component: () => import('@/views/service/ExternalLink.vue'),
            },
            {
                path: '/service/external-links/create',
                name: 'ExternalLinkCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0043',
                    roles: [],
                },
                component: () => import('@/views/service/ExternalLinkCreate.vue'),
            },
            {
                path: '/service/external-links/update/:extLinkNo',
                name: 'ExternalLinkUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0043',
                    roles: [],
                },
                component: () => import('@/views/service/ExternalLinkCreate.vue'),
            },
            {
                path: '/service/external-links/view/:extLinkNo',
                name: 'ExternalLinkView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0043',
                    roles: [],
                },
                component: () => import('@/views/service/ExternalLinkView.vue'),
            },
            /**
             * 서비스 관리 > 스플래시 관리
             */
            {
                path: '/service/splash',
                name: 'Splash',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0042',
                    roles: [],
                },
                component: () => import('@/views/service/Splash.vue'),
            },
            {
                path: '/service/splash/create',
                name: 'SplashCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0042',
                    roles: [],
                },
                component: () => import('@/views/service/SplashCreate.vue'),
            },
            {
                path: '/service/splash/update/:advrtSplashNo',
                name: 'SplashUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0042',
                    roles: [],
                },
                component: () => import('@/views/service/SplashCreate.vue'),
            },
            {
                path: '/service/splash/view/:advrtSplashNo',
                name: 'SplashView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0042',
                    roles: [],
                },
                component: () => import('@/views/service/SplashView.vue'),
            },
            /**
             * 서비스 관리 > 보상 문구 관리
             */
            {
                path: '/service/compensation',
                name: 'Compensation',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0041',
                    roles: [],
                },
                component: () => import('@/views/service/Compensation.vue'),
            },
            {
                path: '/service/compensation/view/:advrtTextNo',
                name: 'CompensationView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0041',
                    roles: [],
                },
                component: () => import('@/views/service/CompensationView.vue'),
            },
            {
                path: '/service/compensation/create',
                name: 'CompensationCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0041',
                    roles: [],
                },
                component: () => import('@/views/service/CompensationCreate.vue'),
            },
            {
                path: '/service/compensation/update/:advrtTextNo',
                name: 'CompensationUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0041',
                    roles: [],
                },
                component: () => import('@/views/service/CompensationCreate.vue'),
            },
            /**
             * 서비스 관리 -> FAQ 관리 -> FAQ 카테고리 관리
             */
            {
                path: '/service/faqs/categories',
                name: 'FaqCategory',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/FaqCategory.vue'),
            },
            {
                path: '/service/faqs/categories/view/:categoryNo',
                name: 'FaqCategoryView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/FaqCategoryView.vue'),
            },
            {
                path: '/service/faqs/categories/create',
                name: 'FaqCategoryCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/FaqCategoryCreate.vue'),
            },
            {
                path: '/service/faqs/categories/update/:categoryNo',
                name: 'FaqCategoryUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/FaqCategoryCreate.vue'),
            },

            /**
             * 서비스 관리 > APP 화면 항목 관리
             */
            {
                path: '/service/exposure-item',
                name: 'AppScreen',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0044',
                    roles: [],
                },
                component: () => import('@/views/service/AppScreen.vue'),
            },
            {
                path: '/service/exposure-item/view/:expItemNo',
                name: 'AppScreenView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0044',
                    roles: [],
                },
                component: () => import('@/views/service/AppScreenView.vue'),
            },
            {
                path: '/service/exposure-item/create',
                name: 'AppScreenCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0044',
                    roles: [],
                },
                component: () => import('@/views/service/AppScreenCreate.vue'),
            },
            {
                path: '/service/exposure-item/update/:expItemNo',
                name: 'AppScreenUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0044',
                    roles: [],
                },
                component: () => import('@/views/service/AppScreenCreate.vue'),
            },

            /**
             * 서비스 관리 > 공지사항 관리
             */
            {
                path: '/service/notices',
                name: 'Notice',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/Notice.vue'),
            },
            {
                path: '/service/notices/view/:noticeNo',
                name: 'NoticeView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeView.vue'),
            },
            {
                path: '/service/notices/create',
                name: 'NoticeCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeCreate.vue'),
            },
            {
                path: '/service/notices/update/:noticeNo',
                name: 'NoticeUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeCreate.vue'),
            },
            {
                path: '/service/notices/tags',
                name: 'NoticeTag',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeTag.vue'),
            },
            {
                path: '/service/notices/tags/view/:tagNo',
                name: 'NoticeTagView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeTagView.vue'),
            },
            {
                path: '/service/notices/tags/create',
                name: 'NoticeTagCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeTagCreate.vue'),
            },
            {
                path: '/service/notices/tags/update/:tagNo',
                name: 'NoticeTagUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0045',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeTagCreate.vue'),
            },

            /**
             * 서비스 관리 > FAQ 관리
             */
            {
                path: '/service/faqs',
                name: 'Faq',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/Faq.vue'),
            },
            {
                path: '/service/faqs/view/:noticeNo',
                name: 'FaqView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/FaqView.vue'),
            },
            {
                path: '/service/faqs/create',
                name: 'FaqCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeCreate.vue'),
            },
            {
                path: '/service/faqs/update/:noticeNo',
                name: 'FaqUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0047',
                    roles: [],
                },
                component: () => import('@/views/service/NoticeCreate.vue'),
            },

            /**
             * 서비스 관리 > 공통 코드 관리
             */
            {
                path: '/service/common-code',
                name: 'CommonCode',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0055',
                    roles: [],
                },
                component: () => import('@/views/service/CommonCode.vue'),
            },
            {
                path: '/service/common-code/create',
                name: 'CommonCodeCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0055',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/CommonCodeCreate.vue'),
            },

            {
                path: '/service/common-code/update',
                name: 'CommonCodeUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0055',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/CommonCodeCreate.vue'),
            },

            /**
             * 서비스 관리 > K-pop Hotel 관리 > 스플래쉬 관리
             */
            {
                path: '/service/kpop-hotel/splash',
                name: 'KpopSplash',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0059',
                    roles: [],
                },
                component: () => import('@/views/service/kpopHotel/KpopSplash.vue'),
            },
            {
                path: '/service/kpop-hotel/splash/view/:advrtSplashNo',
                name: 'KpopSplashView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0059',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopSplashView.vue'),
            },
            {
                path: '/service/kpop-hotel/splash/create',
                name: 'KpopSplashCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0059',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopSplashCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/splash/update/:advrtSplashNo',
                name: 'KpopSplashUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0059',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopSplashCreate.vue'),
            },

            /**
             * 서비스 관리 > K-pop Hotel 관리 > 팝업 문구 관리
             */
            {
                path: '/service/kpop-hotel/popup-texts',
                name: 'PopupText',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0060',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopPopupText.vue'),
            },
            {
                path: '/service/kpop-hotel/popup-texts/:advrtTextNo',
                name: 'PopupTextView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0060',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopPopupTextView.vue'),
            },
            {
                path: '/service/kpop-hotel/popup-texts/create',
                name: 'PopupTextCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0060',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopPopupTextCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/popup-texts/update/:advrtTextNo',
                name: 'PopupTextUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0060',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/KpopPopupTextCreate.vue'),
            },

            /**
             * 서비스 관리 > K-POP HOTEL > 아티스트 그룹 관리
             */
            {
                path: '/service/kpop-hotel/artist-group',
                name: 'ArtistGroup',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0061',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroup.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-group/create',
                name: 'ArtistGroupCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0061',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-group/view/:artistGroupNo',
                name: 'ArtistGroupView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0061',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupView.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-group/update/:artistGroupNo',
                name: 'ArtistGroupUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0061',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupCreate.vue'),
            },

            /**
             * 서비스 관리 > K-POP HOTEL > 아티스트 관리
             */
            {
                path: '/service/kpop-hotel/artist',
                name: 'Artist',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0062',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/Artist.vue'),
            },
            {
                path: '/service/kpop-hotel/artist/create',
                name: 'ArtistCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0062',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/artist/view/:artistNo',
                name: 'ArtistView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0062',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistView.vue'),
            },
            {
                path: '/service/kpop-hotel/artist/update/:artistNo',
                name: 'ArtistUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0062',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistCreate.vue'),
            },

            /**
             * 서비스 관리 > K-pop Hotel 관리 > 팬 미션 관리
             */
            {
                path: '/service/kpop-hotel/mission',
                name: 'Mission',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0063',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/Mission.vue'),
            },
            {
                path: '/service/kpop-hotel/mission/:fanMissionNo',
                name: 'MissionView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0063',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/MissionView.vue'),
            },
            {
                path: '/service/kpop-hotel/mission/create',
                name: 'MissionCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0063',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/MissionCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/mission/update/:fanMissionNo',
                name: 'MissionUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0063',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/MissionCreate.vue'),
            },

            /**
             * 서비스 관리 > K-pop Hotel 관리 > 팬 미션 보상 관리
             */
            {
                path: '/service/kpop-hotel/mission-reward',
                name: 'FanMissionReward',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0064',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/FanMissionReward.vue'),
            },
            {
                path: '/service/kpop-hotel/mission-reward/:fanMissionRewardNo',
                name: 'FanMissionRewardView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0064',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/FanMissionRewardView.vue'),
            },
            {
                path: '/service/kpop-hotel/mission-reward/create',
                name: 'FanMissionRewardCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0064',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/FanMissionRewardCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/mission-reward/update/:fanMissionRewardNo',
                name: 'FanMissionRewardUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0064',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/service/kpopHotel/FanMissionRewardCreate.vue'),
            },

            /**
             * 서비스 관리 > K-POP HOTEL > 아티스트 그룹 팬 미션 관리
             */
            {
                path: '/service/kpop-hotel/artist-grp-fan-mission',
                name: 'ArtistGrpFanMission',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0066',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupFanMission.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-grp-fan-mission/create',
                name: 'ArtistGrpFanMissionCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0066',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupFanMissionCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-grp-fan-mission/view/:artistGrpFanMissionNo',
                name: 'ArtistGrpFanMissionView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0066',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupFanMissionView.vue'),
            },
            {
                path: '/service/kpop-hotel/artist-grp-fan-mission/update/:artistGrpFanMissionNo',
                name: 'ArtistGrpFanMissionUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0066',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/service/ArtistGroupFanMissionCreate.vue'),
            },

            /**
             * 서비스 관리 > K-POP HOTEL > K-POP 다국어 메시지 관리
             */
            {
                path: '/service/kpop-hotel/multi-langs',
                name: 'KpopMultiLanguage',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0068',
                    roles: [],
                },
                component: () => import('@/views/service/kpopHotel/KpopMultiLanguage.vue'),
            },
            {
                path: '/service/kpop-hotel/multi-langs/create',
                name: 'KpopMultiLanguageCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0068',
                    roles: [],
                },
                component: () => import('@/views/service/kpopHotel/KpopMultiLanguageCreate.vue'),
            },
            {
                path: '/service/kpop-hotel/multi-langs/view/:langMsgNo',
                name: 'KpopMultiLanguageView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0068',
                    roles: [],
                },
                component: () => import('@/views/service/kpopHotel/KpopMultiLanguageView.vue'),
            },
            {
                path: '/service/kpop-hotel/multi-langs/update/:langMsgNo',
                name: 'KpopMultiLanguageUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0068',
                    roles: [],
                },
                component: () => import('@/views/service/kpopHotel/KpopMultiLanguageCreate.vue'),
            },

            /**
             * 서비스 관리 > CMS 관리
             */
            {
                path: '/service/cms',
                name: 'Cms',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0065',
                    roles: [],
                },
                component: () => import('@/views/service/Cms.vue'),
            },
            {
                path: '/service/cms/view/:cmsNo',
                name: 'CmsView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0065',
                    roles: [],
                },
                component: () => import('@/views/service/CmsView.vue'),
            },
            {
                path: '/service/cms/create',
                name: 'CmsCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0065',
                    roles: [],
                },
                component: () => import('@/views/service/CmsCreate.vue'),
            },
            {
                path: '/service/cms/update/:cmsNo',
                name: 'CmsUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0065',
                    roles: [],
                },
                component: () => import('@/views/service/CmsCreate.vue'),
            },

            /**
             * 서비스 관리 > CMS 이메일 발송 이력
             */
            {
                path: '/service/cms-email',
                name: 'CmsMailSendLog',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0067',
                    roles: [],
                },
                component: () => import('@/views/service/CmsMailSendLog.vue'),
            },

            /**
             * 채널 및 룸 정보 > 이프스퀘어 관리
             */
            {
                path: '/channel-room/land',
                name: 'MeetupRoom',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/MeetupRoom.vue'),
            },
            {
                path: '/channel-room/land/view/:meetupNo',
                name: 'MeetupRoomView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/MeetupRoomView.vue'),
            },
            {
                path: '/channel-room/land/update/:meetupNo',
                name: 'MeetupRoomUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/MeetupRoomUpdate.vue'),
            },
            {
                path: '/channel-room/land/presentaion-data',
                name: 'MeetupRoomPresentaionData',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/MeetupRoomPresentaionData.vue'),
            },
            {
                path: '/channel-room/land/control/:meetupNo/:meetupJoinFomCd/:userIdx',
                name: 'MeetupRoomControl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/MeetupRoomControl.vue'),
            },
            {
                path: '/channel-room/land/square-control/:meetupNo/:meetupJoinFomCd/:userIdx',
                name: 'MeetupSquareControl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/MeetupSquareControl.vue'),
            },
            {
                path: '/channel-room/land/create',
                name: 'MeetupRoomCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0003',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/MeetupRoomCreate.vue'),
            },

            /**
             * 채널 및 룸 정보 > 이프스퀘어 테마 관리
             */
            {
                path: '/channel-room/land-theme',
                name: 'LandTheme',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0004',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/LandTheme.vue'),
            },
            {
                path: '/channel-room/land-theme/create',
                name: 'LandThemeCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0004',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/LandThemeCreate.vue'),
            },
            {
                path: '/channel-room/land-theme/view/:meetupThemeNo',
                name: 'LandThemeView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0004',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/LandThemeView.vue'),
            },
            {
                path: '/channel-room/land-theme/update/:meetupThemeNo',
                name: 'LandThemeUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0004',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/LandThemeCreate.vue'),
            },

            /**
             * 채널 및 룸 정보 > 스페셜 이프스퀘어 관리
             */
            {
                path: '/channel-room/special-land',
                name: 'SpecialLand',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0006',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/SpecialLand.vue'),
            },

            /**
             * 채널 및 룸 정보 > 제휴처 이프스퀘어 관리
             */
            {
                path: '/channel-room/partner-land',
                name: 'PartnerLand',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0008',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/PartnerLand.vue'),
            },
            {
                path: '/channel-room/partner-land/create',
                name: 'PartnerLandCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0008',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/PartnerLandCreate.vue'),
            },
            {
                path: '/channel-room/partner-land/view/:meetupShowMgNo',
                name: 'PartnerLandView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0008',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/PartnerLandView.vue'),
            },

            /**
             * 채널 및 룸 정보 > 별 이프스퀘어 관리
             */
            {
                path: '/channel-room/star-land',
                name: 'StarLand',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0007',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/StarLand.vue'),
            },
            {
                path: '/channel-room/star-land/create/',
                name: 'StarLandCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0007',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/StarLandCreate.vue'),
            },
            {
                path: '/channel-room/star-land/update/:meetupShowMgNo',
                name: 'StarLandUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0007',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/StarLandCreate.vue'),
            },
            {
                path: '/channel-room/star-land/view/:meetupShowMgNo',
                name: 'StarLandView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0007',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/StarLandView.vue'),
            },

            /**
             * 채널 및 룸 정보 > 추천 이프스퀘어 관리
             */
            {
                path: '/channel-room/recommendation-land',
                name: 'RecommendLand',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0005',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/RecommendLand.vue'),
            },

            /**
             * 채널 및 룸 정보 > 제휴처 카테고리 관리
             */
            {
                path: '/channel-room/partner-category',
                name: 'PartnerCategory',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0015',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/channelRoom/PartnerCategory.vue'),
            },
            {
                path: '/channel-room/partner-category/create',
                name: 'PartnerCategoryCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0015',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/channelRoom/PartnerCategoryCreate.vue'),
            },
            {
                path: '/channel-room/partner-category/update/:ptrshCtgryMgNo',
                name: 'PartnerCategoryUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0015',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/channelRoom/PartnerCategoryCreate.vue'),
            },
            {
                path: '/channel-room/partner-category/view/:ptrshCtgryMgNo',
                name: 'PartnerCategoryView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0015',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/channelRoom/PartnerCategoryView.vue'),
            },

            /**
             * 채널 및 룸 정보 > 제휴처 관리
             */
            {
                path: '/channel-room/partner',
                name: 'Partner',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0009',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/Partner.vue'),
            },
            {
                path: '/channel-room/partner/create',
                name: 'PartnerCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0009',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/PartnerCreate.vue'),
            },
            {
                path: '/channel-room/partner/update/:ptrshEnpNo',
                name: 'PartnerUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0009',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/PartnerCreate.vue'),
            },
            {
                path: '/channel-room/partner/view/:ptrshEnpNo',
                name: 'PartnerView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0009',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/PartnerView.vue'),
            },

            /**
             * 채널 및 룸 정보 > 이프스퀘어 주제 관리
             */
            {
                path: '/channel-room/land-topics',
                name: 'Topic',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0057',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/channelRoom/Topic.vue'),
            },
            {
                path: '/channel-room/land-topics/create',
                name: 'TopicCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0057',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/TopicCreate.vue'),
            },
            {
                path: '/channel-room/land-topics/update/:meetupTopicNo',
                name: 'TopicUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0057',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/TopicCreate.vue'),
            },
            {
                path: '/channel-room/land-topics/view/:meetupTopicNo',
                name: 'TopicView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0057',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/channelRoom/TopicView.vue'),
            },

            /**
             * TRO 룸 정보 > Volumetric Content 관리
             */
            {
                path: '/tro-room/volumetric-content',
                name: 'VolumetricContent',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0016',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/troRoom/VolumetricContent.vue'),
            },
            {
                path: '/tro-room/volumetric-content/view/:ctntNo',
                name: 'VolumetricContentView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0016',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/troRoom/VolumetricContentView.vue'),
            },
            {
                path: '/tro-room/volumetric-content/create',
                name: 'VolumetricContentCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0016',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/troRoom/VolumetricContentCreate.vue'),
            },
            {
                path: '/tro-room/volumetric-content/update/:ctntNo',
                name: 'VolumetricContentUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0016',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/troRoom/VolumetricContentCreate.vue'),
            },

            /**
             * TRO 룸 정보 > TRO 룸 정보 관리
             */
            {
                path: '/tro-room/informations',
                name: 'TroRoomInformation',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0071',
                    roles: [],
                },
                component: () => import('@/views/troRoom/TroRoomInformation.vue'),
            },
            {
                path: '/tro-room/informations/view/:troRoomMetaIdx',
                name: 'TroRoomInformationView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0071',
                    roles: [],
                },
                component: () => import('@/views/troRoom/TroRoomInformationView.vue'),
            },
            {
                path: '/tro-room/informations/create',
                name: 'TroRoomInformationCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0071',
                    roles: [],
                },
                component: () => import('@/views/troRoom/TroRoomInformationCreate.vue'),
            },
            {
                path: '/tro-room/informations/update/:troRoomMetaIdx',
                name: 'TroRoomInformationUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0071',
                    roles: [],
                },
                component: () => import('@/views/troRoom/TroRoomInformationCreate.vue'),
            },

            /**
             * 사용자 관리 > Talk 게시글 관리
             */
            {
                path: '/member-manage/talk-post',
                name: 'TalkPost',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0023',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/memberManage/TalkPost.vue'),
            },
            {
                path: '/member-manage/talk-post/view/:talkNo',
                name: 'TalkPostView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0023',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/memberManage/TalkPostView.vue'),
            },

            /**
             * 사용자 관리 > Talk 답글 관리
             */
            {
                path: '/member-manage/talk-reply',
                name: 'TalkReply',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0024',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/memberManage/TalkReply.vue'),
            },
            {
                path: '/member-manage/talk-reply/view/:talkNo',
                name: 'TalkReplyView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0024',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/memberManage/TalkReplyView.vue'),
            },

            /**
             * 사용자 관리 > Unmasking 관리
             */
            {
                path: '/member-manage/unmasking',
                name: 'Unmasking',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0031',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/memberManage/Unmasking.vue'),
            },

            /**
             * 시스템 관리 > 관리자 > 관리자 관리
             */
            {
                path: '/system/admin/info',
                name: 'AdminInfo',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0017',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/Admin.vue'),
            },
            {
                path: '/system/admin/info/update',
                name: 'AdminUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0017',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/AdminUpdate.vue'),
            },
            {
                path: '/system/admin/info/view/:idx',
                name: 'AdminView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0017',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/AdminView.vue'),
            },

            /**
             * 시스템 관리 > 관리자 > 관리자 승인 요청 정보
             */
            {
                path: '/system/admin/approval',
                name: 'AdminApproval',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0018',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/AdminApproval.vue'),
            },

            /**
             * 시스템 관리 > 작업 이력
             */
            {
                path: '/system/work-history',
                name: 'WorkHistory',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0022',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/WorkHistory.vue'),
            },

            /**
             * 시스템 관리 > 서비스 지역 관리
             */
            {
                path: '/system/service-area',
                name: 'ServiceArea',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0021',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/ServiceArea.vue'),
            },
            {
                path: '/system/service-area/create',
                name: 'ServiceAreaCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0021',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/ServiceAreaCreate.vue'),
            },
            {
                path: '/system/service-area/update/:zonNo',
                name: 'ServiceAreaUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0021',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/ServiceAreaCreate.vue'),
            },
            {
                path: '/system/service-area/view/:zonNo',
                name: 'ServiceAreaView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0021',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/ServiceAreaView.vue'),
            },

            /**
             * 시스템 관리 > 권한
             */
            {
                path: '/system/authority',
                name: 'Authority',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0020',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/Authority.vue'),
            },
            {
                path: '/system/authority/create/:roleNo',
                name: 'AuthorityCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0020',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/AuthorityCreate.vue'),
                props: true,
            },
            {
                path: '/system/authority/update/:roleNo',
                name: 'AuthorityUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0020',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/AuthorityCreate.vue'),
                props: true,
            },
            {
                path: '/system/authority/view/:roleNo',
                name: 'AuthorityView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0020',
                    roles: [],
                    // isChangeGroupPage: false
                },
                component: () => import('@/views/system/AuthorityView.vue'),
                props: true,
            },

            /**
             * 시스템 관리 > 메뉴
             */
            {
                path: '/system/menu',
                name: 'Menu',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0019',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/system/Menu.vue'),
            },
            /**
             * 시스템 관리 > 사용자 그룹 관리
             */
            {
                path: '/system/user-group',
                name: 'UserGroup',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0010',
                    roles: [],
                    isChangeGroupPage: true,
                },
                component: () => import('@/views/system/UserGroup.vue'),
            },
            {
                path: '/system/user-group/create',
                name: 'UserGroupCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0010',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/system/UserGroupCreate.vue'),
            },
            {
                path: '/system/user-group/update/:groupNo',
                name: 'UserGroupUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0010',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/system/UserGroupCreate.vue'),
            },
            {
                path: '/system/user-group/view/:groupNo',
                name: 'UserGroupView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0010',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/system/UserGroupView.vue'),
            },
            /**
             * 시스템 관리 > 서비스 지역별 언어 관리
             */
            {
                path: '/system/lang-service-zones',
                name: 'LangServiceZone',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0046',
                    roles: [],
                },
                component: () => import('@/views/system/LangServiceZone.vue'),
            },
            {
                path: '/system/lang-service-zones/create',
                name: 'LangServiceZoneCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0046',
                    roles: [],
                },
                component: () => import('@/views/system/LangServiceZoneCreate.vue'),
            },
            {
                path: '/system/lang-service-zones/update/:langMapNo',
                name: 'LangServiceZoneUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0046',
                    roles: [],
                },
                component: () => import('@/views/system/LangServiceZoneCreate.vue'),
            },
            {
                path: '/system/lang-service-zones/view/:langMapNo',
                name: 'LangServiceZoneView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0046',
                    roles: [],
                },
                component: () => import('@/views/system/LangServiceZoneView.vue'),
            },
            /**
             * 시스템 관리 > 언어 태그 관리
             */
            {
                path: '/system/use-langs',
                name: 'UseLang',
                meta: {
                    requresAuth: true,
                    menuId: 'M0053',
                    roles: [],
                },
                component: () => import('@/views/system/UseLang.vue'),
            },
            {
                path: '/system/use-langs/view/:langCd',
                name: 'UseLangView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0053',
                    roles: [],
                },
                component: () => import('@/views/system/UseLangView.vue'),
            },
            {
                path: '/system/use-langs/create',
                name: 'UseLangCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0053',
                    roles: [],
                },
                component: () => import('@/views/system/UseLangCreate.vue'),
            },
            {
                path: '/system/use-langs/update/:langCd',
                name: 'UseLangUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0053',
                    roles: [],
                },
                component: () => import('@/views/system/UseLangCreate.vue'),
            },
            /**
             * 시스템 관리 > 서비스 언어 관리
             */
            {
                path: '/system/sv-langs',
                name: 'ServiceLang',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0054',
                    roles: [],
                },
                component: () => import('@/views/system/ServiceLang.vue'),
            },
            {
                path: '/system/sv-langs/view/:langCd',
                name: 'ServiceLangView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0054',
                    roles: [],
                },
                component: () => import('@/views/system/ServiceLangView.vue'),
            },
            {
                path: '/system/sv-langs/create',
                name: 'ServiceLangCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0054',
                    roles: [],
                },
                component: () => import('@/views/system/ServiceLangCreate.vue'),
            },
            {
                path: '/system/sv-langs/update/:langCd',
                name: 'ServiceLangUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0054',
                    roles: [],
                },
                component: () => import('@/views/system/ServiceLangCreate.vue'),
            },
            /**
             * Play Item 관리 > 노래방 관리
             */
            {
                path: '/play-item/karaoke',
                name: 'Karaoke',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0025',
                    roles: [],
                },
                component: () => import('@/views/playItem/Karaoke.vue'),
            },
            {
                path: '/play-item/karaoke/song-setting',
                name: 'SongSettings',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0025',
                    roles: [],
                },
                component: () => import('@/views/playItem/SongSettings.vue'),
            },
            /**
             * Play Item 관리 > 숏폼 테마 관리
             */
            {
                path: '/play-item/short-form',
                name: 'ShortForm',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0028',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortForm.vue'),
            },
            {
                path: '/play-item/short-form/create',
                name: 'ShortFormCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0028',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormCreate.vue'),
            },
            {
                path: '/play-item/short-form/update/:evtthemeNo',
                name: 'ShortFormUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0028',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormCreate.vue'),
            },
            {
                path: '/play-item/short-form/view/:evtthemeNo',
                name: 'ShortFormView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0028',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormView.vue'),
            },
            /**
             * Play Item 관리 > 플레이 아이템 관리
             */
            {
                path: '/play-item/item',
                name: 'PlayItem',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItem.vue'),
            },
            {
                path: '/play-item/item/create',
                name: 'PlayItemCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItemCreate.vue'),
            },
            {
                path: '/play-item/item/update/:playitemId',
                name: 'PlayItemUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItemCreate.vue'),
            },
            {
                path: '/play-item/item/view/:playitemId',
                name: 'PlayItemView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItemView.vue'),
            },
            {
                path: '/play-item/item/source/create',
                name: 'PlayItemSourceCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItemSourceCreate.vue'),
            },
            {
                path: '/play-item/item/source/view/:playitemCode',
                name: 'PlayItemSourceView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0026',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayItemSourceView.vue'),
            },
            /**
             * Play Item 관리 > 플레이 컴포넌트 관리
             */
            {
                path: '/play-item/play-component',
                name: 'PlayComponent',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0027',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayComponent.vue'),
            },
            {
                path: '/play-item/play-component/create',
                name: 'PlayComponentCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0027',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayComponentCreate.vue'),
            },
            {
                path: '/play-item/play-component/update/:compId',
                name: 'PlayComponentUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0027',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayComponentCreate.vue'),
            },
            {
                path: '/play-item/play-component/view/:compId',
                name: 'PlayComponentView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0027',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/PlayComponentView.vue'),
            },
            /**
             * Play Item 관리 > 플레이 아이템 보상 이력
             *
             */
            {
                path: '/play-item/reward-history',
                name: 'RewardHistory',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0029',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/playItem/RewardHistory.vue'),
            },
            /**
             * Play Item 관리 > 숏폼 인기 소식 관리
             */
            {
                path: '/play-item/short-form-hits',
                name: 'ShortFormHits',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0030',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormHits.vue'),
            },
            {
                path: '/play-item/short-form-hits/create',
                name: 'ShortFormHitsCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0030',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormHitsCreate.vue'),
            },
            {
                path: '/play-item/short-form-hits/update/:hitNo',
                name: 'ShortFormHitsUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0030',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormHitsCreate.vue'),
            },
            {
                path: '/play-item/short-form-hits/view/:hitNo',
                name: 'ShortFormHitsView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0030',
                    roles: [],
                },
                component: () => import('@/views/playItem/ShortFormHitsView.vue'),
            },
            /**
             * Play Item 관리 > 플레이 아이템 포인트 모니터링
             */
            {
                path: '/play-item/point-monitor',
                name: 'PlayItemPointMonitor',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0032',
                    roles: [],
                },
                component: () => import('@/views/playItem/PlayItemPointMonitor.vue'),
            },
            /*
             * ifhome 관리 > 지금 뜨는 아이템
             */
            {
                path: '/ifhome/rise-items',
                name: 'RiseItem',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0056',
                    roles: [],
                },
                component: () => import('@/views/ifhome/RiseItem.vue'),
            },
            {
                path: '/ifhome/rise-items/create',
                name: 'RiseItemCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0056',
                    roles: [],
                },
                component: () => import('@/views/ifhome/RiseItemCreate.vue'),
            },
            /**
             * ifhome 관리 > ifhome 일반 > 일반 ifhome 관리
             */
            {
                path: '/ifhome/general/ifhome',
                name: 'Ifhome',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0036',
                    roles: [],
                },
                component: () => import('@/views/ifhome/Ifhome.vue'),
            },
            {
                path: '/ifhome/general/ifhome/view/:userIdx',
                name: 'IfhomeView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0036',
                    roles: [],
                    isGeneral: true,
                },
                component: () => import('@/views/ifhome/IfhomeView.vue'),
            },
            {
                path: '/ifhome/general/ifhome/update/:userIdx',
                name: 'IfhomeUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0036',
                    roles: [],
                    isGeneral: true,
                },
                component: () => import('@/views/ifhome/IfhomeUpdate.vue'),
            },
            {
                path: '/ifhome/general/ifhome/control/:userIdx',
                name: 'GeneralIfHomeControl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0036',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfHomeControl.vue'),
            },
            /**
             * ifhome 관리 > ifhome 일반 > 오늘의 ifhome 관리
             */
            {
                path: '/ifhome/general/today-ifhome',
                name: 'TodayIfhome',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0037',
                    roles: [],
                },
                component: () => import('@/views/ifhome/TodayIfhome.vue'),
            },
            {
                path: '/ifhome/general/today-ifhome/create',
                name: 'TodayIfhomeCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0037',
                    roles: [],
                },
                component: () => import('@/views/ifhome/TodayIfhomeCreate.vue'),
            },

            /**
             * ifhome 관리 > ifhome 일반 > ifhome 통신 이벤트 관리
             */
            {
                path: '/ifhome/general/cres-event',
                name: 'ifhomeCresEvent',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0052',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeCresEvent.vue'),
            },

            /**
             * ifhome 관리 > ifhome 제휴 > 제휴 ifhome 관리
             */
            {
                path: '/ifhome/partner/partner-ifhome',
                name: 'PartnerIfhome',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0033',
                    roles: [],
                },
                component: () => import('@/views/ifhome/PartnerIfhome.vue'),
            },
            {
                path: '/ifhome/partner/partner-ifhome/view/:userIdx/:spacePtrshEnpNo',
                name: 'PartnerIfhomeView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0033',
                    roles: [],
                    isGeneral: false,
                },
                component: () => import('@/views/ifhome/IfhomeView.vue'),
            },
            {
                path: '/ifhome/partner/partner-ifhome/update/:userIdx/:spacePtrshEnpNo',
                name: 'PartnerIfhomeUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0033',
                    roles: [],
                    isGeneral: false,
                },
                component: () => import('@/views/ifhome/IfhomeUpdate.vue'),
            },
            {
                path: '/ifhome/partner/partner-ifhome/control/:userIdx',
                name: 'PartnerIfHomeControl',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0033',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfHomeControl.vue'),
            },
            /**
             * ifhome 관리 > ifhome 제휴 > ifhome 제휴처 관리
             */
            {
                path: '/ifhome/ifhome-partner',
                name: 'IfhomePartner',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartner.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/create',
                name: 'IfhomePartnerCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/ifhome/IfhomePartnerCreate.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/view/:spacePtrshEnpNo',
                name: 'IfhomePartnerView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/ifhome/IfhomePartnerView.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/update/:spacePtrshEnpNo',
                name: 'IfhomePartnerUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                    isChangeGroupPage: false,
                },
                component: () => import('@/views/ifhome/IfhomePartnerCreate.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/:spacePtrshEnpNo/account-mapping/update/:userIdx',
                name: 'IfhomePartnerAccountMappingUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerAccountMapping.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/:spacePtrshEnpNo/account-mapping/create',
                name: 'IfhomePartnerAccountMapping',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerAccountMapping.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/prop-mapping/:spacePtrshEnpNo',
                name: 'IfhomePartnerPropMapping',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerPropMapping.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/prop-mapping/:userIdx/create/:spacePtrshEnpNo',
                name: 'IfhomePartnerPropMappingCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerPropMappingCreate.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/prop-mapping/:spacePtrshEnpNo/view/:spacePtrshEnpPropNo',
                name: 'IfhomePartnerPropMappingView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerPropMappingView.vue'),
            },
            {
                path: '/ifhome/ifhome-partner/prop-mapping/:spacePtrshEnpNo/update/:spacePtrshEnpPropNo',
                name: 'IfhomePartnerPropMappingUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0034',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomePartnerPropMappingCreate.vue'),
            },
            /**
             * ifhome 관리 > ifhome 제휴 > 스페셜 ifhome 관리
             */
            {
                path: '/ifhome/special-ifhome',
                name: 'IfhomeSpecial',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0035',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeSpecial.vue'),
            },
            {
                path: '/ifhome/special-ifhome/create',
                name: 'IfhomeSpecialCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0035',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeSpecialCreate.vue'),
            },
            /**
             * ifhome 관리 > ifhome 공통 > ifhome 모델하우스 관리
             */
            {
                path: '/ifhome/common/modelhouse',
                name: 'IfhomeModelhouse',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0048',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeModelhouse.vue'),
            },
            {
                path: '/ifhome/common/modelhouse/create',
                name: 'IfhomeModelhouseCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0048',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeModelhouseCreate.vue'),
            },
            {
                path: '/ifhome/common/modelhouse/update/:mbrRcmdNo',
                name: 'IfhomeModelhouseUpdate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0048',
                    roles: [],
                },
                component: () => import('@/views/ifhome/IfhomeModelhouseCreate.vue'),
            },
            {
                path: '/ifhome/common/modelhouse/view/:mbrRcmdNo',
                name: 'IfhomeModelhouseView',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0048',
                    roles: [],
                    isGeneral: false,
                },
                component: () => import('@/views/ifhome/IfhomeModelhouseView.vue'),
            },
            /**
             * ifhome 관리 > 인기 게시물 관리
             */
            {
                path: '/ifhome/popular-posts',
                name: 'PopularPost',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0049',
                    roles: [],
                },
                component: () => import('@/views/ifhome/PopularPost.vue'),
            },
            {
                path: '/ifhome/popular-posts/create',
                name: 'PopularPostCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0049',
                    roles: [],
                },
                component: () => import('@/views/ifhome/PopularPostCreate.vue'),
            },
            /*
             * ifhome 관리 > 지금 집에 있는 친구 관리
             */
            {
                path: '/ifhome/in-home-friends',
                name: 'InHomeFriend',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0050',
                    roles: [],
                },
                component: () => import('@/views/ifhome/InHomeFriend.vue'),
            },
            {
                path: '/ifhome/in-home-friends/create',
                name: 'InHomeFriendCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0050',
                    roles: [],
                },
                component: () => import('@/views/ifhome/InHomeFriendCreate.vue'),
            },
            /**
             * ifhome 관리 > 인기 급상승 ifhome 관리
             */
            {
                path: '/ifhome/popular-ifhomes',
                name: 'PopularIfHome',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0051',
                    roles: [],
                },
                component: () => import('@/views/ifhome/PopularIfhome.vue'),
            },
            {
                path: '/ifhome/popular-ifhomes/create',
                name: 'PopularIfHomeCreate',
                meta: {
                    requiresAuth: true,
                    menuId: 'M0051',
                    roles: [],
                },
                component: () => import('@/views/ifhome/PopularIfhomeCreate.vue'),
            },
            /**
             * 마이 페이지
             */
            {
                path: '/my-page/my-page',
                name: 'MyPage',
                meta: {
                    requiresAuth: true,
                    menuId: '',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/myPage/MyPage.vue'),
            },
            {
                path: '/my-page/email-change',
                name: 'EmailChange',
                meta: {
                    requiresAuth: true,
                    menuId: '',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/myPage/EmailChange.vue'),
            },
            {
                path: '/my-page/pw-change',
                name: 'PwChange',
                meta: {
                    requiresAuth: true,
                    menuId: '',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/myPage/PwChange.vue'),
            },
            {
                path: '/my-page/group-change',
                name: 'GroupChange',
                meta: {
                    requiresAuth: true,
                    menuId: '',
                    roles: [],
                    // isChangeGroupPage: true
                },
                component: () => import('@/views/myPage/GroupChange.vue'),
            },
        ],
    },
    // 레이아웃 제외 페이지
    {
        path: '/',
        name: 'layoutNone',
        component: () => import('@/layout/LayoutNone.vue'),
        children: [
            // 로그인
            {
                path: '/login',
                name: 'Login',
                meta: {
                    requiresAuth: false,
                },
                component: () => import('@/views/login/Login.vue'),
            },
            {
                path: '/join',
                name: 'Join',
                meta: {
                    requiresAuth: false,
                },
                component: () => import('@/views/login/Join.vue'),
            },
            {
                path: '/fido-register',
                name: 'FidoRegister',
                meta: {
                    requiresAuth: false,
                },
                component: () => import('@/views/login/FidoRegister.vue'),
            },
            {
                path: '/channel-room/land/statistics/:meetupNo',
                name: 'MeetupRoomStatistics',
                meta: {
                    requiresAuth: false,
                },
                component: () => import('@/views/channelRoom/openNewMeetupRoomStatistics.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach(onBeforeEnter);

async function onBeforeEnter(to, from, next) {
    nextPath = to.fullPath;
    // Dialog, Layer Store 값 초기화
    store.dispatch('dialogStore/CLOSE');
    store.dispatch('layerStore/CLOSE');

    const userId = await store.getters['sessionStore/GET_USER_ID'];

    const hasMenuAuth = async menuId => {
        const menus = await store.getters['sessionStore/GET_MENUS'];
        // 메뉴ID없는 화면들 임시 빈값 설정
        return menus.includes(menuId) || menuId === '';
    };

    const hasRole = async () => {
        const roles = await store.getters['sessionStore/GET_ROLES'];
        let result = false;
        roles.forEach(role => {
            if (to.meta.roles.includes(role)) {
                result = true;
            }
        });
        return result;
    };

    // 헤더 그룹변경 가능 여부
    const isChangeGroupPage = typeof to.meta.isChangeGroupPage !== 'undefined' ? to.meta.isChangeGroupPage : true;
    store.dispatch('SET_IS_CHANGE_GROUP_PAGE', isChangeGroupPage);

    if (to.path === '/index') {
        if (userId !== null && typeof userId !== 'undefined') {
            /* index의 경우 IfPageInfo를 사용하지 않아서 menuStore값이 변경되지 않음
             => index진입 시 menuStore의 menuId를 빈 값으로 설정 */
            store.dispatch('menuStore/SET_MENU_ID', '');
            return next();
        } else {
            return next({ name: 'Login' });
        }
    }

    if (userId && to.name === 'Login') {
        return next({ path: '/index' });
    }

    // 로그인 필수 페이지인 경우
    const isRequireLogin = to.meta.requiresAuth;
    if (isRequireLogin) {
        // 로그인 체크
        if (!userId) {
            return next({ path: from.path });
        }
        // 메뉴 권한 유무 체크
        if (!(await hasMenuAuth(to.meta.menuId))) {
            //return next({ path: from.path });
            await loginApi.logout();
            store.dispatch('sessionStore/CLEAR_SESSION');
            return next({ path: '/login' });
        }
        // 롤 유무 체크
        if (to.meta.roles.length > 0) {
            if (!(await hasRole())) {
                //return next({ path: from.path });
                await loginApi.logout();
                store.dispatch('sessionStore/CLEAR_SESSION');
                return next({ path: '/login' });
            }
        }
    }

    return next();
}
let nextPath;
router.onError(error => {
    if (error.name === 'ChunkLoadError') {
        window.location.href = nextPath || '/';
    }
});

export default router;
