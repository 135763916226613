import store from '../store';

export default {
    install: app => {
        app.config.globalProperties.$getResource = async () => {
            return await store.getters['sessionStore/GET_RESOURCES'];
        };

        app.config.globalProperties.$hasResource = async resourceId => {
            const resources = await store.getters['sessionStore/GET_RESOURCES'];
            if (resources.includes(resourceId)) return true;
            return false;
        };

        app.config.globalProperties.$hasAllResource = async (...resourceId) => {
            const resources = await store.getters['sessionStore/GET_RESOURCES'];
            for (let i = 0; i < resourceId.length; i++) {
                if (!resources.includes(resourceId[i])) {
                    return false;
                }
            }
            return true;
        };

        app.config.globalProperties.$hasAnyResource = async (...resourceId) => {
            const resources = await store.getters['sessionStore/GET_RESOURCES'];
            for (let i = 0; i < resourceId.length; i++) {
                if (resources.includes(resourceId[i])) {
                    return true;
                }
            }
            return false;
        };

        app.config.globalProperties.$hasRole = async roleId => {
            const roles = await store.getters['sessionStore/GET_ROLES'];
            if (roles.includes(roleId)) return true;
            return false;
        };

        app.config.globalProperties.$hasAllRole = async (...roleId) => {
            const roles = await store.getters['sessionStore/GET_ROLES'];
            for (let i = 0; i < roleId.length; i++) {
                if (!roles.includes(roleId[i])) {
                    return false;
                }
            }
            return true;
        };

        app.config.globalProperties.$hasAnyRole = async (...roleId) => {
            const roles = await store.getters['sessionStore/GET_ROLES'];
            for (let i = 0; i < roleId.length; i++) {
                if (roles.includes(roleId[i])) {
                    return true;
                }
            }
            return false;
        };
        
        app.directive('access', {
            async beforeMount(el, binding) {
                if ((await binding.arg) === 'disabled') {
                    if (!(await binding.value)) {
                        el.disabled = true;
                        const inputs = el.querySelectorAll('input');
                        inputs.forEach(e => {
                            e.disabled = true;
                        });
                    }
                } else {
                    if (!(await binding.value)) {
                        el.style.display = 'none';
                    }
                }
            },
        });
    },
};
