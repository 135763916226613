import axiosApi from '@/api/index';
import { getLocale } from '@/i18n';
export default {
    async login(username, password) {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('multiLangCd', getLocale());
        await axiosApi.post('/api/v2/login', params, config);
    },
    async verify(params) {
        return axiosApi.post('/api/v2/login/verify', params);
    },
    async fidoCheckEnabled(params) {
        return axiosApi.get('/api/v2/fido/enabled/check');
    },
    async fidoVerify(params) {
        return axiosApi.post('/api/v2/fido/verify', params);
    },
    async fidoSendVerifyCode(params) {
        return axiosApi.post('/api/v2/fido/verify-code/send', params);
    },
    async fidoCheckVerifyCode(params) {
        return axiosApi.post('/api/v2/fido/verify-code/check', params);
    },
    async joinSendVerifyCode(params) {
        return axiosApi.post('/api/v2/join/verify-code/send', params);
    },
    async joinCheckVerifyCode(params) {
        return axiosApi.post('/api/v2/join/verify-code/check', params);
    },
    async logout() {
        return axiosApi.post('/api/v2/logout');
    },
    async join(params) {
        return await axiosApi.post('/api/admin/signup', params);
    },
    async ping() {
        return await axiosApi.get('/api/v2/login/ping');
    },
    async changeEmail(params) {
        return await axiosApi.post('/api/v2/login/email', params);
    },
    async changePassword(params) {
        return await axiosApi.post('/api/admin/changePassword/', params);
    },
    async getMyInfo() {
        return await axiosApi.get('/api/get/adminInfo/myInfo');
    },
    async modifySessionLang(params) {
        return await axiosApi.put('/api/v2/login/session/lang', params);
    },
    async modifySessionGroup(params) {
        return await axiosApi.put('/api/v2/login/session/group', params);
    },
    async getSessionInfo() {
        return await axiosApi.get('/api/v2/login/session');
    },
    async getMyGroups() {
        return await axiosApi.get('/api/v3/groups');
    },
    async changeGroup(params) {
        return await axiosApi.put('/api/v2/groups/primary', params);
    },
    async updateAdminJoinInfo(params) {
        return axiosApi.put('/api/v2/admins/pending-approvals', params);
    },
    async updatePassword(params) {
        return await axiosApi.put('/api/v2/login/password', params);
    },
    async getGroupDetail(groupId) {
        return await axiosApi.get(`/api/v3/groups/${groupId}`);
    },
    async getLeaderRoles(groupNo) {
        return await axiosApi.get(`/api/v2/groups/${groupNo}/leader/roles`);
    },
    async unmasking(params) {
        return axiosApi.put('/api/v2/login/unmasking', params, {
            headers: {
                'X-WORK-lOG': encodeURIComponent('unmasking;_unMasking'),
            },
        });
    },
};
