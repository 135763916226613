const dialogStore = {
    namespaced: true,
    state: {
        dialog: false,
    },
    getters: {
        GET_DIALOG: state => state.dialog,
    },
    mutations: {
        SET_DIALOG(state, value) {
            state.dialog = value;
        },
    },
    actions: {
        OPEN_ALERT({ commit }, payload) {
            if (!Object.keys(payload).includes('callBack')) {
                payload['callBack'] = function () {};
            }

            commit('SET_DIALOG', payload);
        },
        OPEN_CONFIRM({ commit }, payload) {
            commit('SET_DIALOG', payload);
        },
        CLOSE({ commit }) {
            commit('SET_DIALOG', false);
        },
    },
};

export default dialogStore;
