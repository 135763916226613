import store from '@/store';

export function useProgress() {
    const setProgress = (percentage = '', status) => {
        const progress = store.getters['progressStore/GET'];

        store.dispatch('progressStore/SET_PROGRESS', {
            ...progress,
            percentage,
            status,
        });
    };

    const requestSetProgress = ({ callback }) => {
        const progress = store.getters['progressStore/GET'];

        store.dispatch('progressStore/SET_PROGRESS', {
            ...progress,
            callback,
        });
    };

    return { setProgress, requestSetProgress };
}
