import store from '@/store';

export function useDialog(title) {
    const alert = async ({ text, callBack, cancelCallBack }) => {
        setTimeout(() => {
            store.dispatch('dialogStore/OPEN_ALERT', {
                title: title ? title : '',
                text: text,
                type: 'A',
                callBack: callBack,
                cancelCallBack: cancelCallBack,
            });
        }, 0);
    };

    const confirm = async ({ text, callBack, cancelCallBack }) => {
        setTimeout(() => {
            store.dispatch('dialogStore/OPEN_CONFIRM', {
                title: title ? title : '',
                text: text,
                type: 'C',
                callBack: callBack,
                cancelCallBack: cancelCallBack,
            });
        }, 0);
    };

    return { alert, confirm };
}
